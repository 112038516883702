<template>
  <div>
    <b-container fluid="xl" class="px-md-5 px-xl-0 s1">
      <b-row>
        <b-col
          class="bg-ondas py-5"
          :style="{
            'background-image':
              'url(' + require('../assets/img/ondas.png') + ')'
          }"
        >
          <b-row>
            <b-col class="d-none d-xl-block col-6 text-center py-3 align-self-center px-5">
              <b-img
                :src="require('../assets/img/login1.png')"
                fluid
                alt=""
              ></b-img>
            </b-col>
            <b-col class="col-12 col-md-8 col-lg-6 offset-md-2 offset-lg-3 offset-xl-0 col-5 pl-xl-5">
              <div class="card py-5 px-4">
                <p class="pb-5 text-center">
                  <b-img
                    :src="require('../assets/img/logo.png')"
                    fluid
                    alt=""
                    class=""
                  ></b-img>
                </p>
                <h5 class="text-white ff-chaney text-center">Confirmar identidade</h5>
                <div class="py-5 px-3">
                    <p>
                        O campo "CPF/Passaporte" será utilizado para verificar sua identidade para eventuais premiações.<br>
                        <strong>Qualquer divergência entre este valor e o documento apresentado invalidará seu prêmio.</strong>
                    </p>
                  <b-form
                    class="ff-inter"
                    @submit="onSubmit"
                  >
                    <b-form-group label="Apelido" class="mt-4">
                      <b-input-group
                        prepend=" "
                        class="mb-2 mr-sm-2 mb-sm-0"
                      >
                        <span
                          ><b-img
                            :src="require('../assets/icons/outline/user.svg')"
                            fluid
                            alt=""
                            class="icon-white icon-sm"
                          ></b-img
                        ></span>
                        <b-form-input
                          placeholder="Digite seu apelido"
                          type="text"
                          v-model="user.username"
                          @input="$v.user.username.$touch()"
                        ></b-form-input>
                      </b-input-group>
                      <b-form-invalid-feedback
                        :state="isUsernameValid"
                        v-for="(error, i) in errors.username" :key=i
                      >
                        {{ error }}
                      </b-form-invalid-feedback>
                    </b-form-group>

                    <b-form-group label="CPF ou Passaporte" class="mt-4">
                      <b-input-group
                        prepend=" "
                        class="mb-2 mr-sm-2 mb-sm-0"
                      >
                        <span
                          ><b-img
                            :src="require('../assets/icons/outline/document.svg')"
                            fluid
                            alt=""
                            class="icon-white icon-sm"
                          ></b-img
                        ></span>
                        <b-form-input
                          placeholder="Digite seu CPF ou Passaporte"
                          type="text"
                          v-model="user.identification"
                          @input="$v.user.identification.$touch()"
                        ></b-form-input>
                      </b-input-group>
                      <b-form-invalid-feedback
                        :state="isIdentificationValid"
                        v-for="(error, i) in errors.identification" :key=i
                      >
                        {{ error }}
                      </b-form-invalid-feedback>
                    </b-form-group>

                    <b-form-group label="Data de nascimento" class="mt-4">
                      <b-input-group
                        prepend=" "
                        class="mb-2 mr-sm-2 mb-sm-0"
                      >
                        <span
                          ><b-img
                            :src="require('../assets/icons/outline/calendar.svg')"
                            fluid
                            alt=""
                            class="icon-white icon-sm"
                          ></b-img
                        ></span>
                        <b-form-input
                          placeholder="Digite sua data de nascimento"
                          type="date"
                          v-model="user.birthDate"
                          @input="$v.user.birthDate.$touch()"
                        ></b-form-input>
                      </b-input-group>
                      <b-form-invalid-feedback
                        :state="isBirthDateValid"
                        v-for="(error, i) in errors.birthDate" :key=i
                      >
                        {{ error }}
                      </b-form-invalid-feedback>
                    </b-form-group>

                    <b-button type="submit" class="btn-3 w-100 py-3 mt-4">
                      <b-img
                        :src="require('../assets/icons/broken/directbox-receive.svg')"
                        fluid
                        alt=""
                        class="icon-dark icon-sm"
                      ></b-img>
                      <strong> FINALIZAR CADASTRO </strong>
                    </b-button>
                  </b-form>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
    <b-modal id="modal-scoped" size="lg">
      <template #modal-title>
        <h6 class="ff-chaney mb-0">
          <b-img
            :src="require('../assets/icons/broken/trash.svg')"
            fluid
            alt=""
            class="icon-green icon-sm"
          ></b-img>
          Confirme os dados
        </h6>
      </template>
      <div class="d-block">
        <p>Apelido: {{ user.username }}</p>
        <p>CPF ou Passaporte: {{ user.identification }}</p>
        <p>Data de nascimento: {{ user.birthDate }}</p>
        <p class="text-center mb-0">
          <strong> Os dados estão corretos? </strong>
        </p>
      </div>
      <template #modal-footer="{ hide }">
        <b-container>
          <b-row>
            <b-col class="col-12 col-lg-6 py-3 py-lg-0 pl-lg-0">
              <b-button class="btn-1 w-100 py-3" @click="hide()">
                <b-img
                  :src="require('../assets/icons/broken/close-square.svg')"
                  fluid
                  alt=""
                  class="icon-white icon-sm"
                ></b-img>
                <strong> CANCELAR </strong>
              </b-button>
            </b-col>
            <b-col class="col-12 col-lg-6 py-3 py-lg-0 pr-lg-0">
              <b-button class="btn-3 w-100 py-3" @click="validateEmail">
                <b-img
                  :src="require('../assets/icons/broken/tick-square.svg')"
                  fluid
                  alt=""
                  class="icon-dark icon-sm"
                ></b-img>
                <strong> CONFIRMAR </strong>
              </b-button>
            </b-col>
          </b-row>
        </b-container>
      </template>
    </b-modal>
  </div>
</template>

<style lang="scss">
.bg-ondas {
  background-size: cover;
  background-position: center center;
}
.s1 .card {
  background-color: #13151b;
  border: 2px solid #20232c;
  border-radius: 16px;
}
</style>

<script>
import UserService from '../services/users/user'
import setsNotify from '../mixins/sets-notify.mixin'
import formErrors from '../mixins/form-errors.mixin'
import { required } from 'vuelidate/lib/validators'
export default {
  name: 'Redefinir',
  mixins: [setsNotify, formErrors],
  components: {
  },
  metaInfo () {
    return {
      meta: [{
        lang: 'pt-BR',
        title: 'CartoLOL - Validar email',
        descrition: 'Quer receber os panoramas mais assertivos dos principais torneios de esports do mundo, mostrar que é pé quente nos palpites e de quebra montar o seu próprio time e mostrar que é o melhor manager do Brasil? Acesse nossos serviços e saiba mais.',
        keywords: 'bet, aposta, call, cblol, lol, cs, league-of-legends, betclub, esports, palpites, myhint, hint, bolão, bolao, baiano, baianolol, baianolol1, fantasy, cartolol, cartola-do-lol',
        site_name: 'CartoLOL',
        type: 'website',
        robots: 'index, follow'
      }]
    }
  },
  data: () => ({
    userService: new UserService(),
    user: {
      vericationCode: '',
      identification: '',
      birthDate: '',
      username: ''
    },
    errors: { vericationCode: [], birthDate: [], identification: [], username: [] },
    done: false,
    loading: false,
    busy: false,
    msg: 'Validando email...'
  }),
  validations: {
    user: {
      birthDate: {
        required
      },
      identification: {
        required
      },
      username: {
        required
      }
    }
  },
  watch: {
    '$v.user.username.$error' (newVal, oldVal) {
      const requiredField = 'Este campo é obrigatório.'
      this.errors.username = this.errors.username.filter(item => item !== requiredField)
      if (!this.$v.user.username.required) {
        this.errors.username.push(requiredField)
      }
    },
    '$v.user.birthDate.$error' (newVal, oldVal) {
      const requiredField = 'Este campo é obrigatório.'
      this.errors.birthDate = this.errors.birthDate.filter(item => item !== requiredField)
      if (!this.$v.user.birthDate.required) {
        this.errors.birthDate.push(requiredField)
      }
    },
    '$v.user.identification.$error' (newVal, oldVal) {
      const requiredField = 'Este campo é obrigatório.'
      this.errors.identification = this.errors.identification.filter(item => item !== requiredField)
      if (!this.$v.user.identification.required) {
        this.errors.identification.push(requiredField)
      }
    }
  },
  computed: {
    isUsernameValid () {
      return this.errors.username.length === 0 ? null : false
    },
    isBirthDateValid () {
      return this.errors.birthDate.length === 0 ? null : false
    },
    isIdentificationValid () {
      return this.errors.identification.length === 0 ? null : false
    }
  },
  methods: {
    onSubmit (event) {
      event.preventDefault()
      const fields = [this.$v.user]
      this.verifyErrorsForm(fields).then((resp) => {
        this.$bvModal.show('modal-scoped')
      }).catch((err) => {
        this.setNotifyDanger(err.message)
      })
    },
    async validateEmail () {
      this.busy = true
      try {
        this.user.verificationCode = this.$route.query.token
        await this.userService.verificationCode(this.user)
        this.$router.push('/login', () => {
          this.setNotifySuccess('Email validado com sucesso.')
        })
        if (this.$gtag) {
          this.$gtag.event('Email Validado', { event_category: 'User' })
        }
      } catch (error) {
        this.done = false
        this.msg = 'Falha ao validar email.'
        if (error.name === 400 && this.isJson(error.message)) {
          this.errors = this.parseServerSideErros(this.errors, JSON.parse(error.message))
          this.errors.verificationCode.forEach(msg => {
            this.setNotifyDanger(`${msg}. Error: ${error.name}.`)
          })
          return
        }
        this.setNotifyDanger(`${error.message}. Error: ${error.name}.`)
      } finally {
        this.busy = false
      }
    }
  },
  head: {
    title: 'CartoLOL - Validar email'
  }
}
</script>
