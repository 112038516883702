<template>
  <b-overlay :show="failed || loading" bg-color="#000000" no-center>
    <template #overlay>
      <div v-if="failed" class="text-center">
        <b-icon
          icon="exclamation-triangle-fill"
          font-scale="3"
          variant="danger"
        ></b-icon>
        <p>Houve um problema ao carregar a pagina:</p>
        <p>{{ error_message }}</p>
        <b-button class="btn-default-secundary" size="sm" to="/">
          Voltar
        </b-button>
        <b-button class="btn-default ml-3" size="sm" @click="activeFantasy">
          Tentar Novamente
        </b-button>
      </div>
      <div v-else class="text-center mt-5">
        <b-icon icon="three-dots" animation="cylon" font-scale="4"></b-icon>
        <p>Carregando...</p>
      </div>
    </template>
    <div>
      <div
        class="bg-capa"
        :style="{
          'background-image':
            'url(' + require('@/assets/img/bg_fantasy.png') + ')'
        }"
      >
        <div class="barra-topo-fantasy">
          <b-container fluid="xl" class="px-md-5 px-xl-0 py-3">
            <b-row>
              <b-col
                class="col-12 col-lg-4 text-center text-lg-left align-self-center py-3"
              >
                <h5 class="mb-0">
                  <b-img
                    :src="fantasy.image"
                    fluid
                    alt=""
                    style="max-width: 50px"
                    class="mr-3 mr-lg-5"
                  ></b-img>
                  {{ fantasy.name }}
                </h5>
              </b-col>
              <b-col class="col-12 col-lg-4 text-center align-self-center py-3">
                <p class="mb-0">
                  <b-img
                    :src="require('@/assets/icons/broken/people.svg')"
                    fluid
                    alt=""
                    class="icon-sm icon-white"
                  ></b-img>
                  ESCOLHENDO <b>MEU TIME</b>
                </p>
              </b-col>
              <b-col
                class="col-12 col-lg-4 text-center text-lg-right align-self-center py-3"
              >
                <p class="mb-0">
                  <b-img
                    :src="require('@/assets/icons/broken/timer.svg')"
                    fluid
                    alt=""
                    class="icon-sm icon-white"
                  ></b-img>
                  <small> {{ marketLabel }} </small>
                </p>
                <p class="mb-0">
                  <b>
                    <vue-countdown-timer
                      :end-text="''"
                      :start-time="new Date()"
                      :end-time="new Date(fantasy.current_round_lock_date)"
                      :day-txt="'dias e'"
                      :seconds-txt="''"
                      show-zero
                    ></vue-countdown-timer>
                  </b>
                </p>
              </b-col>
            </b-row>
          </b-container>
        </div>
        <b-container fluid="xl" class="px-md-5 px-xl-0 text-center py-3">
          <b-row>
            <b-col class="col-12">
              <b-img :src="fantasy.image" fluid alt="" class="py-3"></b-img>
            </b-col>
            <b-col class="col-12 py-3">
              <p class="mb-0">
                <b-button class="btn-6 px-3">
                  <b-img
                    :src="require('@/assets/icons/bold/calendar-2.svg')"
                    fluid
                    alt=""
                    class="icon-sm icon-white"
                  ></b-img>
                  {{ toDate(fantasy.event__start) }} -
                  {{ toDate(fantasy.event__end) }}
                </b-button>
              </p>
            </b-col>
            <b-col class="col-12 py-3">
              <h1 class="ff-chaney mb-0">{{ fantasy.name }}</h1>
            </b-col>
          </b-row>
        </b-container>
      </div>
      <div class="custom-tab-container mt-3">
        <div class="nav-custom custom-tab-borda-externa d-none d-sm-block">
          <b-tabs content-class="mt-3">
            <b-tab title="First" active></b-tab>
          </b-tabs>
        </div>
        <b-container fluid="xl" class="px-md-5 px-xl-0">
          <b-row>
            <b-col>
              <div class="nav-custom custom-tab-borda-interna">
                <b-tabs content-class="mt-3" v-model="selectedTab">
                  <FantasyTeamSelection
                    v-model="team"
                    :round="round"
                    :rounds="rounds"
                    :wallet="wallet"
                    :isMarketOpen="isMarketOpen"
                    :round_players="round_players"
                    :weigths="weigths"
                    :share="share"
                    @update-round="updateRound"
                  />
                  <FantasyStatistics :fantasy="fantasy" />
                  <FantasyLeaderboard :fantasy="fantasy" />
                  <FantasyInfo :fantasy="fantasy" @change-tab="onChangeTab"/>
                  <FantasyPast :fantasies="fantasies"/>
                </b-tabs>
              </div>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </div>
  </b-overlay>
</template>

<style lang="scss">
.s1 .card {
  background-color: #13151b;
  border: 2px solid #20232c;
  border-radius: 16px;
}
</style>

<script>
import FantasyService from '@/services/fantasies/fantasy.js'
import { mapGetters } from 'vuex'
import setsNotify from '@/mixins/sets-notify.mixin'
import dataFormat from '@/mixins/data-format.mixin'
import FantasyTeamSelection from '@/components/fantasy/FantasyTeamSelection'
import FantasyStatistics from '../../components/fantasy/FantasyStatistics.vue'
import FantasyLeaderboard from '../../components/fantasy/FantasyLeaderboard.vue'
import FantasyInfo from '../../components/fantasy/FantasyInfo.vue'
import FantasyPast from '../../components/fantasy/FantasyPast.vue'

export default {
  name: 'FantasyIndex',
  mixins: [setsNotify, dataFormat],
  components: {
    FantasyTeamSelection,
    FantasyStatistics,
    FantasyLeaderboard,
    FantasyInfo,
    FantasyPast
  },
  metaInfo () {
    return {
      meta: [
        {
          lang: 'pt-BR',
          title: 'CatoLOL - League Fantasy',
          descrition:
            'Quer receber os panoramas mais assertivos dos principais torneios de esports do mundo, mostrar que é pé quente nos palpites e de quebra montar o seu próprio time e mostrar que é o melhor manager do Brasil? Acesse nossos serviços e saiba mais.',
          keywords:
            'bet, aposta, call, cblol, lol, cs, league-of-legends, betclub, esports, palpites, myhint, hint, bolão, bolao, baiano, baianolol, baianolol1, fantasy, cartolol, cartola-do-lol',
          site_name: 'Catolol',
          type: 'website',
          robots: 'index, follow'
        }
      ]
    }
  },
  data: () => ({
    failed: false,
    error_message: '',
    loading: true,
    fantasies: [],
    fantasy: {
      id: null,
      current_round_lock_date: '2000-01-01',
      award: '',
      info: ''
    },
    round: { id: 0, price_locked: false },
    rounds: [],
    team: {
      top_id: null,
      jun_id: null,
      mid_id: null,
      adc_id: null,
      sup_id: null,
      coach_id: null,
      shotcaller_id: null
    },
    wallet: { credits: null },
    round_players: [],
    weigths: [],
    fantasyService: new FantasyService(),
    selectedTab: 0,
    roles: ['top', 'jun', 'mid', 'adc', 'sup'],
    share: false
  }),
  watch: {
    '$route.query.rodada' (newValue) {
      if (newValue) {
        this.updateRound(newValue)
        this.selectedTab = 0
      }
    },
    '$route.params.id' (newValue) {
      if (newValue) {
        if (this.$route.query.info !== undefined) {
          this.selectedTab = 3
        } else {
          this.selectedTab = 0
        }
        this.activeFantasy()
      }
    }
  },
  computed: {
    ...mapGetters('auth', ['isLoggedIn']),
    isMarketOpen () {
      return (
        this.round.price_locked &&
        new Date().getTime() < new Date(this.round.lock_date).getTime()
      )
    },
    marketLabel () {
      return new Date().getTime() <
        new Date(this.fantasy.current_round_lock_date).getTime()
        ? 'MERCADO FECHA EM'
        : 'MERCADO FECHADO'
    }
  },
  methods: {
    onChangeTab (tab) {
      this.selectedTab = tab
    },
    isSubscriber (id) {
      return this.subscriptions.includes(id)
    },
    async activeFantasy () {
      this.failed = false
      this.loading = true
      try {
        const { data } = await this.fantasyService.list()
        this.fantasies = data.data.sort((a, b) => {
          return b.event__end.localeCompare(a.event__end)
        })
        this.fantasy = this.$route.params.id
          ? this.fantasies.find(f => `${f.id}` === this.$route.params.id)
          : data.data.sort((a, b) => {
            return b.event__end.localeCompare(a.event__end)
          })[0]
        if (!this.fantasy) {
          throw new Error('Legue Fantasy não encontrada.')
        }
        if (this.fantasy.current_round_id) {
          const { data: round } = await this.fantasyService.get(
            this.fantasy.current_round_id
          )
          this.round = round.data
          this.rounds = round.included.rounds
          this.rounds.unshift({ id: this.round.id, number: this.round.number })
          this.share = round.included.user_team !== null
          this.team = round.included.user_team
            ? round.included.user_team
            : {
              top_id: null,
              jun_id: null,
              mid_id: null,
              adc_id: null,
              sup_id: null,
              coach_id: null,
              shotcaller_id: null
            }
          this.team.top_price = this.team.top_price
            ? this.team.top_price
            : this.team.top_player_price
          this.team.jun_price = this.team.jun_price
            ? this.team.jun_price
            : this.team.jun_player_price
          this.team.mid_price = this.team.mid_price
            ? this.team.mid_price
            : this.team.mid_player_price
          this.team.adc_price = this.team.adc_price
            ? this.team.adc_price
            : this.team.adc_player_price
          this.team.sup_price = this.team.sup_price
            ? this.team.sup_price
            : this.team.sup_player_price
          this.team.coach_price = this.team.coach_price
            ? this.team.coach_price
            : this.team.coach_player_price
          this.wallet = round.included.wallet
          this.round_players = round.included.round_players
          this.weigths = round.included.weigths
          const roles = ['top', 'jun', 'mid', 'adc', 'sup']
          roles.forEach(pos => {
            const player = this.round_players.find(
              player => player.player_id === this.team[`${pos}_id`]
            )
            if (player) {
              this.team[`${pos}_average_performance`] = player.average_performance
              this.team[`${pos}_last_performance`] = player.last_performance
            }
          })
        }
        this.loading = false
      } catch (error) {
        this.failed = true
        this.error_message = error.message
      }
    },
    async updateRound (roundId) {
      // this.loading = true
      try {
        const { data: round } = await this.fantasyService.get(roundId)
        this.round = round.data
        this.share = round.included.user_team !== null
        this.team = round.included.user_team
          ? round.included.user_team
          : {
            top_id: null,
            jun_id: null,
            mid_id: null,
            adc_id: null,
            sup_id: null,
            coach_id: null,
            shotcaller_id: null
          }
        this.team.top_price = this.team.top_price
          ? this.team.top_price
          : this.team.top_player_price
        this.team.jun_price = this.team.jun_price
          ? this.team.jun_price
          : this.team.jun_player_price
        this.team.mid_price = this.team.mid_price
          ? this.team.mid_price
          : this.team.mid_player_price
        this.team.adc_price = this.team.adc_price
          ? this.team.adc_price
          : this.team.adc_player_price
        this.team.sup_price = this.team.sup_price
          ? this.team.sup_price
          : this.team.sup_player_price
        this.team.coach_price = this.team.coach_price
          ? this.team.coach_price
          : this.team.coach_player_price
        this.wallet = round.included.wallet
        this.round_players = round.included.round_players
        this.weigths = round.included.weigths
        const roles = ['top', 'jun', 'mid', 'adc', 'sup']
        roles.forEach(pos => {
          const player = this.round_players.find(
            player => player.player_id === this.team[`${pos}_id`]
          )
          if (player) {
            this.team[`${pos}_average_performance`] = player.average_performance
            this.team[`${pos}_last_performance`] = player.last_performance
          }
        })
        // this.loading = false
      } catch (error) {
        this.setNotifyDanger(error.message)
      }
    }
  },
  mounted () {
    if (!window.__PRERENDER_INJECTED) {
      if (!this.isLoggedIn) {
        this.$router.push(
          {
            path: '/login/',
            query: { redirect: this.$router.currentRoute.fullPath }
          },
          () => {
            this.setNotifyDanger(
              'Você precisa estar logado para acessar esta página.'
            )
          }
        )
        return
      }
      this.activeFantasy()
    }
  }
}
</script>
