<template>
  <div>
    <b-container v-if="!done" fluid="xl" class="px-md-5 px-xl-0 s1">
      <b-row>
        <b-col
          class="bg-ondas py-5"
          :style="{
            'background-image':
              'url(' + require('../assets/img/ondas.png') + ')'
          }"
        >
          <b-row>
            <b-col class="d-none d-xl-block col-6 text-center py-3 align-self-center px-5">
              <b-img
                :src="require('../assets/img/login1.png')"
                fluid
                alt=""
              ></b-img>
            </b-col>
            <b-col class="col-12 col-md-8 col-lg-6 offset-md-2 offset-lg-3 offset-xl-0 col-5 pl-xl-5">
              <div class="card py-5 px-4">
                <p class="pb-5 text-center">
                  <b-img
                    :src="require('../assets/img/logo.png')"
                    fluid
                    alt=""
                    class=""
                  ></b-img>
                </p>
                <h5 class="text-white ff-chaney text-center">Iniciar Sessão</h5>
                <div class="py-5 px-3">
                  <b-form
                    class="ff-inter"
                    @submit="onSubmit"
                  >
                    <b-form-group label="Nome">
                      <b-input-group
                        prepend=" "
                        class="mb-2 mr-sm-2 mb-sm-0"
                      >
                        <span
                          ><b-img
                            :src="require('../assets/icons/outline/user.svg')"
                            fluid
                            alt=""
                            class="icon-white icon-sm"
                          ></b-img
                        ></span>
                        <b-form-input
                          placeholder="Digite seu nome"
                          v-model="user.name"
                          @input="$v.user.name.$touch()"
                        ></b-form-input>
                      </b-input-group>
                      <b-form-invalid-feedback
                        :state="isNameValid"
                        v-for="(error, i) in errors.name" :key=i
                      >
                        {{ error }}
                      </b-form-invalid-feedback>
                    </b-form-group>

                    <b-form-group label="E-mail" class="mt-4">
                      <b-input-group
                        prepend=" "
                        class="mb-2 mr-sm-2 mb-sm-0"
                      >
                        <span
                          ><b-img
                            :src="require('../assets/icons/outline/sms.svg')"
                            fluid
                            alt=""
                            class="icon-white icon-sm"
                          ></b-img
                        ></span>
                        <b-form-input
                          placeholder="Digite seu e-mail"
                          v-model="user.email"
                          @input="$v.user.email.$touch()"
                        ></b-form-input>
                      </b-input-group>
                      <b-form-invalid-feedback
                        :state="isEmailValid"
                        v-for="(error, i) in errors.email" :key=i
                      >
                        {{ error }}
                      </b-form-invalid-feedback>
                    </b-form-group>

                    <b-form-group label="Senha" class="mt-4">
                      <b-input-group
                        prepend=" "
                        class="mb-2 mr-sm-2 mb-sm-0"
                      >
                        <span
                          ><b-img
                            :src="require('../assets/icons/outline/lock.svg')"
                            fluid
                            alt=""
                            class="icon-white icon-sm"
                          ></b-img
                        ></span>
                        <b-form-input
                          placeholder="Digite sua senha"
                          type="password"
                          v-model="user.password"
                          @input="$v.user.password.$touch()"
                        ></b-form-input>
                      </b-input-group>
                      <b-form-invalid-feedback
                        :state="isPasswordValid"
                        v-for="(error, i) in errors.password" :key=i
                      >
                        {{ error }}
                      </b-form-invalid-feedback>
                    </b-form-group>

                    <b-form-group label="Confirme a Senha" class="mt-4">
                      <b-input-group
                        prepend=" "
                        class="mb-2 mr-sm-2 mb-sm-0"
                      >
                        <span
                          ><b-img
                            :src="require('../assets/icons/outline/lock.svg')"
                            fluid
                            alt=""
                            class="icon-white icon-sm"
                          ></b-img
                        ></span>
                        <b-form-input
                          placeholder="Confirme sua senha"
                          type="password"
                          v-model="user.passwordConfirmation"
                          input="$v.user.passwordConfirmation.$touch()"
                        ></b-form-input>
                      </b-input-group>
                      <b-form-invalid-feedback
                        :state="isPasswordConfirmationValid"
                        v-for="(error, i) in errors.passwordConfirmation" :key=i
                      >
                        {{ error }}
                      </b-form-invalid-feedback>
                    </b-form-group>

                    <b-row>
                      <b-col class="col-12 mt-4 text-3">
                        <b-form-group>
                            <b-form-checkbox v-model="user.privacy" @change="$v.user.privacy.$touch()"
                              >
                                <small>
                                    Li e aceito os <b-link to="/termos" class="text-white">Termos de uso</b-link> e <b-link to="/politica" class="text-white">Política de privacidade</b-link>
                                </small>
                              </b-form-checkbox
                            >
                          <b-form-invalid-feedback
                            :state="isPrivacyValid"
                            v-for="(error, i) in errors.privacy" :key=i
                          >
                            {{ error }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </b-col>
                    </b-row>

                    <b-button type="submit" class="btn-3 w-100 py-3 mt-4">
                      <b-img
                        :src="require('../assets/icons/broken/security-user.svg')"
                        fluid
                        alt=""
                        class="icon-dark icon-sm"
                      ></b-img>
                      <strong> CRIAR CONTA </strong>
                    </b-button>
                  </b-form>
                </div>
                <p class="text-center text-3">Já tem uma conta?</p>
                <p class="text-center">
                  <b-link to="/login/" class="text-white"
                    >Faça o login</b-link
                  >
                </p>
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
    <b-container v-else fluid="xl" class="px-md-5 px-xl-0 s1">
      <b-row>
        <b-col
          class="bg-ondas py-5"
          :style="{
            'background-image':
              'url(' + require('../assets/img/ondas.png') + ')'
          }"
        >
          <b-row>
            <b-col class="col-12 text-center">
              <b-img
                :src="require('../assets/img/ico_sucesso.png')"
                fluid
                alt=""
              ></b-img>
              <h4 class="pt-5">
                  Solicitação enviada com sucesso!
              </h4>
              <p class="text-3">
                  Uma mensagem foi enviada para o seu e-mail <span class="text-white">{{ user.email }}</span> <br>
                  Siga as instruções da mensagem para ativar sua conta.
              </p>
              <p class="text-center text-3">Não recebeu o e-mail?</p>
                <p class="text-center">
                  <b-link to="/reenviar-email/" class="text-white"
                    >Reenviar email</b-link
                  >
                </p>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import setsNotify from '../mixins/sets-notify.mixin'
import formErrors from '../mixins/form-errors.mixin'
import { required, email, sameAs } from 'vuelidate/lib/validators'
import UserService from '../services/users/user'
export default {
  name: 'Register',
  mixins: [setsNotify, formErrors],
  metaInfo () {
    return {
      meta: [{
        lang: 'pt-BR',
        title: 'HintClub - Criar conta',
        descrition: 'Quer receber os panoramas mais assertivos dos principais torneios de esports do mundo, mostrar que é pé quente nos palpites e de quebra montar o seu próprio time e mostrar que é o melhor manager do Brasil? Acesse nossos serviços e saiba mais.',
        keywords: 'bet, aposta, call, cblol, lol, cs, league-of-legends, betclub, esports, palpites, myhint, hint, bolão, bolao, baiano, baianolol, baianolol1, fantasy, cartolol, cartola-do-lol',
        site_name: 'HintClub',
        type: 'website',
        robots: 'index, follow'
      }]
    }
  },
  data: () => ({
    userService: new UserService(),
    user: {
      name: '',
      email: '',
      password: '',
      passwordConfirmation: '',
      privacy: false,
      cookies: true,
      token: ''
    },
    errors: { name: [], email: [], password: [], passwordConfirmation: [], privacy: [], token: [] },
    loading: false,
    done: false
  }),
  validations: {
    user: {
      name: {
        required
      },
      email: {
        required,
        email
      },
      password: {
        required
      },
      passwordConfirmation: {
        required,
        sameAsPassword: sameAs('password')
      },
      privacy: {
        sameAs: sameAs(() => true)
      }

    }
  },
  watch: {
    '$v.user.name.$error' (newVal, oldVal) {
      const requiredField = 'Este campo é obrigatório.'
      this.errors.name = this.errors.name.filter(item => item !== requiredField)
      if (!this.$v.user.name.required) {
        this.errors.name.push(requiredField)
      }
    },
    '$v.user.email.$error' (newVal, oldVal) {
      const validEmail = 'Insira um endereço de email válido.'
      const requiredField = 'Este campo é obrigatório.'
      this.errors.email = this.errors.email.filter(item => item !== validEmail)
      if (!this.$v.user.email.email) {
        this.errors.email.push(validEmail)
      }
      this.errors.email = this.errors.email.filter(item => item !== requiredField)
      if (!this.$v.user.email.required) {
        this.errors.email.push(requiredField)
      }
    },
    '$v.user.password.$error' (newVal, oldVal) {
      const requiredField = 'Este campo é obrigatório.'
      this.errors.password = this.errors.password.filter(item => item !== requiredField)
      if (!this.$v.user.password.required) {
        this.errors.password.push(requiredField)
      }
    },
    '$v.user.passwordConfirmation.$error' (newVal, oldVal) {
      const requiredField = 'Este campo é obrigatório.'
      const sameAsPassword = 'As senhas devem ser iguais.'
      this.errors.passwordConfirmation = this.errors.passwordConfirmation.filter(item => item !== requiredField)
      if (!this.$v.user.passwordConfirmation.required) {
        this.errors.passwordConfirmation.push(requiredField)
      }
      this.errors.passwordConfirmation = this.errors.passwordConfirmation.filter(item => item !== sameAsPassword)
      if (!this.$v.user.passwordConfirmation.sameAsPassword) {
        this.errors.passwordConfirmation.push(sameAsPassword)
      }
    },
    '$v.user.privacy.$error' (newVal, oldVal) {
      const requiredField = 'Este campo é obrigatório.'
      this.errors.privacy = this.errors.privacy.filter(item => item !== requiredField)
      if (!this.$v.user.privacy.sameAs) {
        this.errors.privacy.push(requiredField)
      }
    }
  },
  computed: {
    isNameValid () {
      return this.errors.name.length === 0 ? null : false
    },
    isEmailValid () {
      return this.errors.email.length === 0 ? null : false
    },
    isPasswordValid () {
      return this.errors.password.length === 0 ? null : false
    },
    isPasswordConfirmationValid () {
      return this.errors.passwordConfirmation.length === 0 ? null : false
    },
    isPrivacyValid () {
      return this.errors.privacy.length === 0 ? null : false
    }
  },
  methods: {
    onSubmit (event) {
      event.preventDefault()
      const fields = [this.$v.user]
      this.verifyErrorsForm(fields).then((resp) => {
        this.register()
      }).catch((err) => {
        this.setNotifyDanger(err.message)
      })
    },
    async register () {
      this.loading = true
      try {
        await this.userService.register(this.user)
        this.done = true
        if (this.$gtag) {
          this.$gtag.event('Cadastro', { event_category: 'User' })
        }
      } catch (error) {
        if (error.name === 400 && this.isJson(error.message)) {
          this.errors = this.parseServerSideErros(this.errors, JSON.parse(error.message))
          this.errors.token.forEach(msg => {
            this.setNotifyDanger(`${msg}. Error: ${error.name}.`)
          })
          return
        }
        this.setNotifyDanger(`${error.message}. Error: ${error.name}.`)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss">
  .bg-ondas {
    background-size: cover;
    background-position: center center;
  }
  .s1 .card {
    background-color: #13151b;
    border: 2px solid #20232c;
    border-radius: 16px;
  }
</style>
