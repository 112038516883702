<template>
  <!-- TAB Informações -->
  <b-tab>
    <template #title>
      <b-img
        :src="require('@/assets/icons/broken/info-circle.svg')"
        fluid
        alt=""
        class="icon-sm"
      ></b-img>
      Informações
    </template>
    <div class="py-5">
      <b-row>
        <b-col class="col-12 pb-5">
          <b-img
            :src="require('@/assets/icons/broken/info-circle.svg')"
            fluid
            alt=""
            class="icon-sm icon-green"
          ></b-img>
          INFORMAÇÕES
        </b-col>
      </b-row>
      <div>
        <b-row>
          <b-col class="col-12 pb-4">
            <p class="text-3" v-for="(text, index) in info" :key="`info-${index}`">
               <span v-html="text"></span>
            </p>
            <!-- <p>
              <b-button class="btn-3 py-3 px-5 m-0" @click="$emit('change-tab', 0)">
                <b-img
                  :src="require('@/assets/icons/broken/people.svg')"
                  fluid
                  alt=""
                  class="icon-dark btn-img mr-1"
                ></b-img>
                <strong>MONTE SEU TIME</strong>
              </b-button>
            </p> -->
          </b-col>
        </b-row>
        <b-row>
          <b-col class="col-12 col-lg-6">
            <b-row class="justify-content-center">
              <b-col class="col-12 pb-4 py-3 text-center">
                <b-img
                  :src="require('@/assets/icons/broken/cup.svg')"
                  fluid
                  alt=""
                  class="icon-sm icon-green"
                ></b-img>
                PREMIAÇÃO GERAL
              </b-col>
            </b-row>
            <b-row
              class="justify-content-center"
              v-for="(text, index) in award"
              :key="`geral-${index}`"
            >
              <b-col class="col-10 col-lg-8 pb-4 border-bottom pt-3">
                <div class="row text-center">
                  <div class="col-4">
                    <h5 class="mb-0">
                      <b> {{ text.split(':')[0] }} </b>
                    </h5>
                  </div>
                  <div class="col-8">
                    <h5 class="mb-0">
                      <b> {{ text.split(':')[1] }} </b>
                    </h5>
                  </div>
                </div>
              </b-col>
            </b-row>
            <b-row class="justify-content-center mt-4" v-if="award2.length">
              <b-col class="col-12 pb-4 py-3 text-center">
                <b-img
                  :src="require('@/assets/icons/broken/cup.svg')"
                  fluid
                  alt=""
                  class="icon-sm icon-green"
                ></b-img>
                PREMIAÇÃO SEMANAL
              </b-col>
            </b-row>
            <b-row
              class="justify-content-center"
              v-for="(text, index) in award2"
              :key="`semanal-${index}`"
            >
              <b-col class="col-10 col-lg-8 pb-4 border-bottom pt-3">
                <div class="row text-center">
                  <div class="col-4">
                    <h5 class="mb-0">
                      <b> {{ text.split(':')[0] }} </b>
                    </h5>
                  </div>
                  <div class="col-8">
                    <h5 class="mb-0">
                      <b> {{ text.split(':')[1] }} </b>
                    </h5>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-col>
          <b-col class="col-12 col-lg-6 align-self-center pt-5 pt-md-0">
            <p class="mb-0 text-center">
              <a href="https://streamelements.com/baiano/store" target="_blank" class="text-white">
              <b-img
                :src="require('@/assets/img/baicoin.png')"
                fluid
                alt=""
              ></b-img>
              </a>
            </p>
            <h2 class="text-center pt-3">
              <a href="https://streamelements.com/baiano/store" target="_blank" class="text-white">USE AS BAICOINS NA BAILOJA</a>
            </h2>
          </b-col>
        </b-row>
      </div>
    </div>
  </b-tab>
</template>

<script>
export default {
  name: 'FantasyInfo',
  props: {
    fantasy: {
      type: Object,
      default: () => ({ info: '', award: '' })
    }
  },
  computed: {
    info () {
      if (!this.fantasy.info.includes('\n')) {
        return []
      }
      return this.fantasy.info.replace('\r', '').split('\n')
    },
    award () {
      if (!this.fantasy.award.includes('\n')) {
        return []
      }
      return this.fantasy.award
        .replace('\r', '')
        .split('\n##\n')[0]
        .split('\n')
    },
    award2 () {
      if (!this.fantasy.award.includes('\n')) {
        return []
      }
      if (!this.fantasy.award.includes('\n##\n')) {
        return []
      }
      return this.fantasy.award
        .replace('\r', '')
        .split('\n##\n')[1]
        .split('\n')
    }
  }
}
</script>
