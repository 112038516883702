<template>
  <div>
    <b-container v-if="!emailSent" fluid="xl" class="px-md-5 px-xl-0 s1">
      <b-row>
        <b-col
          class="bg-ondas py-5"
          :style="{
            'background-image':
              'url(' + require('../assets/img/ondas.png') + ')'
          }"
        >
          <b-row>
            <b-col class="d-none d-xl-block col-6 text-center py-3 align-self-center px-5">
              <b-img
                :src="require('../assets/img/login1.png')"
                fluid
                alt=""
              ></b-img>
            </b-col>
            <b-col class="col-12 col-md-8 col-lg-6 offset-md-2 offset-lg-3 offset-xl-0 col-5 pl-xl-5">
              <div class="card py-5 px-4">
                <p class="pb-5 text-center">
                  <b-img
                    :src="require('../assets/img/logo.png')"
                    fluid
                    alt=""
                    class=""
                  ></b-img>
                </p>
                <h5 class="text-white ff-chaney text-center">Esqueceu a senha?</h5>
                <p class="text-center text-3">Insira seu e-mail para redefinir sua senha.</p>
                <div class="py-5 px-3">
                  <b-form
                    class="ff-inter"
                    @submit="onSubmit"
                  >
                    <b-form-group label="E-mail">
                      <b-input-group
                        prepend=" "
                        class="mb-2 mr-sm-2 mb-sm-0"
                      >
                        <span
                          ><b-img
                            :src="require('../assets/icons/outline/sms.svg')"
                            fluid
                            alt=""
                            class="icon-white icon-sm"
                          ></b-img
                        ></span>
                        <b-form-input
                          v-model="user.email"
                          placeholder="Digite seu e-mail"
                          @input="$v.user.email.$touch()"
                        ></b-form-input>
                      </b-input-group>
                      <b-form-invalid-feedback
                          :state="isEmailValid"
                          v-for="(error, i) in errors.email" :key=i
                      >
                            {{ error }}
                      </b-form-invalid-feedback>
                    </b-form-group>

                    <b-button type="submit" class="btn-3 w-100 py-3 mt-4">
                      <b-img
                        :src="require('../assets/icons/broken/key.svg')"
                        fluid
                        alt=""
                        class="icon-dark icon-sm"
                      ></b-img>
                      <strong> REDEFINIR A SENHA </strong>
                    </b-button>
                  </b-form>
                </div>
                <p class="text-center">
                  <b-link to="/login" class="text-white"
                    >Voltar ao Login</b-link
                  >
                </p>
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
    <b-container v-else fluid="xl" class="px-md-5 px-xl-0 s1">
      <b-row>
        <b-col
          class="bg-ondas py-5"
          :style="{
            'background-image':
              'url(' + require('../assets/img/ondas.png') + ')'
          }"
        >
          <b-row>
            <b-col class="col-12 text-center">
              <b-img
                :src="require('../assets/img/ico_sucesso.png')"
                fluid
                alt=""
              ></b-img>
              <h4 class="pt-5">
                  Solicitação enviada com sucesso!
              </h4>
              <p class="text-3">
                  Uma mensagem foi enviada para o seu e-mail <span class="text-white">{{ user.email }}</span> <br>
                  Siga as instruções da mensagem para redefinir sua senha.
              </p>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import setsNotify from '../mixins/sets-notify.mixin'
import formErrors from '../mixins/form-errors.mixin'
import { required, email } from 'vuelidate/lib/validators'
import UserService from '../services/users/user'
export default {
  name: 'Redefinir',
  mixins: [setsNotify, formErrors],
  metaInfo () {
    return {
      meta: [{
        lang: 'pt-BR',
        title: 'CartoLOL - Esqueceu a senha',
        descrition: 'Quer receber os panoramas mais assertivos dos principais torneios de esports do mundo, mostrar que é pé quente nos palpites e de quebra montar o seu próprio time e mostrar que é o melhor manager do Brasil? Acesse nossos serviços e saiba mais.',
        keywords: 'bet, aposta, call, cblol, lol, cs, league-of-legends, betclub, esports, palpites, myhint, hint, bolão, bolao, baiano, baianolol, baianolol1, fantasy, cartolol, cartola-do-lol',
        site_name: 'CartoLOL',
        type: 'website',
        robots: 'index, follow'
      }]
    }
  },
  data: () => ({
    userService: new UserService(),
    emailSent: false,
    user: {
      email: ''
    },
    errors: { email: [] },
    loading: false
  }),
  validations: {
    user: {
      email: {
        required,
        email
      }
    }
  },
  watch: {
    '$v.user.email.$error' (newVal, oldVal) {
      const validEmail = 'Insira um endereço de email válido.'
      const requiredField = 'Este campo é obrigatório.'
      this.errors.email = this.errors.email.filter(item => item !== validEmail)
      if (!this.$v.user.email.email) {
        this.errors.email.push(validEmail)
      }
      this.errors.email = this.errors.email.filter(item => item !== requiredField)
      if (!this.$v.user.email.required) {
        this.errors.email.push(requiredField)
      }
    }
  },
  computed: {
    isEmailValid () {
      return this.errors.email.length === 0 ? null : false
    }
  },
  methods: {
    onSubmit (event) {
      event.preventDefault()
      const fields = [this.$v.user]
      this.verifyErrorsForm(fields).then((resp) => {
        this.forgotPassword()
      }).catch((err) => {
        this.setNotifyDanger(err.message)
      })
    },
    async forgotPassword () {
      this.loading = true
      try {
        await this.userService.forgotPassword(this.user)
        this.emailSent = true
        // this.$router.push('/login', () => {
        //   this.setNotifySuccess('Email de redefinição de senha enviado com sucesso.')
        // })
        if (this.$gtag) {
          this.$gtag.event('Solicitar Refenir Senha', { event_category: 'User' })
        }
      } catch (error) {
        if (error.name === 400 && this.isJson(error.message)) {
          this.errors = this.parseServerSideErros(this.errors, JSON.parse(error.message))
          return
        }
        this.setNotifyDanger(`${error.message}. Error: ${error.name}.`)
      } finally {
        this.loading = false
      }
    }
  },
  head: {
    title: 'CartoLOL - Redefinir'
  }
}
</script>

<style lang="scss">
  .bg-ondas {
    background-size: cover;
    background-position: center center;
  }
  .s1 .card {
    background-color: #13151b;
    border: 2px solid #20232c;
    border-radius: 16px;
  }
</style>
