<template>
  <b-tab>
    <template #title>
      <b-img
        :src="require('@/assets/icons/outline/task.svg')"
        fluid
        alt=""
        class="icon-sm"
      ></b-img>
      Listagem
    </template>
    <div class="pt-1">
      <hr class="mt-3 mb-5" />
      <b-row>
        <b-col class="col-12">
          <b-img
            :src="require('@/assets/icons/broken/game.svg')"
            fluid
            alt=""
            class="icon-sm icon-green mr-3"
          ></b-img>
          JOGADORES
        </b-col>
      </b-row>
      <b-row>
        <b-col class="mt-3">
          <table class="table custom-table">
            <thead>
              <tr>
                <th scope="col" class="text-center"></th>
                <th scope="col" class="text-center">TIME</th>
                <th scope="col">JOGADOR</th>
                <th scope="col">MÉDIA</th>
                <th scope="col">ÚLTIMA RODADA</th>
                <th scope="col">PREÇO</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              <FantasyListPlayer
                v-for="(role, index) in roles"
                :key="index"
                :id="team[`${role}_id`]"
                :name="team[`${role}_nickname`]"
                :role="role"
                :team="team[`${role}_team_shortcut`]"
                :player_image="team[`${role}_image`]"
                :team_image="team[`${role}_team_image`]"
                :price="team[`${role}_price`]"
                :points="team[`${role}_point`]"
                :average_performance="team[`${role}_average_performance`]"
                :last_performance="team[`${role}_last_performance`]"
                :shotcaller="team.shotcaller_id && team[`${role}_id`] === team.shotcaller_id"
                :isMarketOpen="isMarketOpen"
                :selectedRole="selectedRole"
                @select-role="$emit('select-role', role)"
                @select-shotcaller="$emit('select-shotcaller', team[`${role}_id`])"
              />
            </tbody>
          </table>
        </b-col>
        <div class="w-100 d-none d-xl-block"></div>
        <!--CARD COACH-->
        <FantasyCardCoach
          :id="team[`coach_id`]"
          :name="team[`coach_nickname`]"
          :team="team[`coach_team_shortcut`]"
          :player_image="team[`coach_image`]"
          :team_image="team[`coach_team_image`]"
          :price="team[`coach_price`]"
          :points="team[`coach_point`]"
          :isMarketOpen="isMarketOpen"
          :selectedRole="selectedRole"
          @select-role="$emit('select-role', 'coach')"
        />
        <FantasySummary :isMarketOpen="isMarketOpen" :team="team" :wallet="wallet" :round="round.id" :share="share" @clear-team="$emit('clear-team')"/>
      </b-row>
    </div>
  </b-tab>
</template>

<script>
import FantasyCardCoach from '@/components/fantasy/FantasyCardCoach.vue'
import FantasySummary from '@/components/fantasy/FantasySummary.vue'
import FantasyListPlayer from '@/components/fantasy/FantasyListPlayer.vue'
export default {
  name: 'FantasyTeamSelectionList',
  components: {
    FantasySummary, FantasyCardCoach, FantasyListPlayer
  },
  props: {
    team: {
      type: Object,
      default: () => ({ top_id: null, jun_id: null, mid_id: null, adc_id: null, sup_id: null, coach_id: null, shotcaller_id: null })
    },
    round: {
      type: Object,
      default: () => ({ id: 0, number: 0 })
    },
    isMarketOpen: {
      type: Boolean,
      default: false
    },
    wallet: {
      type: Object,
      default: () => ({ credits: null })
    },
    selectedRole: {
      type: String,
      default: null
    },
    share: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      roles: ['top', 'jun', 'mid', 'adc', 'sup']
    }
  }
}
</script>
