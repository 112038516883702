var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-col',{staticClass:"col-12 col-md-4 col-xl-3 offset-xl-1 py-3",attrs:{"id":"player-coach"}},[_c('div',{staticClass:"text-center py-3"},[_c('h3',{staticClass:"mb-0"},[_c('b-img',{staticClass:"mr-2",attrs:{"src":require("@/assets/img/ico_coach.png"),"fluid":"","alt":""}}),_vm._v(" COACH ")],1)]),_c('b-link',{staticClass:"text-body",on:{"click":function($event){return _vm.$emit('select-role', 'coach')}}},[_c('div',{staticClass:"card-player-container",class:{active: _vm.selectedRole === 'coach'}},[_c('div',{staticClass:"card-player-outer"},[_c('div',{staticClass:"card-player-inner",style:({
            background: 'url(' + require('@/assets/img/bg_player.png') + ')',
          })},[(_vm.id)?_c('div',{staticClass:"card-player",style:({
              'background-image':
                'url(' + _vm.player_image + ')',
            })},[_c('div',{staticClass:"card-player-overlay"}),_c('div',{staticClass:"card-player-team text-right mr-3 mt-2"},[_c('b-img',{staticStyle:{"max-height":"36px"},attrs:{"src":_vm.team_image,"fluid":"","alt":""}})],1),_c('div',{staticClass:"card-player-text-lane"},[_c('b-img',{attrs:{"src":require("@/assets/img/text_lane_coach.png"),"fluid":"","alt":""}})],1),_c('div',{staticClass:"card-player-desc text-center"},[_c('p',{staticClass:"mb-0"},[_c('b',{staticClass:"text-uppercase"},[_vm._v(" "+_vm._s(_vm.name)+" ")])]),_c('p',{staticClass:"mb-0 text-3"},[_c('small',[_vm._v(" "+_vm._s(_vm.team)+" ")])])])]):_c('div',{staticClass:"card-player",style:({
              'background-image':
                'url(' +
                require('@/assets/img/ico_select_player.png') +
                ')',
            })},[_c('div',{staticClass:"card-player-team text-right mr-3 mt-2 mb-3"},[_c('b-img',{staticStyle:{"max-height":"36px","visibility":"hidden"},attrs:{"src":require('@/assets/img/card_player_team.png'),"fluid":"","alt":""}})],1),_c('div',{staticClass:"card-player-text-lane"},[_c('b-img',{staticStyle:{"visibility":"hidden"},attrs:{"src":require('@/assets/img/text_lane_coach.png'),"fluid":"","alt":""}})],1),_c('div',{staticClass:"card-player-desc text-center"},[_c('p',{staticClass:"mb-0 text-white"},[(_vm.isMarketOpen)?_c('b',[_vm._v(" Escolha um treinador ")]):_c('b',[_vm._v(" Sem treinador")])]),_c('p',{staticClass:"mb-0 text-3"},[(_vm.isMarketOpen)?_c('small',[_vm._v(" Clique aqui para escolher ")]):_c('small',[_vm._v(" Você não selecionou um treinador nesta rodada ")])])])])])]),(_vm.id)?_c('div',{staticClass:"card-player-change"},[_c('div',{staticClass:"card-player-change-inner"},[_c('div',{staticClass:"text-center pb-3 pt-3"},[_c('b-img',{staticClass:"icon-white",staticStyle:{"height":"100px"},attrs:{"src":require("@/assets/icons/bold/signpost.svg"),"fluid":"","alt":""}})],1),_c('p',{staticClass:"text-white text-center mb-0"},[_vm._v(" Trocar de Coach ")]),_c('p',{staticClass:"text-center text-3 mb-0"},[_c('small',[_vm._v("Clique aqui para escolher")])])])]):_vm._e()])]),_c('div',{staticClass:"card-player-price text-center mt-2"},[(_vm.points)?_c('span',{staticClass:"px-3 py-2"},[_c('b',[_vm._v(_vm._s(_vm.points))]),_vm._v(" pts ")]):_c('span',{staticClass:"px-3 py-2"},[_vm._v(" "+_vm._s(_vm.pricePrefix)+" "),_c('b',[_vm._v(_vm._s(_vm.price))])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }