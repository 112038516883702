<template>
  <div>
    <div
      class="bg-capa"
      :style="{
        'background-image':
          'url(' + require('@/assets/img/bg_perfil.png') + ')'
      }"
    >
      <b-container fluid="xl" class="px-md-5 px-xl-0">
        <b-row>
          <b-col class="mt-5 text-center mb-3">
            <div class="container-avatar-lg mr-auto ml-auto">
              <b-avatar
                :src="userInfos.image"
                :text="firstLetter(userInfos.username)"
                size="200px"
              />
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <h5 class="text-white ff-chaney text-center">{{ profileOwner ? userInfos.name : userInfos.username }}</h5>
            <p class="text-center text-3" v-if="profileOwner">{{ userInfos.username }}</p>
          </b-col>
        </b-row>
        <b-row>
              <b-col class="py-3 col-12 col-lg-6 offset-lg-3">
                <b-link class="btn btn-1 py-3 px-3 m-2 d-block btn-secondary" v-if="profileOwner" to="/alterar-perfil/">
                  <b-img
                    :src="require('../assets/icons/bold/edit.svg')"
                    fluid
                    alt=""
                    class="icon-white btn-img mr-1"
                  ></b-img>
                  ATUALIZAR PERFIL
                </b-link>
                <b-link class="btn btn-1 py-3 px-3 m-2 d-block btn-secondary" v-else-if="!isMyFriend(userInfos.id) && userInfos.id !== userID" @click="addPublicFriend()">
                  <b-img
                    :src="require('../assets/icons/bold/user.svg')"
                    fluid
                    alt=""
                    class="icon-white btn-img mr-1"
                  ></b-img>
                  SOLICITAR AMIZADE
                </b-link>
                <b-link class="btn btn-1 py-3 px-3 m-2 d-block btn-secondary" v-else-if="userInfos.id !== userID" @click="undoPublicFriend()">
                  <b-img
                    :src="require('../assets/icons/bold/user.svg')"
                    fluid
                    alt=""
                    class="icon-white btn-img mr-1"
                  ></b-img>
                  DESFAZER AMIZADE
                </b-link>
              </b-col>
            </b-row>
      </b-container>
    </div>
    <div class="custom-tab-container mt-3">
      <div class="nav-custom custom-tab-borda-externa d-none d-sm-block">
        <b-tabs content-class="mt-3">
          <b-tab title="First" active></b-tab>
        </b-tabs>
      </div>
      <b-container fluid="xl" class="px-md-5 px-xl-0 s1">
        <b-row>
          <b-col>
            <div class="nav-custom custom-tab-borda-interna">
              <b-tabs content-class="mt-3" v-model="selectedTab">
                <b-tab>
                  <template #title>
                    <b-img
                      :src="require('@/assets/icons/broken/element-3.svg')"
                      fluid
                      alt=""
                      class="icon-sm"
                    ></b-img>
                    Resumo
                  </template>
                  <div class="py-5">
                    <b-row>
                      <b-col class="col-12 pb-5">
                        <b-img
                          :src="require('@/assets/icons/broken/chart-1.svg')"
                          fluid
                          alt=""
                          class="icon-sm icon-green"
                        ></b-img>
                        ESTATÍSTICAS
                      </b-col>
                      <b-col>
                        <b-row>
                          <b-col class="col-12 col-md-4">
                            <div class="quadro2 py-3 px-4 mt-4">
                              <h2 class="">{{haveContent(userSocial.fantasyStats.fantasy_rounds_played)}}</h2>
                              <p class="text-3 mb-1">
                                <b-img
                                  :src="
                                    require('@/assets/icons/broken/cup.svg')
                                  "
                                  fluid
                                  alt=""
                                  class="icon-sm icon-grey"
                                ></b-img>
                                Número de rodadas participadas
                              </p>
                            </div>
                          </b-col>
                          <b-col class="col-12 col-md-4">
                            <div class="quadro2 py-3 px-4 mt-4">
                              <h2 class=""><b>{{haveContent(parseFloat(userSocial.fantasyStats.best_score_all).toFixed(2))}}</b>pts</h2>
                              <p class="text-3 mb-1">
                                <b-img
                                  :src="
                                    require('@/assets/icons/broken/buy-crypto.svg')
                                  "
                                  fluid
                                  alt=""
                                  class="icon-sm icon-grey"
                                ></b-img>
                                Melhor pontuação
                              </p>
                            </div>
                          </b-col>
                          <b-col class="col-12 col-md-4">
                            <div class="quadro2 py-3 px-4 mt-4">
                              <h2 class=""><b>{{haveContent(parseFloat(userSocial.fantasyStats.best_score_round).toFixed(2))}}</b>pts</h2>
                              <p class="text-3 mb-1">
                                <b-img
                                  :src="
                                    require('@/assets/icons/broken/buy-crypto.svg')
                                  "
                                  fluid
                                  alt=""
                                  class="icon-sm icon-grey"
                                ></b-img>
                                Melhor pontuação em rodada
                              </p>
                            </div>
                          </b-col>
                          <b-col class="col-12 col-md-4">
                            <div class="quadro2 py-3 px-4 mt-4">
                              <h2 class=""><b>{{haveContent(parseFloat(userSocial.fantasyStats.worst_score_round).toFixed(2))}}</b>pts</h2>
                              <p class="text-3 mb-1">
                                <b-img
                                  :src="
                                    require('@/assets/icons/broken/buy-crypto.svg')
                                  "
                                  fluid
                                  alt=""
                                  class="icon-sm icon-grey"
                                ></b-img>
                                Pior pontuação em rodada
                              </p>
                            </div>
                          </b-col>
                          <b-col class="col-12 col-md-4">
                            <div class="quadro2 py-3 px-4 mt-4">
                              <h2 class=""><b>{{haveContent(parseFloat(userSocial.fantasyStats.average_round_score).toFixed(2))}}</b>pts</h2>
                              <p class="text-3 mb-1">
                                <b-img
                                  :src="
                                    require('@/assets/icons/broken/buy-crypto.svg')
                                  "
                                  fluid
                                  alt=""
                                  class="icon-sm icon-grey"
                                ></b-img>
                                Média de pontuação em rodada
                              </p>
                            </div>
                          </b-col>
                          <b-col class="col-12 col-md-4">
                            <div class="quadro2 py-3 px-4 mt-4">
                              <h2 class="">L$ <b>{{haveContent(parseFloat(userSocial.fantasyStats.average_team_cost).toFixed(2))}}</b></h2>
                              <p class="text-3 mb-1">
                                <b-img
                                  :src="
                                    require('@/assets/icons/broken/buy-crypto.svg')
                                  "
                                  fluid
                                  alt=""
                                  class="icon-sm icon-grey"
                                ></b-img>
                                Média de custo de time em rodadas
                              </p>
                            </div>
                          </b-col>
                        </b-row>
                      </b-col>
                    </b-row>
                    <!-- <hr class="my-5" />
                    <b-row>
                      <b-col
                        class="col-12 col-md-7 col-lg-8 col-xl-9 pb-4 align-self-center"
                      >
                        <b-img
                          :src="
                            require('@/assets/icons/broken/medal-star.svg')
                          "
                          fluid
                          alt=""
                          class="icon-sm icon-green"
                        ></b-img>
                        CONQUISTAS
                      </b-col>
                      <b-col class="col-12">
                        USAR AS MESMAS QUE JA TMEOS
                      </b-col>
                    </b-row>
                    <hr class="my-5" />
                    <b-row>
                      <b-col
                        class="col-12 col-md-7 col-lg-8 col-xl-9 pb-4 align-self-center"
                      >
                        <b-img
                          :src="require('@/assets/icons/broken/star.svg')"
                          fluid
                          alt=""
                          class="icon-sm icon-green"
                        ></b-img>
                        TIME ATUAL
                      </b-col>
                      <b-col class="col-12">
                        <b-row>
                          <b-col class="col-12 col-md-4 col-xl my-3">
                            <div class="text-center py-3">
                              <h3 class="mb-0">
                                <b-img
                                  :src="require('@/assets/img/ico_top.png')"
                                  fluid
                                  alt=""
                                  class="mr-2"
                                ></b-img>
                                TOP
                              </h3>
                            </div>
                            <div class="card-player-container">
                              <div class="card-player-outer">
                                <div
                                  class="card-player-inner"
                                  :style="{
                                    background:
                                      'url(' +
                                      require('@/assets/img/bg_player.png') +
                                      ')'
                                  }"
                                >
                                  <div
                                    class="card-player"
                                    :style="{
                                      'background-image':
                                        'url(' +
                                        require('@/assets/img/player.png') +
                                        ')'
                                    }"
                                  >
                                    <div class="card-player-overlay"></div>
                                    <div
                                      class="card-player-team text-right mr-3 mt-2"
                                    >
                                      <b-img
                                        :src="
                                          require('@/assets/img/card_player_team.png')
                                        "
                                        fluid
                                        alt=""
                                        style="max-height: 36px"
                                      ></b-img>
                                    </div>
                                    <div class="card-player-text-lane">
                                      <b-img
                                        :src="
                                          require('@/assets/img/text_lane_top.png')
                                        "
                                        fluid
                                        alt=""
                                      ></b-img>
                                    </div>
                                    <div class="card-player-desc text-center">
                                      <p class="mb-0">
                                        <b> ROBO </b>
                                      </p>
                                      <p class="mb-0 text-3">
                                        <small> PNG </small>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </b-col>
                          <b-col class="col-12 col-md-4 col-xl my-3">
                            <div class="text-center py-3">
                              <h3 class="mb-0">
                                <b-img
                                  :src="require('@/assets/img/ico_jng.png')"
                                  fluid
                                  alt=""
                                  class="mr-2"
                                ></b-img>
                                JNG
                              </h3>
                            </div>
                            <div class="card-player-container">
                              <div class="card-player-outer">
                                <div
                                  class="card-player-inner"
                                  :style="{
                                    background:
                                      'url(' +
                                      require('@/assets/img/bg_player.png') +
                                      ')'
                                  }"
                                >
                                  <div
                                    class="card-player"
                                    :style="{
                                      'background-image':
                                        'url(' +
                                        require('@/assets/img/player.png') +
                                        ')'
                                    }"
                                  >
                                    <div class="card-player-overlay"></div>
                                    <div
                                      class="card-player-team text-right mr-3 mt-2"
                                    >
                                      <b-img
                                        :src="
                                          require('@/assets/img/card_player_team.png')
                                        "
                                        fluid
                                        alt=""
                                        style="max-height: 36px"
                                      ></b-img>
                                    </div>
                                    <div class="card-player-text-lane">
                                      <b-img
                                        :src="
                                          require('@/assets/img/text_lane_jng.png')
                                        "
                                        fluid
                                        alt=""
                                      ></b-img>
                                    </div>
                                    <div class="card-player-desc text-center">
                                      <p class="mb-0">
                                        <b> ROBO </b>
                                      </p>
                                      <p class="mb-0 text-3">
                                        <small> PNG </small>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </b-col>
                          <b-col class="col-12 col-md-4 col-xl my-3">
                            <div class="text-center py-3">
                              <h3 class="mb-0">
                                <b-img
                                  :src="require('@/assets/img/ico_mid.png')"
                                  fluid
                                  alt=""
                                  class="mr-2"
                                ></b-img>
                                MID
                              </h3>
                            </div>
                            <div class="card-player-container">
                              <div class="card-player-outer">
                                <div
                                  class="card-player-inner"
                                  :style="{
                                    background:
                                      'url(' +
                                      require('@/assets/img/bg_player.png') +
                                      ')'
                                  }"
                                >
                                  <div
                                    class="card-player"
                                    :style="{
                                      'background-image':
                                        'url(' +
                                        require('@/assets/img/player.png') +
                                        ')'
                                    }"
                                  >
                                    <div class="card-player-overlay"></div>
                                    <div
                                      class="card-player-team text-right mr-3 mt-2"
                                    >
                                      <b-img
                                        :src="
                                          require('@/assets/img/card_player_team.png')
                                        "
                                        fluid
                                        alt=""
                                        style="max-height: 36px"
                                      ></b-img>
                                    </div>
                                    <div class="card-player-text-lane">
                                      <b-img
                                        :src="
                                          require('@/assets/img/text_lane_mid.png')
                                        "
                                        fluid
                                        alt=""
                                      ></b-img>
                                    </div>
                                    <div class="card-player-desc text-center">
                                      <p class="mb-0">
                                        <b> ROBO </b>
                                      </p>
                                      <p class="mb-0 text-3">
                                        <small> PNG </small>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </b-col>
                          <b-col class="col-12 col-md-4 col-xl my-3">
                            <div class="text-center py-3">
                              <h3 class="mb-0">
                                <b-img
                                  :src="require('@/assets/img/ico_adc.png')"
                                  fluid
                                  alt=""
                                  class="mr-2"
                                ></b-img>
                                ADC
                              </h3>
                            </div>
                            <div class="card-player-container">
                              <div class="card-player-outer">
                                <div
                                  class="card-player-inner"
                                  :style="{
                                    background:
                                      'url(' +
                                      require('@/assets/img/bg_player.png') +
                                      ')'
                                  }"
                                >
                                  <div
                                    class="card-player"
                                    :style="{
                                      'background-image':
                                        'url(' +
                                        require('@/assets/img/player.png') +
                                        ')'
                                    }"
                                  >
                                    <div class="card-player-overlay"></div>
                                    <div
                                      class="card-player-team text-right mr-3 mt-2"
                                    >
                                      <b-img
                                        :src="
                                          require('@/assets/img/card_player_team.png')
                                        "
                                        fluid
                                        alt=""
                                        style="max-height: 36px"
                                      ></b-img>
                                    </div>
                                    <div class="card-player-text-lane">
                                      <b-img
                                        :src="
                                          require('@/assets/img/text_lane_adc.png')
                                        "
                                        fluid
                                        alt=""
                                      ></b-img>
                                    </div>
                                    <div class="card-player-desc text-center">
                                      <p class="mb-0">
                                        <b> ROBO </b>
                                      </p>
                                      <p class="mb-0 text-3">
                                        <small> PNG </small>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </b-col>
                          <b-col class="col-12 col-md-4 col-xl my-3">
                            <div class="text-center py-3">
                              <h3 class="mb-0">
                                <b-img
                                  :src="require('@/assets/img/ico_sup.png')"
                                  fluid
                                  alt=""
                                  class="mr-2"
                                ></b-img>
                                SUP
                              </h3>
                            </div>
                            <div class="card-player-container">
                              <div class="card-player-outer">
                                <div
                                  class="card-player-inner"
                                  :style="{
                                    background:
                                      'url(' +
                                      require('@/assets/img/bg_player.png') +
                                      ')'
                                  }"
                                >
                                  <div
                                    class="card-player"
                                    :style="{
                                      'background-image':
                                        'url(' +
                                        require('@/assets/img/player.png') +
                                        ')'
                                    }"
                                  >
                                    <div class="card-player-overlay"></div>
                                    <div
                                      class="card-player-team text-right mr-3 mt-2"
                                    >
                                      <b-img
                                        :src="
                                          require('@/assets/img/card_player_team.png')
                                        "
                                        fluid
                                        alt=""
                                        style="max-height: 36px"
                                      ></b-img>
                                    </div>
                                    <div class="card-player-text-lane">
                                      <b-img
                                        :src="
                                          require('@/assets/img/text_lane_sup.png')
                                        "
                                        fluid
                                        alt=""
                                      ></b-img>
                                    </div>
                                    <div class="card-player-desc text-center">
                                      <p class="mb-0">
                                        <b> ROBO </b>
                                      </p>
                                      <p class="mb-0 text-3">
                                        <small> PNG </small>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </b-col>
                          <div class="w-100 d-none d-xl-block"></div>
                          <b-col class="col-12 col-md-4 col-xl my-3">
                            <div class="text-center py-3">
                              <h3 class="mb-0">
                                <b-img
                                  :src="require('@/assets/img/ico_coa.png')"
                                  fluid
                                  alt=""
                                  class="mr-2"
                                ></b-img>
                                COACH
                              </h3>
                            </div>
                            <div class="card-player-container">
                              <div class="card-player-outer">
                                <div
                                  class="card-player-inner"
                                  :style="{
                                    background:
                                      'url(' +
                                      require('@/assets/img/bg_player.png') +
                                      ')'
                                  }"
                                >
                                  <div
                                    class="card-player"
                                    :style="{
                                      'background-image':
                                        'url(' +
                                        require('@/assets/img/player.png') +
                                        ')'
                                    }"
                                  >
                                    <div class="card-player-overlay"></div>
                                    <div
                                      class="card-player-team text-right mr-3 mt-2"
                                    >
                                      <b-img
                                        :src="
                                          require('@/assets/img/card_player_team.png')
                                        "
                                        fluid
                                        alt=""
                                        style="max-height: 36px"
                                      ></b-img>
                                    </div>
                                    <div class="card-player-text-lane">
                                      <b-img
                                        :src="
                                          require('@/assets/img/text_lane_adc.png')
                                        "
                                        fluid
                                        alt=""
                                      ></b-img>
                                    </div>
                                    <div class="card-player-desc text-center">
                                      <p class="mb-0">
                                        <b> ROBO </b>
                                      </p>
                                      <p class="mb-0 text-3">
                                        <small> PNG </small>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </b-col>
                          <b-col class="col-12 col-md-4 col-xl my-3"></b-col>
                          <b-col class="col-12 col-md-4 col-xl my-3"></b-col>
                          <b-col class="col-12 col-md-4 col-xl my-3"></b-col>
                          <b-col class="col-12 col-md-4 col-xl my-3"></b-col>
                        </b-row>
                      </b-col>
                    </b-row> -->
                    <hr class="my-5" />
                    <b-row>
                      <b-col
                        class="col-12 col-md-7 col-lg-8 col-xl-9 pb-4 align-self-center"
                      >
                        <b-img
                          :src="require('@/assets/icons/broken/star.svg')"
                          fluid
                          alt=""
                          class="icon-sm icon-green"
                        ></b-img>
                        TIMES FAVORITOS
                      </b-col>
                      <b-col
                        class="col-12 col-md-5 col-lg-4 col-xl-3 pb-4"
                      ></b-col>
                      <b-col class="d-flex">
                        <div class="container-time-sm m-1" v-for="(team, index) in favoriteTeams" :key="index">
                          <b-img
                            :src="team.image"
                            fluid
                            :alt="team.name"
                            class="btn-time-sm"
                          ></b-img>
                        </div>
                      </b-col>
                    </b-row>
                    <!-- <hr class="my-5" />
                    <b-row>
                      <b-col
                        class="col-12 col-md-7 col-lg-8 col-xl-9 pb-4 align-self-center"
                      >
                        <b-img
                          :src="require('@/assets/icons/broken/star.svg')"
                          fluid
                          alt=""
                          class="icon-sm icon-green"
                        ></b-img>
                        TIME FAVORITO DE CONTER STRIKE
                      </b-col>
                      <b-col
                        class="col-12 col-md-5 col-lg-4 col-xl-3 pb-4"
                      ></b-col>
                      <b-col>
                        <div class="container-time-sm">
                          <b-img
                            :src="require('@/assets/img/time.png')"
                            fluid
                            alt=""
                            class="btn-time-sm"
                          ></b-img>
                        </div>
                      </b-col>
                    </b-row> -->
                  </div>
                </b-tab>
                <b-tab>
                  <template #title>
                    <b-img
                      :src="require('@/assets/icons/broken/people.svg')"
                      fluid
                      alt=""
                      class="icon-sm"
                    ></b-img>
                    Amigos
                  </template>
                  <div class="py-5">
                    <b-row class="align-items-center">
                      <b-col class="col-12 col-md-4 py-3">
                        <b-img
                          :src="require('@/assets/icons/broken/people.svg')"
                          fluid
                          alt=""
                          class="icon-sm icon-green"
                        ></b-img>
                        Amigos <span class="text-3">({{ userSocial.friends.length}})</span>
                      </b-col>
                      <b-col class="col-12 col-md-4 col-md-4 offset-md-4">
                        <b-form class="ff-inter" @submit.prevent="">
                          <b-form-group label="" class="mt-2">
                            <b-input-group
                              prepend=" "
                              class="mb-2 mr-sm-2 mb-sm-0"
                            >
                              <span
                                ><b-img
                                  :src="
                                    require('@/assets/icons/outline/search-normal.svg')
                                  "
                                  fluid
                                  alt=""
                                  class="icon-white icon-sm"
                                ></b-img
                              ></span>
                              <b-form-input
                                placeholder="Pesquise por Usuário"
                                type="text" v-model="filter"
                              ></b-form-input>
                            </b-input-group>
                          </b-form-group>
                        </b-form>
                      </b-col>
                    </b-row>

                    <hr class="my-1" />
                    <b-row class="" v-if="profileOwner">
                      <b-col
                        class="col-12 col-md-6 align-self-center mt-3 py-2"
                      >
                        <div class="tab-tipo-mapa float-left">
                          <div style="float: left" class="pt-2 mr-3">Tipo:</div>
                          <b-nav>
                            <b-nav-item :active="!showRequest" @click="showRequest = !showRequest">
                              <b-img
                                :src="require('@/assets/icons/bold/frame.svg')"
                                fluid
                                alt=""
                                class="icon-sm mr-2"
                              ></b-img>
                              Amigos
                            </b-nav-item>
                            <b-nav-item :active="showRequest" @click="showRequest = !showRequest">
                              <b-img
                                :src="
                                  require('@/assets/icons/broken/receive-square-2.svg')
                                "
                                fluid
                                alt=""
                                class="icon-sm mr-2"
                              ></b-img>
                              Solicitações
                            </b-nav-item>
                          </b-nav>
                        </div>
                      </b-col>
                      <b-col
                        class="col-12 col-md-6 align-self-center mt-3 py-2 text-right"
                      >
                        <b-button
                          class="btn-3 px-3 py-2"
                          @click="$bvModal.show('modal-scoped')"
                        >
                          <b-img
                            :src="
                              require('@/assets/icons/broken/profile-add.svg')
                            "
                            fluid
                            alt=""
                            class="icon-sm icon-black mr-1"
                          ></b-img>
                          <b>ADICIONAR AMIGO</b>
                        </b-button>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col class="pt-3">
                        <table class="table custom-table">
                          <thead>
                            <tr>
                              <th scope="col">NICKNAME</th>
                              <th scope="col"></th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(friend, index) in users" :key="index">
                              <td>
                                <div>
                                  <b-media class="">
                                    <template #aside>
                                      <div class="container-avatar">
                                        <b-avatar
                                          :src="friend.image"
                                          :text="firstLetter(friend.username)"
                                          size="40px"
                                        />
                                      </div>
                                    </template>
                                    <h5 class="mb-0 mb-1">{{ friend.username }}</h5>
                                    <!-- <p class="mb-0 text-3">SrPandaNerd</p> -->
                                  </b-media>
                                </div>
                              </td>
                              <td class="text-right">
                                <p>
                                  <b-button class="btn-1 py-2 mt-4 mr-2" v-b-tooltip.hover title="Ver Perfil" :to="{ path: '/perfil/', query: { user: friend.user_id } }">
                                    <b-img
                                      :src="
                                        require('@/assets/icons/bold/eye.svg')
                                      "
                                      fluid
                                      alt=""
                                      class="icon-white icon-sm"
                                    ></b-img>
                                  </b-button>
                                  <b-button class="btn-1 py-2 mt-4 mr-2" v-b-tooltip.hover title="Aceitar" @click="accpetFriend(friend)" v-if="showRequest && !friend.requester">
                                    <b-img
                                      :src="
                                        require('@/assets/icons/bold/profile-add.svg')
                                      "
                                      fluid
                                      alt=""
                                      class="icon-white icon-sm"
                                    ></b-img>
                                  </b-button>
                                  <b-button class="btn-1 py-2 mt-4 mr-2" v-b-tooltip.hover title="Solicitar Amizade" @click="addPublicFriend(friend.username)" v-if="!showRequest && !profileOwner && !isMyFriend(friend.user_id) && friend.user_id !== userID">
                                    <b-img
                                      :src="
                                        require('@/assets/icons/bold/profile-add.svg')
                                      "
                                      fluid
                                      alt=""
                                      class="icon-white icon-sm"
                                    ></b-img>
                                  </b-button>
                                  <b-button class="btn-1 py-2 mt-4 mr-2" v-b-tooltip.hover title="Rejeitar" @click="rejectFriend(friend)" v-if="showRequest && !friend.requester">
                                    <b-img
                                      :src="
                                        require('@/assets/icons/bold/profile-remove.svg')
                                      "
                                      fluid
                                      alt=""
                                      class="icon-white icon-sm"
                                    ></b-img>
                                  </b-button>
                                  <b-button class="btn-1 py-2 mt-4 mr-2" v-b-tooltip.hover title="Cancelar" @click="cancelFriend(friend)" v-if="showRequest && friend.requester">
                                    <b-img
                                      :src="
                                        require('@/assets/icons/bold/profile-remove.svg')
                                      "
                                      fluid
                                      alt=""
                                      class="icon-white icon-sm"
                                    ></b-img>
                                  </b-button>
                                  <b-button class="btn-1 py-2 mt-4 mr-2" v-b-tooltip.hover title="Remover" @click="onRemoveFriend(friend)" v-if="!showRequest && profileOwner">
                                    <b-img
                                      :src="
                                        require('@/assets/icons/bold/profile-remove.svg')
                                      "
                                      fluid
                                      alt=""
                                      class="icon-white icon-sm"
                                    ></b-img>
                                  </b-button>
                                  <!-- <b-button class="btn-1 py-2 mt-4">
                                    <b-img
                                      :src="
                                        require('@/assets/icons/bold/people.svg')
                                      "
                                      fluid
                                      alt=""
                                      class="icon-white icon-sm"
                                    ></b-img>
                                  </b-button> -->
                                </p>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </b-col>
                    </b-row>
                  </div>
                </b-tab>
              </b-tabs>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <b-modal id="modal-scoped" size="lg" hide-footer>
      <template #modal-title>
        <h6 class="ff-chaney mb-0">
          <b-img
            :src="require('@/assets/icons/broken/profile-add.svg')"
            fluid
            alt=""
            class="icon-green icon-sm"
          ></b-img>
          ADICIONAR AMIGO
        </h6>
      </template>
      <div class="d-block">
        <div class="">
          <b-form class="ff-inter" @submit.prevent="">
            <b-form-group label="" class="mt-4">
              <b-input-group prepend=" " class="mb-2 mr-sm-2 mb-sm-0">
                <span
                  ><b-img
                    :src="require('@/assets/icons/outline/search-normal.svg')"
                    fluid
                    alt=""
                    class="icon-white icon-sm"
                  ></b-img
                ></span>
                <b-form-input
                  placeholder="Pesquisar por Usuário ou E-mail..."
                  type="text" v-model="search"
                ></b-form-input>
              </b-input-group>
            </b-form-group>
          </b-form>
          <b-row>
            <b-button
              class="btn-3 btn-block px-3 py-2"
              @click="addFriend()"
            >
              <b-img
                :src="
                  require('@/assets/icons/broken/profile-add.svg')
                "
                fluid
                alt=""
                class="icon-sm icon-black mr-1"
              ></b-img>
              <b>ADICIONAR AMIGO</b>
            </b-button>
          </b-row>
          <!-- <b-row>
            <b-col class="pt-3">
              <table class="table custom-table">
                <tbody>
                  <tr>
                    <td>
                      <div>
                        <b-media class="">
                          <template #aside>
                            <div class="container-avatar">
                              <div
                                class="avatar"
                                :style="{
                                  'background-image':
                                    'url(' +
                                    require('@/assets/img/avatar.png') +
                                    ')'
                                }"
                              ></div>
                            </div>
                          </template>
                          <h5 class="mb-0 mb-1">Paulo Viegas</h5>
                          <p class="mb-0 text-3">SrPandaNerd</p>
                        </b-media>
                      </div>
                    </td>
                    <td class="text-right">
                      <p>
                        <b-button class="btn-1 py-2 mt-4 mr-2">
                          <b-img
                            :src="require('@/assets/icons/bold/eye.svg')"
                            fluid
                            alt=""
                            class="icon-white icon-sm"
                          ></b-img>
                        </b-button>
                        <b-button class="btn-1 py-2 mt-4 mr-2">
                          <b-img
                            :src="
                              require('@/assets/icons/bold/profile-add.svg')
                            "
                            fluid
                            alt=""
                            class="icon-white icon-sm"
                          ></b-img>
                        </b-button>
                        <b-button class="btn-1 py-2 mt-4">
                          <b-img
                            :src="require('@/assets/icons/bold/people.svg')"
                            fluid
                            alt=""
                            class="icon-white icon-sm"
                          ></b-img>
                        </b-button>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div>
                        <b-media class="">
                          <template #aside>
                            <div class="container-avatar">
                              <div
                                class="avatar"
                                :style="{
                                  'background-image':
                                    'url(' +
                                    require('@/assets/img/avatar.png') +
                                    ')'
                                }"
                              ></div>
                            </div>
                          </template>
                          <h5 class="mb-0 mb-1">Paulo Viegas</h5>
                          <p class="mb-0 text-3">SrPandaNerd</p>
                        </b-media>
                      </div>
                    </td>
                    <td class="text-right">
                      <p>
                        <b-button class="btn-1 py-2 mt-4 mr-2">
                          <b-img
                            :src="require('@/assets/icons/bold/eye.svg')"
                            fluid
                            alt=""
                            class="icon-white icon-sm"
                          ></b-img>
                        </b-button>
                        <b-button class="btn-1 py-2 mt-4 mr-2">
                          <b-img
                            :src="
                              require('@/assets/icons/bold/profile-add.svg')
                            "
                            fluid
                            alt=""
                            class="icon-white icon-sm"
                          ></b-img>
                        </b-button>
                        <b-button class="btn-1 py-2 mt-4">
                          <b-img
                            :src="require('@/assets/icons/bold/people.svg')"
                            fluid
                            alt=""
                            class="icon-white icon-sm"
                          ></b-img>
                        </b-button>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div>
                        <b-media class="">
                          <template #aside>
                            <div class="container-avatar">
                              <div
                                class="avatar"
                                :style="{
                                  'background-image':
                                    'url(' +
                                    require('@/assets/img/avatar.png') +
                                    ')'
                                }"
                              ></div>
                            </div>
                          </template>
                          <h5 class="mb-0 mb-1">Paulo Viegas</h5>
                          <p class="mb-0 text-3">SrPandaNerd</p>
                        </b-media>
                      </div>
                    </td>
                    <td class="text-right">
                      <p>
                        <b-button class="btn-1 py-2 mt-4 mr-2">
                          <b-img
                            :src="require('@/assets/icons/bold/eye.svg')"
                            fluid
                            alt=""
                            class="icon-white icon-sm"
                          ></b-img>
                        </b-button>
                        <b-button class="btn-1 py-2 mt-4 mr-2">
                          <b-img
                            :src="
                              require('@/assets/icons/bold/profile-add.svg')
                            "
                            fluid
                            alt=""
                            class="icon-white icon-sm"
                          ></b-img>
                        </b-button>
                        <b-button class="btn-1 py-2 mt-4">
                          <b-img
                            :src="require('@/assets/icons/bold/people.svg')"
                            fluid
                            alt=""
                            class="icon-white icon-sm"
                          ></b-img>
                        </b-button>
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </b-col>
          </b-row> -->
        </div>
      </div>
    </b-modal>
    <b-modal id="modal-scoped2" size="lg">
      <template #modal-title>
        <h6 class="ff-chaney mb-0">
          <b-img
            :src="require('@/assets/icons/broken/profile-remove.svg')"
            fluid
            alt=""
            class="icon-green icon-sm"
          ></b-img>
          REMOVER AMIGO
        </h6>
      </template>
      <div class="d-block">
        <div class="">
          <p class="text-center text-3">
            Tem certeza que deseja remover o amigo abaixo?
          </p>
          <div class="container-avatar-lg mr-auto ml-auto">
            <b-avatar
                :src="selectedFriend.image"
                :text="firstLetter(selectedFriend.username)"
                size="200px"
              />
          </div>
          <p class="text-center"> {{ selectedFriend.username }}</p>
        </div>
      </div>
      <template #modal-footer="{ hide }">
        <b-container>
          <b-row>
            <b-col class="col-12 col-lg-6 py-3 py-lg-0 pl-lg-0">
              <b-button class="btn-1 w-100 py-3" @click="hide()">
                <b-img
                  :src="require('@/assets/icons/broken/close-square.svg')"
                  fluid
                  alt=""
                  class="icon-white icon-sm"
                ></b-img>
                <strong> CANCELAR </strong>
              </b-button>
            </b-col>
            <b-col class="col-12 col-lg-6 py-3 py-lg-0 pr-lg-0">
              <b-button class="btn-5 w-100 py-3" @click="undoFriend()">
                <b-img
                  :src="require('@/assets/icons/broken/profile-remove.svg')"
                  fluid
                  alt=""
                  class="icon-white icon-sm"
                ></b-img>
                <strong> REMOVER AMIGO </strong>
              </b-button>
            </b-col>
          </b-row>
        </b-container>
      </template>
    </b-modal>
  </div>
</template>

<script>
import UserInfo from '../services/users/user.js'
import formErrors from '../mixins/form-errors.mixin'
import setsNotify from '../mixins/sets-notify.mixin'
import { mapGetters } from 'vuex'
export default {
  name: 'Perfil',
  mixins: [
    setsNotify, formErrors
  ],
  metaInfo () {
    return {
      meta: [{
        lang: 'pt-BR',
        title: 'Cartolol - Perfil',
        descrition: 'Quer receber os panoramas mais assertivos dos principais torneios de esports do mundo, mostrar que é pé quente nos palpites e de quebra montar o seu próprio time e mostrar que é o melhor manager do Brasil? Acesse nossos serviços e saiba mais.',
        keywords: 'bet, aposta, call, cblol, lol, cs, league-of-legends, betclub, esports, palpites, myhint, hint, bolão, bolao, baiano, baianolol, baianolol1, fantasy, cartolol, cartola-do-lol',
        site_name: 'Cartolol',
        type: 'website',
        robots: 'index, follow'
      }]
    }
  },
  data: () => ({
    error_message: '',
    failed: false,
    profileOwner: true,
    userInfos: [],
    allBadges: [],
    userBadges: [],
    userBadgeId: -1,
    userSocial: {
      favoriteTeams: [],
      fantasyStats: {},
      prohintStats: {},
      friends: [],
      myFriends: [],
      requests: []
    },
    addFriendTemp: {},
    showstatistic: true,
    actived: 'border-cor',
    user: new UserInfo(),
    loading: true,
    showReport: false,
    busy: false,
    selectedFriend: {},
    search: '',
    showRequest: false,
    filter: '',
    selectedTab: 0
  }),
  watch: {
    '$route.query.user' () {
      window.scrollTo(0, 0)
      this.loading = true
      this.selectedTab = 0
      this.filter = ''
      this.search = ''
      this.showRequest = false
      this.getUserInfo()
    }
  },
  computed: {
    ...mapGetters('auth', ['getUser', 'isLoggedIn']),
    userID () {
      if (this.isLoggedIn) {
        return this.getUser.id
      }
      return null
    },
    userNickname () {
      if (this.userInfos.username === null) {
        return 'User_' + this.userInfos.id
      }
      return this.userInfos.username
    },
    haveFavoriteTeam () {
      return this.userSocial.favoriteTeams && this.userSocial.favoriteTeams.length > 0
    },
    FantasyParticipation () {
      return this.userSocial.fantasyStats.fantasy_rounds_played > 0
    },
    ProHintParticipation () {
      return this.userSocial.prohintStats.bets > 0
    },
    ownerId () {
      if (this.profileOwner) {
        return this.userID
      }
      return null
    },
    validReport () {
      return this.errors.description.length === 0 ? null : false
    },
    favoriteTeams () {
      return this.userSocial.favoriteTeams.filter(team => team.game === 'League of Legends')
    },
    users () {
      const list = this.showRequest ? this.userSocial.requests : this.userSocial.friends
      return list.filter(user => {
        return user.username.toLowerCase().includes(this.filter.toLowerCase())
      })
    }
  },
  methods: {
    firstLetter (username) {
      if (username === undefined || username === null) {
        return 'U'
      }
      return username.substring(0, 1).toUpperCase()
    },
    onInputBadge (badges) {
      this.userBadges = [...this.userBadges, ...badges]
    },
    async getUserInfo () {
      this.loading = true
      this.failed = false
      this.profileOwner = true
      if (this.$route.query.user != null) {
        this.profileOwner = false
      }
      if (this.profileOwner) {
        try {
          const { data } = await this.user.get(this.userID)
          this.userInfos = data.data
          this.userSocial = data.included
          this.allBadges = data.included.allBadges
          this.userBadges = data.included.userBadges
          this.userBadgeId = data.data.badge_id
          this.userSocial.friends.forEach((items, index) => {
            if (this.userSocial.friends[index].username === null) {
              this.userSocial.friends[index].username = 'User_' + this.userSocial.friends[index].user_id
            }
          })
          this.userSocial.requests.forEach((items, index) => {
            if (this.userSocial.requests[index].username === null) {
              this.userSocial.requests[index].username = 'User_' + this.userSocial.requests[index].user_id
            }
          })
          this.loading = false
        } catch (error) {
          this.failed = true
          this.error_message = error.message
        }
      } else {
        try {
          const { data } = await this.user.publicProfile(this.$route.query.user)
          this.userInfos = []
          this.userSocial = []
          this.userInfos = data.data
          this.userSocial = data.included
          this.allBadges = data.included.allBadges
          this.userBadges = data.included.userBadges
          this.userBadgeId = data.data.badge_id
          this.userSocial.friends.forEach((items, index) => {
            if (this.userSocial.friends[index].username === null) {
              this.userSocial.friends[index].username = 'User_' + this.userSocial.friends[index].user_id
            }
          })
          this.loading = false
        } catch (error) {
          this.failed = true
          this.error_message = error.message
        }
      }
    },
    async addFriend () {
      try {
        const { data } = await this.user.addFriend(this.search)
        const ret = data.data
        this.userSocial.requests.push({ id: ret.id, image: ret.image, date: ret.date, requester: ret.requester, user_id: ret.user_id, username: ret.username })
        this.setNotifySuccess('Solicitação enviada com sucesso!')
        this.$bvModal.hide('modal-scoped')
      } catch (error) {
        this.setNotifyDanger(error.message)
      }
    },
    async undoFriend () {
      await this.user.undoFriend(this.selectedFriend.id)
        .then(data => {
          this.busy = true
          const t2 = this.userSocial.friends.findIndex(friend => friend.id === this.selectedFriend.id)
          this.userSocial.friends.splice(t2, 1)
          this.setNotifySuccess('Amizade desfeita com sucesso!')
          this.$bvModal.hide('modal-scoped2')
        })
        .catch(error => {
          this.busy = false
          this.setNotifyDanger(`${error.message}`)
        })
    },
    async addPublicFriend (friend = this.userInfos.username) {
      try {
        const { data } = await this.user.addFriend(friend)
        const ret = data.data
        this.userSocial.myFriends.push({ id: ret.id, image: ret.image, date: ret.date, requester: ret.requester, user_id: ret.user_id, username: ret.username })
        this.setNotifySuccess('Solicitação enviada com sucesso!')
        this.$bvModal.hide('modal-scoped')
      } catch (error) {
        this.setNotifyDanger(error.message)
      }
    },
    async undoPublicFriend () {
      await this.user.undoFriend(this.userSocial.myFriends.find(friend => friend.user_id === this.userInfos.id).id)
        .then(data => {
          this.busy = true
          const t2 = this.userSocial.myFriends.findIndex(friend => friend.user_id === this.userInfos.id)
          this.userSocial.myFriends.splice(t2, 1)
          this.setNotifySuccess('Amizade desfeita com sucesso!')
          this.$bvModal.hide('modal-scoped2')
        })
        .catch(error => {
          this.busy = false
          this.setNotifyDanger(`${error.message}`)
        })
    },
    async accpetFriend (friend) {
      await this.user.acceptFriend(friend.id)
        .then(data => {
          this.busy = true
          const t2 = this.userSocial.requests.findIndex(friend => friend.id === this.selectedFriend.id)
          this.userSocial.requests.splice(t2, 1)
          this.userSocial.friends.push(friend)
          this.setNotifySuccess('Amizade aceita com sucesso!')
        })
        .catch(error => {
          this.busy = false
          this.setNotifyDanger(`${error.message}`)
        })
    },
    async rejectFriend (friend) {
      await this.user.rejectFriend(friend.id)
        .then(data => {
          this.busy = true
          const t2 = this.userSocial.requests.findIndex(friend => friend.id === this.selectedFriend.id)
          this.userSocial.requests.splice(t2, 1)
          this.setNotifySuccess('Amizade rejeitada com sucesso!')
        })
        .catch(error => {
          this.busy = false
          this.setNotifyDanger(`${error.message}`)
        })
    },
    async cancelFriend (friend) {
      await this.user.cancelFriend(friend.id)
        .then(data => {
          this.busy = true
          const t2 = this.userSocial.requests.findIndex(friend => friend.id === this.selectedFriend.id)
          this.userSocial.requests.splice(t2, 1)
          this.setNotifySuccess('Solicitação de amizade cancelada com sucesso!')
        })
        .catch(error => {
          this.busy = false
          this.setNotifyDanger(`${error.message}`)
        })
    },
    onAddFriend () {
      this.$bvModal.show('add-friend')
    },
    onRemoveFriend (friend) {
      this.selectedFriend = friend
      this.$bvModal.show('modal-scoped2')
    },
    isMyFriend (id) {
      return this.userSocial.myFriends.filter(val => val.user_id === id).length > 0
    },
    friendshipId (id) {
      return this.userSocial.myFriends.filter(val => val.user_id === id)[0].id
    },
    haveContent (value) {
      return value !== undefined || value !== null ? value : ''
    }
  },
  mounted () {
    if (this.$route.query.user != null) {
      this.profileOwner = false
    }
    if (!window.__PRERENDER_INJECTED) {
      if (!this.isLoggedIn) {
        this.$router.push({ path: '/login/', query: { redirect: this.$router.currentRoute.fullPath } }, () => {
          this.setNotifyDanger('Você precisa estar logado para acessar esta página.')
        })
        return
      }
      this.getUserInfo()
    }
  }
}
</script>
<style>
p {
  word-wrap: break-word
 }
.left-border{
  border-left: 3px solid #38f8f9!important;
}
.border-bottom{
  border-bottom: 3px solid white!important;
}
.border-cor{
  border-bottom-color:#38f8f9!important
}
</style>
