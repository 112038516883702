<template>
  <b-tab @click="callFunction()">
    <template #title>
      <b-img
        :src="require('@/assets/icons/outline/map.svg')"
        fluid
        alt=""
        class="icon-sm"
      ></b-img>
      Mapa
    </template>
    <div class="pt-1">
      <hr class="mt-3 mb-5" />
      <b-row>
        <b-col class="col-12">
          <b-img
            :src="require('@/assets/icons/broken/game.svg')"
            fluid
            alt=""
            class="icon-sm icon-green mr-3"
          ></b-img>
          <span style="margin-bottom: -5px"> JOGADORES </span>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="my-5 text-center">
          <div id="mapa-container" class="my-5 d-inline-block mapa-container">
            <span :id="`player-${role}`" v-for="(role, index) in roles" :key="`player-${index}`"/>
            <div id="mapa" class="">
              <b-img
                :src="require('@/assets/img/map.png')"
                fluid
                alt=""
              ></b-img>
            </div>
            <FantasyMapPlayer
              v-for="(role, index) in roles"
              :key="index"
              :id="team[`${role}_id`]"
              :name="team[`${role}_nickname`]"
              :role="role"
              :team="team[`${role}_team_shortcut`]"
              :player_image="team[`${role}_image`]"
              :team_image="team[`${role}_team_image`]"
              :price="team[`${role}_price`]"
              :points="team[`${role}_point`]"
              :shotcaller="team.shotcaller_id && team[`${role}_id`] === team.shotcaller_id"
              :isMarketOpen="isMarketOpen"
              :selectedRole="selectedRole"
              @select-role="$emit('select-role', role)"
              @select-shotcaller="$emit('select-shotcaller', team[`${role}_id`])"
            />
          </div>
        </b-col>
      </b-row>
      <b-row>
        <div class="w-100 d-none d-xl-block"></div>
        <!--CARD COACH-->
        <FantasyCardCoach
          :id="team[`coach_id`]"
          :name="team[`coach_nickname`]"
          :team="team[`coach_team_shortcut`]"
          :player_image="team[`coach_image`]"
          :team_image="team[`coach_team_image`]"
          :price="team[`coach_price`]"
          :points="team[`coach_point`]"
          :isMarketOpen="isMarketOpen"
          :selectedRole="selectedRole"
          @select-role="$emit('select-role', 'coach')"
        />
        <FantasySummary :isMarketOpen="isMarketOpen" :team="team" :wallet="wallet" :round="round.id" :share="share" @clear-team="$emit('clear-team')"/>
      </b-row>
    </div>
  </b-tab>
</template>

<style scoped>
#mapa {
  position: relative;
}

#mapa-container {
  position: relative;
}
</style>

<script>
import FantasyCardCoach from '@/components/fantasy/FantasyCardCoach.vue'
import FantasySummary from '@/components/fantasy/FantasySummary.vue'
import FantasyMapPlayer from './FantasyMapPlayer.vue'
export default {
  components: { FantasyMapPlayer, FantasyCardCoach, FantasySummary },
  name: 'FantasyTeamSelectionMap',
  props: {
    team: {
      type: Object,
      default: () => ({ top_id: null, jun_id: null, mid_id: null, adc_id: null, sup_id: null, coach_id: null, shotcaller_id: null })
    },
    round: {
      type: Object,
      default: () => ({ id: 0, number: 0 })
    },
    isMarketOpen: {
      type: Boolean,
      default: false
    },
    wallet: {
      type: Object,
      default: () => ({ credits: null })
    },
    selectedRole: {
      type: String,
      default: null
    },
    share: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      roles: ['top', 'jun', 'mid', 'adc', 'sup']
    }
  },
  methods: {
    callFunction: function () {
      setTimeout(
        function () {
          this.resizeMap()
        }.bind(this),
        200
      )
    },
    resizeMap () {
      this.roles.forEach(role => {
        var positions = {
          top: [600, 90],
          jun: [250, 90],
          mid: [250, 300],
          sup: [-50, 400],
          adc: [-50, 600]
        }
        var mapW = document.querySelector('#mapa').clientWidth
        var mapH = document.querySelector('#mapa').clientHeight

        if (mapW >= 800) {
          var newL = positions[role][1]
          var newB = positions[role][0]
          document.querySelector('#map-' + role).style.left = newL + 'px'
          document.querySelector('#map-' + role).style.bottom = newB + 'px'
        } else if (mapW < 800 && mapW >= 600) {
          var newL2 = (mapW * positions[role][1]) / 800
          var newB2 = (mapH * positions[role][0]) / 800 - 25
          document.querySelector('#map-' + role).style.left = newL2 + 'px'
          document.querySelector('#map-' + role).style.bottom = newB2 + 'px'
        } else {
          var newL3 = (mapW * positions[role][1]) / 800
          var newB3 = (mapH * positions[role][0]) / 800 - 50
          if (role === 'sup' || role === 'adc') {
            newB3 = newB3 - 50
          }
          if (role === 'top') {
            newB3 = newB3 + 25
          }
          document.querySelector('#map-' + role).style.left = newL3 + 'px'
          document.querySelector('#map-' + role).style.bottom = newB3 + 'px'
        }
      })
    }
  }
}
</script>
