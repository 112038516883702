<template>
  <div>
    <b-container fluid="xl" class="px-md-5 px-xl-0 s1">
      <b-row>
        <b-col
          class="bg-ondas py-5"
          :style="{
            'background-image':
              'url(' + require('../assets/img/ondas.png') + ')'
          }"
        >
          <b-row>
            <b-col class="d-none d-xl-block col-6 text-center py-3 align-self-center px-5">
              <b-img
                :src="require('../assets/img/login1.png')"
                fluid
                alt=""
              ></b-img>
            </b-col>
            <b-col class="col-12 col-md-8 col-lg-6 offset-md-2 offset-lg-3 offset-xl-0 col-5 pl-xl-5">
              <div class="card py-5 px-4">
                <p class="pb-5 text-center">
                  <b-img
                    :src="require('../assets/img/logo.png')"
                    fluid
                    alt=""
                    class=""
                  ></b-img>
                </p>
                <h5 class="text-white ff-chaney text-center">Criar nova senha</h5>
                <div class="py-5 px-3">
                  <b-form
                    class="ff-inter"
                    @submit="onSubmit"
                  >
                    <b-form-group label="Senha" class="mt-4">
                      <b-input-group
                        prepend=" "
                        class="mb-2 mr-sm-2 mb-sm-0"
                      >
                        <span
                          ><b-img
                            :src="require('../assets/icons/outline/lock.svg')"
                            fluid
                            alt=""
                            class="icon-white icon-sm"
                          ></b-img
                        ></span>
                        <b-form-input
                          placeholder="Digite sua senha"
                          type="password"
                          v-model="user.password"
                          @input="$v.user.password.$touch()"
                        ></b-form-input>
                      </b-input-group>
                      <b-form-invalid-feedback
                        :state="isPasswordValid"
                        v-for="(error, i) in errors.password" :key=i
                      >
                        {{ error }}
                      </b-form-invalid-feedback>
                    </b-form-group>

                    <b-form-group label="Confirme a Senha" class="mt-4">
                      <b-input-group
                        prepend=" "
                        class="mb-2 mr-sm-2 mb-sm-0"
                      >
                        <span
                          ><b-img
                            :src="require('../assets/icons/outline/lock.svg')"
                            fluid
                            alt=""
                            class="icon-white icon-sm"
                          ></b-img
                        ></span>
                        <b-form-input
                          placeholder="Confirme sua senha"
                          type="password"
                          v-model="user.passwordConfirmation"
                          @input="$v.user.passwordConfirmation.$touch()"
                        ></b-form-input>
                      </b-input-group>
                      <b-form-invalid-feedback
                        :state="isPasswordConfirmationValid"
                        v-for="(error, i) in errors.passwordConfirmation" :key=i
                      >
                        {{ error }}
                      </b-form-invalid-feedback>
                    </b-form-group>

                    <b-button type="submit" class="btn-3 w-100 py-3 mt-4">
                      <b-img
                        :src="require('../assets/icons/broken/directbox-receive.svg')"
                        fluid
                        alt=""
                        class="icon-dark icon-sm"
                      ></b-img>
                      <strong> SALVAR </strong>
                    </b-button>
                  </b-form>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import UserService from '../services/users/user'
import { required, sameAs } from 'vuelidate/lib/validators'
import formErrors from '../mixins/form-errors.mixin'
import setsNotify from '../mixins/sets-notify.mixin'

export default {
  name: 'Redefinir',
  mixins: [setsNotify, formErrors],
  metaInfo () {
    return {
      meta: [{
        lang: 'pt-BR',
        title: 'CartoLOL - Confirmar senha',
        descrition: 'Quer receber os panoramas mais assertivos dos principais torneios de esports do mundo, mostrar que é pé quente nos palpites e de quebra montar o seu próprio time e mostrar que é o melhor manager do Brasil? Acesse nossos serviços e saiba mais.',
        keywords: 'bet, aposta, call, cblol, lol, cs, league-of-legends, betclub, esports, palpites, myhint, hint, bolão, bolao, baiano, baianolol, baianolol1, fantasy, cartolol, cartola-do-lol',
        site_name: 'CartoLOL',
        type: 'website',
        robots: 'index, follow'
      }]
    }
  },
  data: () => ({
    userService: new UserService(),
    user: {
      redefinePasswordVerificationCode: '',
      password: '',
      passwordConfirmation: ''
    },
    errors: { password: [], passwordConfirmation: [], redefinePasswordVerificationCode: [] },
    loading: false
  }),
  validations: {
    user: {
      password: {
        required
      },
      passwordConfirmation: {
        required,
        sameAsPassword: sameAs('password')
      }
    }
  },
  watch: {
    '$v.user.password.$error' (newVal, oldVal) {
      const requiredField = 'Este campo é obrigatório.'
      this.errors.password = this.errors.password.filter(item => item !== requiredField)
      if (!this.$v.user.password.required) {
        this.errors.password.push(requiredField)
      }
    },
    '$v.user.passwordConfirmation.$error' (newVal, oldVal) {
      const requiredField = 'Este campo é obrigatório.'
      const sameAsPassword = 'As senhas devem ser iguais.'
      this.errors.passwordConfirmation = this.errors.passwordConfirmation.filter(item => item !== requiredField)
      if (!this.$v.user.passwordConfirmation.required) {
        this.errors.passwordConfirmation.push(requiredField)
      }
      this.errors.passwordConfirmation = this.errors.passwordConfirmation.filter(item => item !== sameAsPassword)
      if (!this.$v.user.passwordConfirmation.sameAsPassword) {
        this.errors.passwordConfirmation.push(sameAsPassword)
      }
    }
  },
  computed: {
    isPasswordValid () {
      return this.errors.password.length === 0 ? null : false
    },
    isPasswordConfirmationValid () {
      return this.errors.passwordConfirmation.length === 0 ? null : false
    }
  },
  mounted () {
    this.user.redefinePasswordVerificationCode = this.$route.query.token
  },
  methods: {
    onSubmit (event) {
      event.preventDefault()
      const fields = [this.$v.user]
      this.verifyErrorsForm(fields).then((resp) => {
        this.passwordConfirmation()
      }).catch((err) => {
        this.setNotifyDanger(err.message)
      })
    },
    async passwordConfirmation () {
      this.loading = true
      try {
        await this.userService.changePassword(this.user)
        this.$router.push('/login', () => {
          this.setNotifySuccess('Senha redefinida com sucesso.')
        })
        if (this.$gtag) {
          this.$gtag.event('Senha Redefinida', { event_category: 'User' })
        }
      } catch (error) {
        if (error.name === 400 && this.isJson(error.message)) {
          this.errors = this.parseServerSideErros(this.errors, JSON.parse(error.message))
          this.errors.redefinePasswordVerificationCode.forEach(msg => {
            this.setNotifyDanger(`${msg}. Error: ${error.name}.`)
          })
          return
        }
        this.setNotifyDanger(`${error.message}. Error: ${error.name}.`)
      } finally {
        this.loading = false
      }
    }
  },
  head: {
    title: 'CartoLOL - Redefinir'
  }
}
</script>

<style lang="scss">
  .bg-ondas {
    background-size: cover;
    background-position: center center;
  }
  .s1 .card {
    background-color: #13151b;
    border: 2px solid #20232c;
    border-radius: 16px;
  }
</style>
