,<template>
  <div>
    <b-row class="mt-5">
        <b-col class="col-12 pb-4">
          <b-img
            :src="require('@/assets/icons/broken/ranking-1.svg')"
            fluid
            alt=""
            class="icon-sm icon-green mr-3"
          ></b-img>
          VENCEDORES
        </b-col>
      </b-row>
    <b-row>
      <b-col>
        <table class="table custom-table">
          <tbody>
            <tr v-for="(user, index) in winnersList" :key="index">
              <td>
                <div>
                  <b-media class="">
                    <template #aside>
                      <div class="container-avatar">
                        <b-avatar
                          :src="user.image"
                          :text="firstLetter(user.username)"
                          size="40px"
                        />
                      </div>
                    </template>
                    <h5 class="mt-0 mb-1">
                      {{ user.username || `User${user.user_id}` }}
                    </h5>
                    <!-- <p class="mb-0 text-3">
                      <small> SrPandaNerd </small>
                    </p> -->
                  </b-media>
                </div>
              </td>
              <td class="text-right">
                <p>
                  <b-button
                    class="btn-1 py-2 mt-4 mr-2"
                    v-b-tooltip.hover title="Ver Perfil" :to="{ path: '/perfil/', query: { user: user.user_id } }" v-if="user.user_id !== userID"
                  >
                    <b-img
                      :src="require('@/assets/icons/bold/eye.svg')"
                      fluid
                      alt=""
                      class="icon-white icon-sm"
                    ></b-img>
                  </b-button>
                  <b-button
                    class="btn-1 py-2 mt-4 mr-2"
                    v-b-tooltip.hover title="Solicitar Amizade" @click="addPublicFriend(user.username)" v-if="!isMyFriend(user.user_id) && user.user_id !== userID"
                  >
                    <b-img
                      :src="require('@/assets/icons/bold/profile-add.svg')"
                      fluid
                      alt=""
                      class="icon-white icon-sm"
                    ></b-img>
                  </b-button>
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </b-col>
    </b-row>
    <b-row v-if="hasMore">
      <b-col class="col-12 align-self-center text-center pb-5 pt-3">
        <b-button class="btn-1 py-3 px-4 m-2" @click="showMore">
          <b-img
            :src="require('@/assets/icons/broken/add-square.svg')"
            fluid
            alt=""
            class="icon-white btn-img mr-1"
          ></b-img>
          CARREGAR MAIS
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import setsNotify from '@/mixins/sets-notify.mixin'
import UserService from '@/services/users/user.js'
import { mapGetters } from 'vuex'
export default {
  name: 'PoolLeaderboard',
  mixins: [setsNotify],
  props: {
    winners: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      userService: new UserService(),
      offset: 10,
      limit: 10,
      friends: []
    }
  },
  computed: {
    ...mapGetters('auth', ['getUser', 'isLoggedIn']),
    userID () {
      if (this.isLoggedIn) {
        return this.getUser.id
      }
      return null
    },
    winnersList () {
      return this.winners.slice(0, this.limit)
    },
    hasMore () {
      return this.winners.length > this.limit
    }
  },
  methods: {
    firstLetter (username) {
      if (username === null || username === undefined || username === '') {
        return 'U'
      }
      return username.charAt(0).toUpperCase()
    },
    showMore () {
      this.limit += this.offset
    },
    isMyFriend (id) {
      return this.friends.includes(id)
    },
    async addPublicFriend (friend) {
      try {
        const { data } = await this.userService.addFriend(friend)
        const ret = data.data
        this.friends.push(ret.user_id)
        this.setNotifySuccess('Solicitação enviada com sucesso!')
      } catch (error) {
        this.setNotifyDanger(error.message)
      }
    }
  }
}
</script>
