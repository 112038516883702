<template>
  <div>
    <div>
      <b-container fluid="xl" class="px-md-5 px-xl-0">
        <b-row>
          <b-col class="col-12">
            <h4 class="text-white ff-chaney mt-5">TERMOS DE USO</h4>
          </b-col>
          <b-col class="col-12 pt-3">
            <div class="text-3 content pb-5">
              <p class="text-white">Última Atualização: 20 de setembro de 2020</p>
              <p>Bem vindo(a)! Obrigado por utilizar o CartoLOL!</p>
              <p>
                Esta aplicação e seu conteúdo ("<strong>CartoLOL</strong>") são
                controlados e operados pelo próprio CartoLOL.
                <strong>Todos os direitos reservados</strong>.
              </p>
              <p>
                Estes termos de uso têm por objeto definir as regras a serem
                seguidas para a utilização do CartoLOL ("<strong
                  >Termos de Uso</strong
                >"), sem prejuízo da aplicação da legislação vigente.
              </p>
              <p>
                <strong>
                  AO UTILIZAR O CartoLOL, VOCÊ AUTOMATICAMENTE CONCORDA COM
                  ESTES TERMOS DE USO, RESPONSABILIZANDO-SE INTEGRALMENTE POR
                  TODOS E QUAISQUER ATOS PRATICADOS POR VOCÊ NO CartoLOL OU EM
                  SERVIÇOS A ELE RELACIONADOS. CASO VOCÊ NÃO CONCORDE COM
                  QUALQUER DOS TERMOS E CONDIÇÕES ABAIXO ESTABELECIDOS, VOCÊ NÃO
                  DEVE UTILIZAR O CartoLOL. VOCÊ TAMBÉM CONCORDA COM OS TERMOS
                  DESCRITOS EM NOSSA POLÍTICA DE PRIVACIDADE. PARA ACESSÁ-LA,
                  <b-link to="/politica" class="text-white">CLIQUE AQUI</b-link>.
                </strong>
              </p>
              <p>
                Caso queira nos dar algum feedback sobre o CartoLOL, tenha
                dúvidas ou precise tratar de qualquer assunto relacionado a
                estes Termos de Uso, entre em contato conosco através do e-mail
                contato@CartoLOL.com.br.
              </p>
              <h4>1. O QUE É O CartoLOL?</h4>
              <h6>1.1. Serviços.</h6>
              <p>
                o CartoLOL é uma plataforma que oferece os seguintes serviços:
              </p>
              <ul style="list-style-type: disc !important; margin-left: 50px">
                <li>
                  Descrição de panoramas relacionados as principais ligas de
                  esportes eletrônicos do mundo;
                </li>
                <li>
                  Palpites fornecidos por um analista, com base em critérios
                  qualitativos e quantitativos, para as principais ligas de
                  esportes eletrônicos do mundo;
                </li>
                <li>
                  ProHint, plataforma interativa em que o usuário pode palpitar
                  em determinados resultados de ligas/torneios de esportes
                  eletrônicos, utilizando pontos concedidos pela plataforma no
                  momento do cadastro.
                </li>
              </ul>
              <h6 style="margin-top: 30px">
                <strong>
                  1.2. Ressalvas. Ao utilizar O CartoLOL, você reconhece que não
                  nos responsabilizamos por eventuais perdas financeiras dos
                  assinantes oriundas de sugestões de apostas oferecidas no
                  nosso site.
                </strong>
              </h6>
              <h6>1.3. Suspensão.</h6>
              <p>
                Nós nos reservamos o direito de suspender ou cancelar, a
                qualquer momento, o seu acesso à aplicação em caso de suspeita
                de fraude, obtenção de benefício ou vantagem de forma ilícita,
                ou pelo não cumprimento de quaisquer condições previstas nestes
                Termos de Uso, na Política de Privacidade ou na legislação
                aplicável. Nesses casos, não será devida qualquer indenização a
                você, e o CartoLOL poderá promover a competente ação de
                regresso, se necessário, bem como tomar quaisquer outras medidas
                necessárias para perseguir e resguardar seus interesses.
              </p>
              <h6>1.4. Premiação ProHint.</h6>
              <p>
                Para que usuário esteja apto a concorrer as premiações
                oferecidas pelo ProHint será necessário atender aos seguintes
                critérios:
              </p>
              <h6>1.4.1. Número mínimo de apostas:</h6>
              <p>
                Ter realizado o número mínimo de apostas diferentes,
                estabelecido de acordo com o ProHint, ao longo do respectivo
                torneio.
              </p>
              <h6>1.4.2. Comprovação de identidade:</h6>
              <p>
                Comprovar, ao término do respectivo ProHint, ser o titular da
                conta por meio do envio de documento comprobatório, mediante
                solicitação.
              </p>
              <h4>2. COMO ACESSO O CartoLOL?</h4>
              <h6>2.1. Acesso.</h6>
              <p>
                Para acessar o CartoLOL e utilizar suas funcionalidades é
                necessário efetuar um cadastro. Para cadastrar-se, você nos
                fornecerá informações pessoais, conforme descrito em nossa
                Política de Privacidade. Para saber mais sobre a privacidade de
                suas informações pessoais no CartoLOL, acesse nossa Política de
                Privacidade.
              </p>
              <h6>2.2. Idade de Utilização.</h6>
              <p>
                Para utilizar o CartoLOL, você deve ter pelo menos 18 (dezoito)
                anos.
              </p>
              <h6>2.3. Titularidade.</h6>
              <p>
                A partir do cadastro, você será titular de uma conta que somente
                poderá ser acessada por você. Caso o CartoLOL detecte alguma
                conta feita a partir de informações falsas, por usuários que,
                por exemplo, não possuem a idade mínima permitida, essa conta
                será automaticamente deletada.
              </p>
              <h6>2.4. Atualização das Informações.</h6>
              <p>
                Desde já, você se compromete a manter as suas informações
                pessoais atualizadas. Você também concorda que irá manter o seu
                login e senha seguros e fora do alcance de terceiros, e não
                permitirá que a sua conta no CartoLOL seja usada por outras
                pessoas. Dessa forma, o usuário responsabiliza-se por todas as
                ações realizadas em sua conta.
              </p>
              <h4>3. ASSINATURA E CANCELAMENTO DE PLANOS</h4>
              <h6>3.1. Assinatura.</h6>
              <p>
                Nós do CartoLOL fornecemos a assinatura de planos de serviço da
                seguinte maneira: gratuita.
              </p>
              <h6>3.2. Cancelamento da Subscrição.</h6>
              <p>
                O cancelamento da subscrição ao plano pode ser realizado a
                qualquer tempo pelo usuário e será entendido como manifestação
                expressa de que não pretende renovar a licença no próximo
                período ainda não contabilizado para fins de cobrança. O
                cancelamento não enseja qualquer dever ao CartoLOL.
              </p>
              <h4>4. QUAIS SÃO OS DIREITOS DO CartoLOL SOBRE A APLICAÇÃO?</h4>
              <h6>4.1. Nossos Direitos.</h6>
              <p>
                Todos os direitos relativos ao CartoLOL e suas funcionalidades
                são de propriedade exclusiva do CartoLOL, inclusive no que diz
                respeito aos seus textos, imagens, layouts, software, códigos,
                bases de dados, gráficos, artigos, fotografias e demais
                conteúdos produzidos direta ou indiretamente pelo CartoLOL
                (“Conteúdo do CartoLOL”). O Conteúdo do CartoLOL é protegido
                pela lei de direitos autorais e de propriedade intelectual. É
                proibido usar, copiar, reproduzir, modificar, traduzir,
                publicar, transmitir, distribuir, executar, fazer o upload,
                exibir, licenciar, vender ou explorar e fazer engenharia reversa
                do Conteúdo do CartoLOL, para qualquer finalidade, sem o
                consentimento prévio e expresso do CartoLOL. Qualquer uso não
                autorizado do Conteúdo do CartoLOL será considerado como
                violação dos direitos autorais e de propriedade intelectual do
                CartoLOL.
              </p>
              <h4>
                5. PROPRIEDADE INTELECTUAL SOBRE O SOFTWARE E OS MATERIAIS
                DISPONIBILIZADOS
              </h4>
              <h6>5.1. Propriedade Intelectual.</h6>
              <p>
                Para nós do CartoLOL, a qualidade dos materiais
                disponibilizados ao usuário é de suma importância. A criação
                deles é fruto de muito trabalho e dedicação de nossos
                desenvolvedores. Por isso, reafirmamos que o CartoLOL garante
                que todos os direitos, título e interesse (incluindo, mas não
                apenas, os direitos autorais, marcários e outros de propriedade
                intelectual) sobre o serviço disponibilizado por nós
                permanecerão sob a titularidade do CartoLOL.
              </p>
              <h6>5.2. Não-aquisição de Direitos.</h6>
              <p>
                O usuário não adquirirá nenhum direito de propriedade sobre os
                serviços e conteúdo do CartoLOL, exceto quando haja outorga
                expressa neste Termos de Uso.
              </p>
              <h6>5.3. Download de Conteúdo.</h6>
              <p>
                <strong>
                  É proibido que o usuário faça o download de nosso conteúdo com
                  o intuito de armazená-lo em banco de dados para oferecer para
                  terceiro que não seja o próprio usuário. Veda-se, também, que
                  o conteúdo disponibilizado por nós seja usado para criar uma
                  base de dados ou um serviço que possa concorrer de qualquer
                  maneira com o nosso negócio.
                </strong>
              </p>
              <h4>6. RECLAMAÇÕES SOBRE VIOLAÇÃO DE DIREITO AUTORAL.</h4>
              <h6>6.1. Infringência de Direitos.</h6>
              <p>
                Alegações de infringência de direito autoral de qualquer
                conteúdo disponível no CartoLOL devem ser encaminhadas por meio
                do e-mail
                <strong>contato@CartoLOL.com.br</strong>.
              </p>
              <h4>7. RESPONSABILIDADES DO USUÁRIO E DO CartoLOL.</h4>
              <h6>7.1. Responsabilidade pelo Uso.</h6>
              <p>
                Você é exclusivamente responsável pelo uso do CartoLOL e deverá
                respeitar as regras destes Termos de Uso, bem como a legislação
                aplicável ao CartoLOL.
              </p>
              <h6>7.2. Responsabilização por Eventuais Danos.</h6>
              <p>
                o CartoLOL, seu controlador, suas afiliadas, parceiras ou
                funcionários não serão, em hipótese alguma, responsabilizados
                por danos diretos ou indiretos que resultem de, ou que tenham
                relação com o acesso, uso ou a incapacidade de acessar ou
                utilizar o CartoLOL.
              </p>
              <h6>7.3. Não-Responsabilização.</h6>
              <p>
                <strong>
                  TENDO EM VISTA AS CARACTERÍSTICAS INERENTES AO AMBIENTE DA
                  INTERNET, O CartoLOL NÃO SE RESPONSABILIZA POR INTERRUPÇÕES OU
                  SUSPENSÕES DE CONEXÃO, TRANSMISSÕES DE COMPUTADOR INCOMPLETAS
                  OU QUE FALHEM, BEM COMO POR FALHA TÉCNICA DE QUALQUER TIPO,
                  INCLUINDO, MAS NÃO SE LIMITANDO, AO MAU FUNCIONAMENTO
                  ELETRÔNICO DE QUALQUER REDE, HARDWARE OU SOFTWARE. A
                  INDISPONIBILIDADE DE ACESSO À INTERNET OU AO CartoLOL, ASSIM
                  COMO QUALQUER INFORMAÇÃO INCORRETA OU INCOMPLETA SOBRE O HINT
                  CLUB E QUALQUER FALHA HUMANA, TÉCNICA OU DE QUALQUER OUTRO
                  TIPO NO PROCESSAMENTO DAS INFORMAÇÕES DO CartoLOL NÃO SERÃO
                  CONSIDERADAS RESPONSABILIDADE DO CartoLOL. O CartoLOL SE EXIME
                  DE QUALQUER RESPONSABILIDADE PROVENIENTE DE TAIS FATOS E/OU
                  ATOS.
                </strong>
              </p>
              <h6>7.4. Perda de Lucros.</h6>
              <p>
                Quando permitido por lei, o CartoLOL e os fornecedores ou
                distribuidores não serão responsáveis por perda de lucros, perda
                de receita, perda de dados, perdas financeiras ou por danos
                indiretos, especiais, consequenciais, exemplares ou punitivos.
              </p>
              <h6>7.5. Manutenção.</h6>
              <p>
                É de sua inteira responsabilidade manter o ambiente de seu
                dispositivo (computador, celular, tablet, entre outros) seguro,
                com o uso de ferramentas disponíveis, como antivírus, firewall,
                entre outras, de modo a contribuir na prevenção de riscos
                eletrônicos.
              </p>
              <h6>7.6. Links Externos.</h6>
              <p>
                É possível que O CartoLOL possa conter links para sites e
                aplicativos de terceiros, assim como ter tecnologias integradas.
                Isso não implica, de maneira alguma, que o CartoLOL endossa,
                verifica, garante ou possui qualquer ligação com os
                proprietários desses sites ou aplicativos, não sendo responsável
                pelo seu conteúdo, precisão, políticas, práticas ou opiniões. O
                CartoLOL recomenda que você leia os termos de uso e políticas de
                privacidade de cada site de terceiros ou serviço que o usuário
                vier a visitar ou utilizar.
              </p>
              <h4>
                8. COMO O CartoLOL LIDA COM O CONTEÚDO QUE VOCÊ E OUTROS
                USUÁRIOS PRODUZEM?
              </h4>
              <h6>8.1. Criação de Conteúdo.</h6>
              <p>
                o CartoLOL poderá, a seu exclusivo critério, permitir que você
                ou qualquer outro usuário apresente, carregue, publique ou
                disponibilize, na aplicação, conteúdo ou informações de texto,
                imagem, áudio ou vídeo (“<strong>Conteúdo de Usuário</strong>”).
              </p>
              <h6>8.2. Conteúdos Proibidos.</h6>
              <p>
                É proibido qualquer Conteúdo de Usuário de caráter difamatório,
                calunioso, injurioso, violento, pornográfico, obsceno, ofensivo
                ou ilícito, conforme apuração do CartoLOL a seu critério
                exclusivo, inclusive informações de propriedade exclusiva
                pertencentes a outras pessoas ou empresas (ex. direito de autor,
                marcas), sem a expressa autorização do titular desses direitos,
                cuja violação não será de responsabilidade do CartoLOL.
              </p>
              <h6>8.3. Titularidade do Conteúdo.</h6>
              <p>
                Qualquer Conteúdo de Usuário fornecido por você permanece de sua
                propriedade. Contudo, ao fornecê-lo para o CartoLOL, você nos
                outorga uma licença em nível mundial, por prazo indeterminado,
                gratuita e transferível, e com direito a sublicenciar, usar,
                copiar, modificar, criar obras derivadas, distribuir, publicar,
                exibir esse Conteúdo de Usuário em todos os formatos e canais de
                distribuição possíveis, sem necessidade de novo aviso a você, e
                sem necessidade de qualquer pagamento, desde que isso esteja
                relacionado ao funcionamento da plataforma.
              </p>
              <h6>8.4. Exclusão do Conteúdo.</h6>
              <p>
                o CartoLOL poderá, mas não se obriga a analisar, monitorar e
                remover Conteúdo de Usuário, a critério exclusivo do CartoLOL, a
                qualquer momento e por qualquer motivo, sem nenhum aviso a você.
              </p>
              <h4>9. O QUE MAIS EU PRECISO SABER SOBRE ESTES TERMOS DE USO?</h4>
              <h6>9.1. Alterações.</h6>
              <p>
                Para melhorar sua experiência, O CartoLOL está sempre sendo
                atualizado. Por esse motivo, estes Termos de Uso podem ser
                alterados, a qualquer tempo, a fim de refletir os ajustes
                realizados. No entanto, sempre que ocorrer qualquer modificação,
                você será previamente informando pelo endereço de e-mail
                fornecido por você no momento do cadastro ou por um aviso em
                destaque na aplicação. Caso você não concorde com os novos
                Termos de Uso, você poderá rejeitá-los, mas, infelizmente, isso
                significa que você não poderá mais ter acesso e fazer uso do
                CartoLOL. Se de qualquer maneira você utilizar o CartoLOL mesmo
                após a alteração destes Termos de Uso, isso significa que
                <strong>você concorda com todas as modificações.</strong>
              </p>
              <h6>9.2. Conflito entre Disposições.</h6>
              <p>
                <strong>
                  Em caso de conflito entre estes termos e os termos
                  modificador, os termos posteriores prevalecerão com relação a
                  esse conflito.
                </strong>
              </p>
              <h6>9.3. Lei e Foro.</h6>
              <p>
                Estes Termos de Uso são regidos pelas leis da República
                Federativa do Brasil. Quaisquer dúvidas e situações não
                previstas nestes Termos de Uso serão primeiramente resolvidas
                pelo CartoLOL e, caso persistam, deverão ser solucionadas pelo
                Foro da Comarca de São Paulo, São Paulo, com exclusão de
                qualquer outro, por mais privilegiado que seja ou venha a ser.
              </p>
              <h6>9.4. Dúvidas.</h6>
              <p>
                Caso você tenha alguma dúvida, comentário ou sugestão, por
                favor, entre em contato conosco por meio do e-mail
                contato@CartoLOL.com.br.
              </p>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<style lang="scss">
.content h1,
.content h2,
.content h3,
.content h4,
.content h5,
.content h6 {
  color: white;
  margin-top: 30px;
}
</style>

<script>
export default {}
</script>
