<template>
  <b-col class="player-card py-3 col-12 col-lg-4 col-xl">
    <div class="text-center py-3">
      <h3 class="mb-0 text-uppercase">
        <b-img
          :src="require(`@/assets/img/ico_${role}.png`)"
          fluid
          alt=""
          class="mr-2"
          style="max-height: 45px"
        ></b-img>
        {{ role }}
      </h3>
    </div>
    <div
      class="card-player-container"
    >
      <div class="card-player-outer">
        <div
          class="card-player-inner"
          :style="{
            background: 'url(' + require('@/assets/img/bg_player.png') + ')'
          }"
        >
          <div
            v-if="id"
            class="card-player"
            :style="{
              'background-image': 'url(' + src + ')'
            }"
          >
            <div class="card-player-overlay"></div>
            <div class="card-player-team text-right mr-3 mt-2">
              <b-img
                :src="team_image"
                fluid
                alt=""
                style="max-height: 36px"
              ></b-img>
            </div>
            <div class="card-player-text-lane">
              <b-img
                :src="require(`@/assets/img/text_lane_${role}.png`)"
                fluid
                alt=""
              ></b-img>
            </div>
            <div class="card-player-desc text-center">
              <p class="mb-0">
                <b class="text-uppercase"> {{ name }} </b>
              </p>
              <p class="mb-0 text-3">
                <small> {{ team }} </small>
              </p>
            </div>
          </div>
          <div
            v-else
            class="card-player"
            :style="{
              'background-image':
                'url(' + require('@/assets/img/ico_select_player.png') + ')'
            }"
          >
            <div class="card-player-team text-right mr-3 mt-2 mb-3">
              <b-img
                :src="require('@/assets/img/card_player_team.png')"
                fluid
                alt=""
                style="max-height: 36px; visibility: hidden"
              ></b-img>
            </div>
            <div class="card-player-text-lane">
              <b-img
                :src="require('@/assets/img/text_lane_sup.png')"
                fluid
                alt=""
                style="visibility: hidden"
              ></b-img>
            </div>
            <div class="card-player-desc text-center">
              <p class="mb-0 text-white">
                <b> Sem jogador</b>
              </p>
              <p class="mb-0 text-3">
                <small>
                  Você não selecionou um jogador nesta rodada
                </small>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- DESC ANTIGO -->
    <b-row class="d-none">
      <b-col cols="8" class="text-center pr-0">
        <div style="border: solid 1px #2DB3BC" class="py-2">
          <span>{{ name }}</span>
        </div>
      </b-col>
      <b-col cols="4" style="" class="text-center pl-0">
        <div
          style="border: solid 1px #2DB3BC; border-left: none; height: 100%; position: relative"
          class=""
        >
          <div
            style="position: absolute; top: 50%; transform: translateY(-50%); left: 0; right: 0; margin: auto"
          >
            <small>{{ role }}</small>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row v-if="hasPlayerSelected" class="d-none">
      <b-col cols="12 mt-2">
        <div
          class="bg-new-azul px-2"
          style="position: absolute; right: 30px; top: 10px;"
          v-if="price"
        >
          <small>
            <b>{{ price }}</b>
          </small>
        </div>
        <div :class="selectedClass">
          <b-link @click="selectPlayer">
            <b-img :src="src" fluid />
          </b-link>
        </div>
      </b-col>
    </b-row>
    <!-- FIM DESC ANTIGO -->
    <b-row v-else>
      <b-col cols="12 mt-2 text-center">
        <div
          class="border-blue-full p-3"
          :class="selectedClass"
          @click="selectPlayer"
          style="cursor:pointer"
        >
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" v-if="!isCoach && !loading" class="mt-3">
        <div
          style=""
          class="text-center"
          v-if="isShotcaller"
        >
          <b-link class="">
            <b-img
          :src="require(`@/assets/img/ico_captain_active.png`)"
          fluid
          alt=""
        ></b-img>
          </b-link>
        </div>
        <div class="text-center border-blue-full" v-else-if="editable">
          <b-link @click="selectShotcaller">
            <small>
              DEFINIR COMO CAPITÃO
            </small>
          </b-link>
        </div>
      </b-col>
    </b-row>
  </b-col>
</template>

<script>
export default {
  name: 'AppPlayerTeamFantasy',
  props: {
    id: {
      type: Number,
      default: null
    },
    name: {
      type: String,
      required: true
    },
    team: {
      type: String,
      required: true
    },
    team_image: {
      type: String,
      required: true
    },
    src: {
      type: String,
      default: null
    },
    role: {
      type: String,
      required: true
    },
    price: {
      type: String,
      default: null
    },
    isShotcaller: {
      type: Boolean,
      required: true
    },
    isSelected: {
      type: String,
      default: null
    },
    loading: {
      type: Boolean,
      default: false
    },
    editable: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    selectedClass () {
      if (this.isSelected === null || this.isSelected === undefined) {
        return ''
      }
      return this.isSelected === this.role ? 'captain' : ''
    },
    hasPlayerSelected () {
      return this.id !== null
    },
    isCoach () {
      return this.role === 'coach'
    }
  },
  methods: {
    selectPlayer () {
      if (this.editable) {
        this.$emit('update-player', this.role)
      }
    },
    selectShotcaller () {
      this.$emit('update-shotcaller', this.role)
    }
  }
}
</script>
