<template>
  <tr>
    <td scope="row" class="text-center">
      <div class="text-center p-1">
        <div class="lista-coroa text-center p-1">
          <b-link @click="$emit('select-shotcaller')">
            <b-img
              :src="require(`@/assets/img/ico_coroa${isShotcaller}.png`)"
              fluid
              alt=""
              style="max-height: 36px"
              class=""
            ></b-img>
          </b-link>
        </div>
        <p class="pt-2">CAPITÃO</p>
      </div>
    </td>
    <th scope="row" class="text-center">
      <div class="tabela-time text-center p-1">
        <b-img
          :src="team_image"
          fluid
          alt=""
          style="max-height: 36px"
          class=""
        ></b-img>
      </div>
    </th>
    <td>
      <div>
        <b-media class="">
          <template #aside>
            <div class="card-player-container">
              <div class="card-player-outer">
                <div
                  class="card-player-inner"
                  :style="{
                    background:
                      'url(' + require('@/assets/img/bg_player.png') + ')',
                  }"
                >
                  <div
                    class="card-player-2"
                    :style="{
                      'background-image':
                        'url(' + player_image + ')',
                    }"
                  ></div>
                </div>
              </div>
            </div>
          </template>
          <h5 class="mt-0 mb-1 text-uppercase">{{name}}</h5>
          <p class="mb-0 text-1">
            <b-img
              :src="require('@/assets/img/filter_top.png')"
              fluid
              alt=""
              class="icon-sm icon-green mr-2"
            ></b-img>
            <strong class="text-uppercase">{{roleName}}</strong>
          </p>
        </b-media>
      </div>
    </td>
    <td><b>{{average_performance || '--'}}</b> pts</td>
    <td><b>{{last_performance || '--'}}</b> pts</td>
    <td>
      <h4 class="mb-0"><small>L$</small> {{ price || '--' }}</h4>
    </td>
    <td class="text-right" v-if="isMarketOpen">
      <b-button class="btn-1 w-100" v-if="id" @click="$emit('select-role', role)">
        <b-img
          :src="require('@/assets/icons/bold/signpost.svg')"
          fluid
          alt=""
          class="icon-sm icon-white mr-1"
        ></b-img>
        <b>TROCAR</b>
      </b-button>
      <b-button class="btn-3 w-100" v-else @click="$emit('select-role', role)">
        <b-img
          :src="require('@/assets/icons/bold/add-square.svg')"
          fluid
          alt=""
          class="icon-sm icon-black mr-1"
        ></b-img>
        <b>ADICIONAR</b>
      </b-button>
    </td>
    <td v-else>
      <h4 class="mb-0"> {{ points || '--' }}<small>pts</small></h4>
    </td>
  </tr>
</template>

<script>
export default {
  name: 'FantasyListPlayer',
  props: {
    id: {
      type: Number,
      default: null
    },
    name: {
      type: String,
      default: null
    },
    role: {
      type: String,
      default: null
    },
    team: {
      type: String,
      default: null
    },
    player_image: {
      type: String,
      default: null
    },
    team_image: {
      type: String,
      default: null
    },
    price: {
      type: String,
      default: null
    },
    points: {
      type: String,
      default: null
    },
    average_performance: {
      type: String,
      default: null
    },
    last_performance: {
      type: String,
      default: null
    },
    shotcaller: {
      type: Boolean,
      default: false
    },
    selectedRole: {
      type: String,
      default: null
    },
    isMarketOpen: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isShotcaller () {
      return this.shotcaller ? '_active' : ''
    },
    pricePrefix () {
      return this.price ? 'L$ ' : 'A definir'
    },
    roleName () {
      return this.role === 'jun' ? 'jng' : this.role
    }
  }
}
</script>
