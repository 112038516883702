<template>
  <b-col class="col-12 col-md-4 col-xl-3 offset-xl-1 py-3" :id="`player-coach`">
    <div class="text-center py-3">
      <h3 class="mb-0">
        <b-img
          :src="require(`@/assets/img/ico_coach.png`)"
          fluid
          alt=""
          class="mr-2"
        ></b-img>
        COACH
      </h3>
    </div>
    <b-link class="text-body" @click="$emit('select-role', 'coach')">
      <div class="card-player-container" :class="{active: selectedRole === 'coach'}">
        <div class="card-player-outer">
          <div
            class="card-player-inner"
            :style="{
              background: 'url(' + require('@/assets/img/bg_player.png') + ')',
            }"
          >
            <div
            v-if="id"
              class="card-player"
              :style="{
                'background-image':
                  'url(' + player_image + ')',
              }"
            >
              <div class="card-player-overlay"></div>
              <div class="card-player-team text-right mr-3 mt-2">
                <b-img
                  :src="team_image"
                  fluid
                  alt=""
                  style="max-height: 36px"
                ></b-img>
              </div>
              <div class="card-player-text-lane">
                <b-img
                  :src="require(`@/assets/img/text_lane_coach.png`)"
                  fluid
                  alt=""
                ></b-img>
              </div>
              <div class="card-player-desc text-center">
                <p class="mb-0">
                  <b class="text-uppercase"> {{ name }} </b>
                </p>
                <p class="mb-0 text-3">
                  <small> {{ team }} </small>
                </p>
              </div>
            </div>
            <div
              v-else
              class="card-player"
              :style="{
                'background-image':
                  'url(' +
                  require('@/assets/img/ico_select_player.png') +
                  ')',
              }"
            >
              <div class="card-player-team text-right mr-3 mt-2 mb-3">
                <b-img
                  :src="require('@/assets/img/card_player_team.png')"
                  fluid
                  alt=""
                  style="max-height: 36px; visibility: hidden"
                ></b-img>
              </div>
              <div class="card-player-text-lane">
                <b-img
                  :src="require('@/assets/img/text_lane_coach.png')"
                  fluid
                  alt=""
                  style="visibility: hidden"
                ></b-img>
              </div>
              <div class="card-player-desc text-center">
                <p class="mb-0 text-white">
                  <b v-if="isMarketOpen"> Escolha um treinador </b>
                  <b v-else> Sem treinador</b>
                </p>
                <p class="mb-0 text-3">
                  <small v-if="isMarketOpen"> Clique aqui para escolher </small>
                  <small v-else> Você não selecionou um treinador nesta rodada </small>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="card-player-change" v-if="id">
          <div class="card-player-change-inner">
            <div class="text-center pb-3 pt-3">
              <b-img
                :src="require(`@/assets/icons/bold/signpost.svg`)"
                fluid
                alt=""
                class="icon-white"
                style="height: 100px"
              ></b-img>
            </div>
            <p class="text-white text-center mb-0">
              Trocar de Coach
            </p>
            <p class="text-center text-3 mb-0">
              <small>Clique aqui para escolher</small>
            </p>
          </div>
        </div>
      </div>
    </b-link>
    <div class="card-player-price text-center mt-2">
      <span v-if="points" class="px-3 py-2"> <b>{{ points }}</b> pts </span>
      <span v-else class="px-3 py-2"> {{ pricePrefix }} <b>{{ price }}</b> </span>
    </div>
  </b-col>
</template>

<script>
export default {
  name: 'FantasyCardCoach',
  props: {
    id: {
      type: Number,
      default: null
    },
    name: {
      type: String,
      default: null
    },
    team: {
      type: String,
      default: null
    },
    player_image: {
      type: String,
      default: null
    },
    team_image: {
      type: String,
      default: null
    },
    price: {
      type: String,
      default: null
    },
    points: {
      type: String,
      default: null
    },
    selectedRole: {
      type: String,
      default: null
    },
    isMarketOpen: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    pricePrefix () {
      return this.price ? 'L$ ' : 'A definir'
    }
  }
}
</script>
