import setsNotify from '../mixins/sets-notify.mixin'

export default {
  methods: {
    verifyErrorsForm (fields) {
      return new Promise((resolve, reject) => {
        for (const field of fields) {
          field.$touch()
        }
        fields.filter(field => field.$error === true).length === 0 ? resolve() : reject(new Error('Verifique os erros do formulário'))
      })
    },
    parseServerSideErros (form, fields) {
      const keys = Object.keys(form)
      Object.keys(fields).forEach((field) => {
        if (field === '__all__') {
          fields[field].forEach((message) => {
            setsNotify.methods.setNotifyDanger(message)
          })
          return
        }
        if (!keys.includes(field)) {
          form[field] = []
        }
        form[field] = []
        fields[field].forEach(item => {
          if (!form[field].includes(item)) {
            form[field].push(item)
          }
        })
      })
      return form
    },
    clearErrors (form) {
      Object.keys(form).forEach((field) => {
        form[field] = []
      })
    },
    isJson (item) {
      item = typeof item !== 'string'
        ? JSON.stringify(item)
        : item

      try {
        item = JSON.parse(item)
      } catch (e) {
        return false
      }
      return (typeof item === 'object' && item !== null)
    }
  }
}
