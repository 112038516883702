<template>
  <div>
    <b-row class="pt-5">
      <b-col class="col-12 col-lg-8 align-self-center py-2">
        <b-img
          :src="require('@/assets/icons/broken/shopping-cart.svg')"
          fluid
          alt=""
          class="icon-sm icon-green mr-3"
        ></b-img>
        MERCADO
      </b-col>
      <b-col class="col-12 col-lg-4 py-2">
        <b-form-group>
          <b-input-group prepend=" " class="mb-2 mr-sm-2 mb-sm-0">
            <span
              ><b-img
                :src="require('@/assets/icons/outline/search-normal.svg')"
                fluid
                alt=""
                class="icon-white icon-sm"
              ></b-img
            ></span>
            <b-form-input placeholder="Pesquisar..." v-model="filter" />
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>
    <hr class="my-1" />
    <b-row class="">
      <b-col class="col-12 col-md-6 align-self-center mt-3 py-2">
        <div class="tab-tipo-mapa float-left">
          <div style="float: left" class="pt-2 mr-3">Tipo:</div>
          <b-nav>
            <b-nav-item :active="!onlyCoach">
              <b-img
                :src="require('@/assets/icons/bold/game.svg')"
                fluid
                alt=""
                class="icon-sm mr-2"
              ></b-img>
              Jogador
            </b-nav-item>
            <b-nav-item :active="onlyCoach">
              <b-img
                :src="require('@/assets/icons/bold/headphone.svg')"
                fluid
                alt=""
                class="icon-sm mr-2"
              ></b-img>
              Coach
            </b-nav-item>
          </b-nav>
        </div>
      </b-col>
      <b-col class="col-12 col-md-6 align-self-center mt-3 py-2 text-right">
        <div class="tab-tipo-mapa tab-tipo-mapa2 float-right float-left-xm">
          <b-nav v-if="!onlyCoach">
            <b-nav-item :active="role === 'top'" @click="filterRole('top')">
              <b-img
                :src="require('@/assets/img/filter_top.png')"
                fluid
                alt=""
                class="icon-sm"
              ></b-img>
            </b-nav-item>
            <b-nav-item :active="role === 'jun'" @click="filterRole('jun')">
              <b-img
                :src="require('@/assets/img/filter_jgn.png')"
                fluid
                alt=""
                class="icon-sm"
              ></b-img>
            </b-nav-item>
            <b-nav-item :active="role === 'mid'" @click="filterRole('mid')">
              <b-img
                :src="require('@/assets/img/filter_mid.png')"
                fluid
                alt=""
                class="icon-sm"
              ></b-img>
            </b-nav-item>
            <b-nav-item :active="role === 'adc'" @click="filterRole('adc')">
              <b-img
                :src="require('@/assets/img/filter_adc.png')"
                fluid
                alt=""
                class="icon-sm"
              ></b-img>
            </b-nav-item>
            <b-nav-item :active="role === 'sup'" @click="filterRole('sup')">
              <b-img
                :src="require('@/assets/img/filter_sup.png')"
                fluid
                alt=""
                class="icon-sm"
              ></b-img>
            </b-nav-item>
          </b-nav>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="pt-3">
        <table class="table custom-table">
          <thead>
            <tr>
              <th scope="col" class="text-center">TIME</th>
              <th scope="col">JOGADOR</th>
              <th scope="col">MÉDIA</th>
              <th scope="col">ÚLTIMA RODADA</th>
              <th scope="col">VARIAÇÃO</th>
              <th scope="col">PREÇO</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <FantasyMarketPlayer v-for="(player, index) in players" :key="index" :player="player" :weigths="weigths" @add-player="addPlayer(player)"/>
        </table>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import FantasyMarketPlayer from './FantasyMarketPlayer.vue'
export default {
  components: { FantasyMarketPlayer },
  name: 'FantasyMarket',
  props: {
    round_players: {
      type: Array,
      default: () => []
    },
    weigths: {
      type: Array,
      default: () => []
    },
    selectedRole: {
      type: String,
      default: null
    },
    team: {
      type: Object,
      default: () => ({ top_id: null, jun_id: null, mid_id: null, adc_id: null, sup_id: null, coach_id: null, shotcaller_id: null })
    }
  },
  data () {
    return {
      filter: '',
      role: null
    }
  },
  watch: {
    selectedRole: {
      immediate: true,
      handler (newRole) {
        if (newRole !== 'coach') {
          this.role = newRole
        } else {
          this.role = null
        }
      }
    }
  },
  computed: {
    onlyCoach () {
      return this.selectedRole === 'coach'
    },
    players () {
      return this.round_players.filter(player => {
        let byName = true
        if (this.filter.length > 2) {
          byName = player.nickname.toLowerCase().includes(this.filter.toLowerCase())
        }
        if (this.onlyCoach) {
          return byName && player.player_role === 'coach'
        }
        if (this.role) {
          return byName && player.player_role === this.role
        }
        return byName
      })
    }
  },
  methods: {
    filterRole (role) {
      if (this.role === role) {
        this.role = null
      } else {
        this.role = role
      }
    },
    addPlayer (player) {
      const localTeam = JSON.parse(JSON.stringify(this.team))
      if (localTeam.shotcaller_id && localTeam[`${this.selectedRole}_id`] === localTeam.shotcaller_id) {
        localTeam.shotcaller_id = player.player_id
      }
      localTeam[`${this.selectedRole}_id`] = player.player_id
      localTeam[`${this.selectedRole}_image`] = player.image
      localTeam[`${this.selectedRole}_nickname`] = player.nickname
      localTeam[`${this.selectedRole}_player_price`] = player.price
      localTeam[`${this.selectedRole}_price`] = player.price
      localTeam[`${this.selectedRole}_point`] = null
      localTeam[`${this.selectedRole}_role`] = player.player_role
      localTeam[`${this.selectedRole}_team_id`] = player.team_id
      localTeam[`${this.selectedRole}_team_image`] = player.team_image
      localTeam[`${this.selectedRole}_team_shotcut`] = player.team_shortcut
      this.$emit('update-team', localTeam)
      this.filter = ''

      document.querySelector('.btn-fixed-team-players').classList.add('visible')
    }
  }
}
</script>
