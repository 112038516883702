import Base from '../base.js'
import { service } from '../../boot/axios'
import { statusResponse } from '../responseService'

export default class Users extends Base {
  constructor () {
    super('user/')
  }

  logout = () => {
    return new Promise((resolve, reject) => {
      service.post(this.api).then((response) => {
        resolve(response)
      }).catch((err) => {
        reject(statusResponse(err, 'action'))
      })
    })
  }

  publicProfile = (data) => {
    const friend = data
    return new Promise((resolve, reject) => {
      service.get(`${this.api}${friend}/profile/`).then((response) => {
        resolve(response)
      }).catch((err) => {
        reject(statusResponse(err, 'action'))
      })
    })
  }

  addFriend = (data) => {
    const friend = { friend: data }
    return new Promise((resolve, reject) => {
      service.post(`${this.api}add/`, friend).then((response) => {
        resolve(response)
      }).catch((err) => {
        reject(statusResponse(err, 'action'))
      })
    })
  }

  forgotPassword = ($data) => {
    return new Promise((resolve, reject) => {
      service.post(`${this.api}forgotPassword/`, $data).then((response) => {
        resolve(response)
      }).catch((err) => {
        reject(statusResponse(err, 'action'))
      })
    })
  }

  resendEmail = ($data) => {
    return new Promise((resolve, reject) => {
      service.post(`${this.api}resend/`, $data).then((response) => {
        resolve(response)
      }).catch((err) => {
        reject(statusResponse(err, 'action'))
      })
    })
  }

  acceptFriend = (data) => {
    const friend = { id: data }
    return new Promise((resolve, reject) => {
      service.post(`${this.api}${data}/accept/`, friend).then((response) => {
        resolve(response)
      }).catch((err) => {
        reject(statusResponse(err, 'action'))
      })
    })
  }

  changePassword = ($data) => {
    return new Promise((resolve, reject) => {
      service.post(`${this.api}changePassword/`, $data).then((response) => {
        resolve(response)
      }).catch((err) => {
        reject(statusResponse(err, 'action'))
      })
    })
  }

  partialUpdate = ($data) => {
    return new Promise((resolve, reject) => {
      service.patch(`${this.api}${$data.id}/`, $data).then((response) => {
        resolve(response)
      }).catch((err) => {
        reject(statusResponse(err, 'action'))
      })
    })
  }

  verificationCode = ($data) => {
    return new Promise((resolve, reject) => {
      service.post(`${this.api}validate/`, $data).then((response) => {
        resolve(response)
      }).catch((err) => {
        reject(statusResponse(err, 'action'))
      })
    })
  }

  undoFriend = (data) => {
    const friend = { id: data }
    return new Promise((resolve, reject) => {
      service.post(`${this.api}${data}/undo/`, friend).then((response) => {
        resolve(response)
      }).catch((err) => {
        reject(statusResponse(err, 'action'))
      })
    })
  }

  rejectFriend = (data) => {
    const friend = { id: data }
    return new Promise((resolve, reject) => {
      service.post(`${this.api}${data}/reject/`, friend).then((response) => {
        resolve(response)
      }).catch((err) => {
        reject(statusResponse(err, 'action'))
      })
    })
  }

  cancelFriend = (data) => {
    const friend = { id: data }
    return new Promise((resolve, reject) => {
      service.post(`${this.api}${data}/cancel/`, friend).then((response) => {
        resolve(response)
      }).catch((err) => {
        reject(statusResponse(err, 'action'))
      })
    })
  }

  register = ($data) => {
    return new Promise((resolve, reject) => {
      service.post(`${this.api}register/`, $data).then((response) => {
        resolve(response)
      }).catch((err) => {
        reject(statusResponse(err, 'action'))
      })
    })
  }

  sendCurriculum = ($data) => {
    return new Promise((resolve, reject) => {
      service.post(`${this.api}workwithus/`, $data, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Content-Transfer-Encoding': 'base64'
        }
      }).then((response) => {
        resolve(response)
      }).catch((err) => {
        reject(statusResponse(err, 'action'))
      })
    })
  }

  picture = ($data, $id) => {
    return new Promise((resolve, reject) => {
      service.patch(`${this.api}${$id}/picture/`, $data, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Content-Transfer-Encoding': 'base64'
        }
      }).then((response) => {
        resolve(response)
      }).catch((err) => {
        reject(statusResponse(err, 'action'))
      })
    })
  }

  report = ($data) => {
    return new Promise((resolve, reject) => {
      service.post(`${this.api}${$data.id}/report/`, $data).then((response) => {
        resolve(response)
      }).catch((err) => {
        reject(statusResponse(err, 'action'))
      })
    })
  }

  deleteAccount = ($id) => {
    return new Promise((resolve, reject) => {
      service.delete(`${this.api}${$id}/`).then((response) => {
        resolve(response)
      }).catch((err) => {
        reject(statusResponse(err, 'action'))
      })
    })
  }
}
