<template>
  <tbody>
    <tr>
      <th scope="row" class="text-center">
        <div class="tabela-time text-center p-1">
          <b-img
            :src="player.team_image"
            fluid
            alt=""
            style="max-height: 36px"
            class=""
          ></b-img>
        </div>
      </th>
      <td>
        <div>
          <b-media class="">
            <template #aside>
              <div class="card-player-container">
                <div class="card-player-outer">
                  <div
                    class="card-player-inner"
                    :style="{
                      background:
                        'url(' + require('@/assets/img/bg_player.png') + ')',
                    }"
                  >
                    <div
                      class="card-player-2"
                      :style="{
                        'background-image':
                          'url(' + player.image + ')',
                      }"
                    ></div>
                  </div>
                </div>
              </div>
            </template>
            <h5 class="mt-0 mb-1 text-uppercase">{{ player.nickname }}</h5>
            <p class="mb-0 text-1">
              <b-img
                v-if="player.player_role !== 'coach'"
                :src="require(`@/assets/img/filter_${player.player_role}.png`)"
                fluid
                alt=""
                class="icon-sm icon-green mr-2"
              ></b-img>
              <strong class="text-uppercase">{{ player.player_role }}</strong>
            </p>
          </b-media>
        </div>
      </td>
      <td><b>{{ hasValue(player.average_performance) }}</b> pts</td>
      <td><b>{{ hasValue(player.last_performance) }}</b> pts</td>
      <td>
        <p v-if="player.variance === null" class="mb-0">
           --
        </p>
        <p v-else class="mb-0" :class="varianceColor">
          <b-img
            v-if="player.variance !== '0.00'"
            :src="require(`@/assets/icons/bold/arrow-${hasPositiveVariance ? 'up-1' : 'down'}.svg`)"
            fluid
            alt=""
            class="icon-sm mr-2"
            :class="{
              'icon-green': hasPositiveVariance,
              'icon-red': !hasPositiveVariance,
            }"
          ></b-img>
          <small>L$</small> <b>{{ player.variance.replace('-','') }}</b>
        </p>
      </td>
      <td>
        <h4 class="mb-0"><small>L$</small> {{ player.price }}</h4>
      </td>
      <td class="text-right">
        <h4 v-if="player.performance" class="mb-0 d-inline mr-3">{{ player.performance }} <small>pts</small></h4>
        <b-button v-else class="btn-3 mr-3" @click="$emit('add-player')">
          <b-img
            :src="require('@/assets/icons/bold/add-square.svg')"
            fluid
            alt=""
            class="icon-sm icon-black mr-1"
          ></b-img>
          <b>ADICIONAR</b>
        </b-button>
        <b-button v-b-toggle="`stats-${player.player_id}`" class="p-0 m-0 btn-1" v-if="player.player_role !== 'coach'">
          <span class="when-open">
            <b-img
              :src="require('@/assets/icons/bold/arrow-square-up.svg')"
              fluid
              alt=""
              class="icon-sm icon-grey"
            ></b-img> </span
          ><span class="when-closed">
            <b-img
              :src="require('@/assets/icons/bold/arrow-square-down.svg')"
              fluid
              alt=""
              class="icon-sm icon-grey"
            ></b-img>
          </span>
        </b-button>
      </td>
    </tr>
    <tr v-if="player.player_role !== 'coach'">
      <td scope="row" colspan="7" class="detail">
        <b-collapse :id="`stats-${player.player_id}`" class="detail-outer">
          <div class="detail-content">
            <div class="row">
              <div class="col-12 col-lg-5 my-2">
                <div class="quadro p-2">
                  <div class="quadro-inner py-2">
                    <h3 class="text-center mb-0 py-3">
                      K: {{ hasValue(player.kill) }} | D: {{ hasValue(player.death) }} | A: {{ hasValue(player.assist) }}
                    </h3>
                  </div>
                </div>
              </div>
              <div class="col-12 col-lg-7 my-2">
                <p>
                  <b> ESTATÍSTICAS: </b>
                </p>
                <div class="row">
                  <b-col class="col-12 col-md-4" v-if="weigth.hasKda">
                    <b-row>
                      <b-col> KDA </b-col>
                      <b-col class="text-right">
                        <b>{{ hasValue(player.kda) }}</b>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col class="col-12 col-md-4" v-if="weigth.hasDmg">
                    <b-row>
                      <b-col> Dano/Min </b-col>
                      <b-col class="text-right">
                        <b>{{ hasValue(player.damage) }}</b>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col class="col-12 col-md-4" v-if="weigth.hasFarm">
                    <b-row>
                      <b-col> Farm/10min </b-col>
                      <b-col class="text-right">
                        <b>{{ hasValue(player.farm) }}</b>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col class="col-12 col-md-4" v-if="weigth.hasGold">
                    <b-row>
                      <b-col> Gold/Min </b-col>
                      <b-col class="text-right">
                        <b>{{ hasValue(player.gold) }}</b>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col class="col-12 col-md-4" v-if="weigth.hasObj">
                    <b-row>
                      <b-col> Objetivos </b-col>
                      <b-col class="text-right">
                        <b>{{ hasValue(player.objectives) }}</b>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col class="col-12 col-md-4" v-if="weigth.hasVision">
                    <b-row>
                      <b-col> Words </b-col>
                      <b-col class="text-right">
                        <b>{{ hasValue(player.ward_placed) }}</b>
                      </b-col>
                    </b-row>
                  </b-col>
                </div>
              </div>
            </div>
          </div>
        </b-collapse>
      </td>
    </tr>
  </tbody>
</template>

<script>
export default {
  name: 'FantasyMarketPlayer',
  props: {
    player: {
      type: Object,
      default: () => ({})
    },
    weigths: {
      type: Array,
      default: () => ([])
    }
  },
  computed: {
    weigth () {
      return this.weigths.find(w => w.role_name === this.player.player_role)
    },
    hasVariance () {
      return this.player.variance !== '0.00'
    },
    hasPositiveVariance () {
      return parseFloat(this.player.variance) > 0
    },
    varianceColor () {
      if (this.player.variance === null || this.player.variance === '0.00') {
        return ''
      }
      return this.hasPositiveVariance ? 'text-1' : 'text-2'
    }
  },
  methods: {
    hasValue (value) {
      if (value) {
        return value
      }
      return '--'
    }
  }
}
</script>
