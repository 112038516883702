<template>
  <b-row>
    <b-col class="text-center pt-3">
      <h5 class="mb-1">
        COMPARTILHE
      </h5>
      <p>
        <b-overlay
          :show="true"
          class="d-inline-block mr-md-3"
          :opacity="opacity"
          style="cursor: pointer"
          rounded
          @click="share('Facebook')"
        >
          <template #overlay>
            <b-spinner small variant="primary" v-show="busy"></b-spinner>
          </template>
          <b-link ref="Facebook" :href="`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(value)}`" target="_blank" onclick="javascript:window.open(this.href, '', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=480,width=640');return false;">
            <b-img
                :src="require('@/assets/img/ico_face2.png')"
                fluid
                alt=""
                class="px-2"
              ></b-img>
          </b-link>
        </b-overlay>
        <b-overlay
          :show="true"
          class="d-inline-block"
          :opacity="opacity"
          style="cursor: pointer"
          rounded
          @click="share('Twitter')"
        >
          <template #overlay>
            <b-spinner small variant="primary" v-show="busy"></b-spinner>
          </template>
          <b-link ref="Twitter" :href="`https://twitter.com/share?url=${encodeURIComponent(value)}`" target="_blank" onclick="javascript:window.open(this.href, '', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=480,width=640');return false;">
            <b-img
                :src="require('@/assets/img/ico_twitter2.png')"
                fluid
                alt=""
                class="px-2"
              ></b-img>
          </b-link>
        </b-overlay>
      </p>
    </b-col>
  </b-row>
</template>

<script>
import setsNotify from '@/mixins/sets-notify.mixin'
export default {
  name: 'AppSocialShare',
  mixins: [setsNotify],
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    canShare: {
      type: Boolean,
      default: true
    },
    service: {
      type: Object,
      required: true
    },
    errorMessage: {
      type: String,
      default: 'Você não pode compartilhar este conteúdo.'
    },
    shareId: {
      type: Number,
      required: true
    },
    value: {
      type: String,
      default: null
    }
  },
  data: () => ({
    opacity: 0,
    busy: false

  }),
  watch: {
    shareId () {
      this.$emit('input', null)
    }
  },
  methods: {
    async share (provider) {
      if (!this.canShare) {
        this.setNotifyDanger(this.errorMessage)
        return
      }
      if (!this.value) {
        this.opacity = 0.85
        this.busy = true
        try {
          const { data } = await this.service.share(this.shareId)
          this.$emit('input', `${process.env.VUE_APP_API_URL}${this.service.api}shared/?secret=${data.data}`)
          await new Promise(resolve => setTimeout(resolve, 100))
          this.$refs[provider].$el.click()
          if (this.$gtag) {
            const category = this.service.api.replaceAll('/', '')
            this.$gtag.event('Compartilhar', { event_category: category[0].toUpperCase() + category.slice(1), event_label: provider })
          }
        } catch (error) {
          this.setNotifyDanger(`${error.message}. Error: ${error.name}.`)
        } finally {
          this.opacity = 0
          this.busy = false
        }
        return
      }
      this.$refs[provider].$el.click()
    }
  }
}
</script>
