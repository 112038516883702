import Base from '../base.js'
import { service } from '../../boot/axios'
import { statusResponse } from '../responseService'

export default class Games extends Base {
  constructor () {
    super('fantasy/')
  }

  getFantasy = () => {
    return new Promise((resolve, reject) => {
      service.get(`${this.api}`).then((response) => {
        resolve(response)
      }).catch((err) => {
        reject(statusResponse(err, 'get', 'item'))
      })
    })
  }

  subscribe = (id) => {
    return new Promise((resolve, reject) => {
      service.post(`${this.api}${id}/subscribe/`, id).then((response) => {
        resolve(response)
      }).catch((err) => {
        reject(statusResponse(err, 'action'))
      })
    })
  }

  share = (id) => {
    return new Promise((resolve, reject) => {
      service.get(`${this.api}${id}/share/`).then((response) => {
        resolve(response)
      }).catch((err) => {
        reject(statusResponse(err, 'get', 'item'))
      })
    })
  }

  leaderboard = (id) => {
    return new Promise((resolve, reject) => {
      service.get(`${this.api}${id}/leaderboard/`).then((response) => {
        resolve(response)
      }).catch((err) => {
        reject(statusResponse(err, 'get', 'item'))
      })
    })
  }

  friendLeaderboard = (id) => {
    return new Promise((resolve, reject) => {
      service.get(`${this.api}${id}/leaderboard_friend/`).then((response) => {
        resolve(response)
      }).catch((err) => {
        reject(statusResponse(err, 'get', 'item'))
      })
    })
  }

  roundLeaderboard = (id) => {
    return new Promise((resolve, reject) => {
      service.get(`${this.api}${id}/leaderboard_round/`).then((response) => {
        resolve(response)
      }).catch((err) => {
        reject(statusResponse(err, 'get', 'item'))
      })
    })
  }

  roundFriendLeaderboard = (id) => {
    return new Promise((resolve, reject) => {
      service.get(`${this.api}${id}/leaderboard_round_friend/`).then((response) => {
        resolve(response)
      }).catch((err) => {
        reject(statusResponse(err, 'get', 'item'))
      })
    })
  }

  team = (roundId, userId) => {
    return new Promise((resolve, reject) => {
      service.get(`${this.api}${roundId}/team/?id=${userId}`).then((response) => {
        resolve(response)
      }).catch((err) => {
        reject(statusResponse(err, 'get', 'item'))
      })
    })
  }
}
