<template>
  <div>
    <div
      class="bg-capa-2"
      :style="{
        'background-image':
          'url(' + require('../assets/img/bg_contato.png') + ')'
      }"
    >
      <b-container fluid="xl" class="px-md-5 px-xl-0">
        <b-row>
          <b-col class="col-12">
            <h1 class="text-white ff-chaney text-center mt-5">
              <b-img
                :src="require('../assets/icons/bold/message-text.svg')"
                fluid
                alt=""
                class="icon-white btn-img mr-1 d-none d-md-inline-block"
                style="height: 50px"
              ></b-img>
              ENTRE EM CONTATO
            </h1>
            <h4 class="text-center text-3 pt-3 pb-5">
              Dúvidas sobre o nosso funcionamento ou precisa de um suporte mais
              especializado?
            </h4>
            <div class="s1 pb-5">
              <div class="card py-5 px-3 px-lg-5">
                <b-form class="ff-inter" @submit="onSubmit">
                  <b-row>
                    <b-form-group label="Nome" class="col-12 col-lg-6">
                      <b-input-group prepend=" " class="mb-2 mr-sm-2 mb-sm-0">
                        <span
                          ><b-img
                            :src="require('../assets/icons/outline/user.svg')"
                            fluid
                            alt=""
                            class="icon-white icon-sm"
                          ></b-img
                        ></span>
                        <b-form-input
                          placeholder="Digite seu nome"
                          v-model="contact.name"
                          @input="$v.contact.name.$touch()"
                        ></b-form-input>
                      </b-input-group>
                      <b-form-invalid-feedback
                        :state="isNameValid"
                        v-for="(error, i) in errors.name" :key=i
                      >
                        {{ error }}
                      </b-form-invalid-feedback>
                    </b-form-group>

                    <b-form-group label="Email" class="col-12 col-lg-6">
                      <b-input-group prepend=" " class="mb-2 mr-sm-2 mb-sm-0">
                        <span
                          ><b-img
                            :src="require('../assets/icons/outline/sms.svg')"
                            fluid
                            alt=""
                            class="icon-white icon-sm"
                          ></b-img
                        ></span>
                        <b-form-input
                          placeholder="Digite seu email"
                          v-model="contact.email"
                          @input="$v.contact.email.$touch()"
                        ></b-form-input>
                      </b-input-group>
                      <b-form-invalid-feedback
                        :state="isEmailValid"
                        v-for="(error, i) in errors.email" :key=i
                      >
                        {{ error }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-row>
                  <b-row>
                    <b-form-group label="Assunto" class="col-12">
                      <b-input-group prepend=" " class="mb-2 mr-sm-2 mb-sm-0">
                        <span
                          ><b-img
                            :src="require('../assets/icons/outline/direct.svg')"
                            fluid
                            alt=""
                            class="icon-white icon-sm"
                          ></b-img
                        ></span>
                        <b-form-input
                          placeholder="Digite o assunto"
                          v-model="contact.subject"
                          @input="$v.contact.subject.$touch()"
                        ></b-form-input>
                      </b-input-group>
                      <b-form-invalid-feedback
                        :state="isSubjectValid"
                        v-for="(error, i) in errors.subject" :key=i
                      >
                        {{ error }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-row>
                  <b-row>
                    <b-form-group label="Mensagem" class="col-12 mt-4">
                      <b-form-textarea
                        placeholder="Digite a mensagem"
                        rows="5"
                        v-model="contact.message"
                        @input="$v.contact.message.$touch()"
                      ></b-form-textarea>
                      <b-form-invalid-feedback
                          :state="isMessageValid"
                          v-for="(error, i) in errors.message" :key=i
                      >
                          {{ error }}
                        </b-form-invalid-feedback>
                    </b-form-group>
                  </b-row>

                  <b-row>
                    <b-col class="col-12 col-lg-6">
                      <b-button class="btn-1 w-100 py-3 mt-4" @click="reset">
                        <b-img
                          :src="require('../assets/icons/broken/broom.svg')"
                          fluid
                          alt=""
                          class="icon-white icon-sm"
                        ></b-img>
                        <strong> LIMPAR ALTERAÇÕES </strong>
                      </b-button>
                    </b-col>
                    <b-col class="col-12 col-lg-6">
                      <b-button type="submit" class="btn-3 w-100 py-3 mt-4">
                        <b-img
                          :src="require('../assets/icons/broken/send-2.svg')"
                          fluid
                          alt=""
                          class="icon-dark icon-sm"
                        ></b-img>
                        <strong> ENVIAR MENSAGEM </strong>
                      </b-button>
                    </b-col>
                  </b-row>
                </b-form>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<style lang="scss">
.s1 .card {
  background-color: #13151b;
  border: 2px solid #20232c;
  border-radius: 16px;
}
</style>

<script>
import setsNotify from '../mixins/sets-notify.mixin'
import formErrors from '../mixins/form-errors.mixin'
import recaptcha from '../mixins/recaptcha.mixin'
import { required, email } from 'vuelidate/lib/validators'
import ContactService from '../services/contacts/contact'
export default {
  name: 'Contato',
  mixins: [setsNotify, formErrors, recaptcha],
  metaInfo () {
    return {
      meta: [{
        lang: 'pt-BR',
        title: 'CartoLOL - Contato',
        descrition: 'Quer receber os panoramas mais assertivos dos principais torneios de esports do mundo, mostrar que é pé quente nos palpites e de quebra montar o seu próprio time e mostrar que é o melhor manager do Brasil? Acesse nossos serviços e saiba mais.',
        keywords: 'bet, aposta, call, cblol, lol, cs, league-of-legends, betclub, esports, palpites, myhint, hint, bolão, bolao, baiano, baianolol, baianolol1, fantasy, cartolol, cartola-do-lol',
        site_name: 'CartoLOL',
        type: 'website',
        robots: 'index, follow'
      }]
    }
  },
  data: () => ({
    contactService: new ContactService(),
    contact: {
      name: '',
      email: '',
      subject: '',
      message: '',
      token: ''
    },
    errors: { name: [], email: [], subject: [], message: [], token: [] },
    loading: false
  }),
  validations: {
    contact: {
      name: {
        required
      },
      email: {
        required,
        email
      },
      subject: {
        required
      },
      message: {
        required
      }
    }
  },
  watch: {
    '$v.contact.name.$error' (newVal, oldVal) {
      const requiredField = 'Este campo é obrigatório.'
      this.errors.name = this.errors.name.filter(item => item !== requiredField)
      if (!this.$v.contact.name.required) {
        this.errors.name.push(requiredField)
      }
    },
    '$v.contact.email.$error' (newVal, oldVal) {
      const validEmail = 'Insira um endereço de email válido.'
      const requiredField = 'Este campo é obrigatório.'
      this.errors.email = this.errors.email.filter(item => item !== validEmail)
      if (!this.$v.contact.email.email) {
        this.errors.email.push(validEmail)
      }
      this.errors.email = this.errors.email.filter(item => item !== requiredField)
      if (!this.$v.contact.email.required) {
        this.errors.email.push(requiredField)
      }
    },
    '$v.contact.subject.$error' (newVal, oldVal) {
      const requiredField = 'Este campo é obrigatório.'
      this.errors.subject = this.errors.subject.filter(item => item !== requiredField)
      if (!this.$v.contact.subject.required) {
        this.errors.subject.push(requiredField)
      }
    },
    '$v.contact.message.$error' (newVal, oldVal) {
      const requiredField = 'Este campo é obrigatório.'
      this.errors.message = this.errors.message.filter(item => item !== requiredField)
      if (!this.$v.contact.message.required) {
        this.errors.message.push(requiredField)
      }
    }
  },
  computed: {
    isNameValid () {
      return this.errors.name.length === 0 ? null : false
    },
    isEmailValid () {
      return this.errors.email.length === 0 ? null : false
    },
    isSubjectValid () {
      return this.errors.subject.length === 0 ? null : false
    },
    isMessageValid () {
      return this.errors.message.length === 0 ? null : false
    }
  },
  methods: {
    onSubmit (event) {
      event.preventDefault()
      const fields = [this.$v.contact]
      this.verifyErrorsForm(fields).then((resp) => {
        this.sendEmail()
      }).catch((err) => {
        this.setNotifyDanger(err.message)
        console.log(err)
      })
    },
    async sendEmail () {
      this.loading = true
      try {
        this.contact.token = await this.getTokenRecaptcha('contact_us')
        if (this.contact.token === null) {
          return
        }
        await this.contactService.create(this.contact)
        this.setNotifySuccess('Mensagem enviada com sucesso.')
        this.contact = {
          name: '',
          email: '',
          subject: '',
          message: ''
        }
        this.$v.$reset()
        if (this.$gtag) {
          this.$gtag.event('Menssagem Enviada', { event_category: 'Contact' })
        }
      } catch (error) {
        if (error.name === 400 && this.isJson(error.message)) {
          this.errors = this.parseServerSideErros(this.errors, JSON.parse(error.message))
          this.errors.token.forEach(msg => {
            this.setNotifyDanger(`${msg}. Error: ${error.name}.`)
          })
          return
        }
        this.setNotifyDanger(`${error.message}. Error: ${error.name}.`)
      } finally {
        this.loading = false
      }
    },
    reset () {
      this.contact = {
        name: '',
        email: '',
        subject: '',
        message: '',
        token: ''
      }
      this.$v.$reset()
    }
  },
  head: {
    title: 'CartoLOL - Contato'
  }
}
</script>
