<template>
  <div :id="`map-${role}`" class="text-center map-player-container">
    <h6 class="mt-0 mb-1 mb-3" v-if="price">
      <span class="btn-2 px-2 py-1">
        <small v-if="!points"> L$ </small>
        <strong>{{ points || price }}</strong>
        <small v-if="points"> pts </small>
      </span>
    </h6>
    <b-link class="text-body" @click="$emit('select-role', role)">
      <div class="card-player-container" :class="{ active: selectedRole === role }" >
        <div class="card-player-outer">
          <div class="lista-coroa text-center p-1" v-if="id">
            <b-link @click.stop="$emit('select-shotcaller')">
              <b-img
                :src="require(`@/assets/img/${isShotcaller}.png`)"
                fluid
                alt=""
                style="max-height: 25px"
                class=""
              ></b-img>
            </b-link>
          </div>
          <div
            class="card-player-inner"
            :style="{
              background: 'url(' + require('@/assets/img/bg_player.png') + ')',
            }"
          >
            <div
              class="card-player-3"
              :style="{
                'background-image':
                  'url(' + (player_image || require('@/assets/img/ico_select_player2.png')) + ')',
              }"
            ></div>
          </div>
          <div class="tabela-time text-center p-1" v-if="team_image">
            <b-img
              :src="team_image"
              fluid
              alt=""
              style="max-height: 25px"
              class=""
            ></b-img>
          </div>
        </div>
        <div class="card-player-change" v-if="id">
          <div class="card-player-change-inner">
            <!-- <div class="text-center pb-3 pt-3">
              <b-img
                :src="require(`@/assets/icons/bold/signpost.svg`)"
                fluid
                alt=""
                class="icon-white"
                style="height: 50px"
              ></b-img>
            </div> -->
            <p class="text-white text-center mb-0">
              Trocar de Jogador
            </p>
            <p class="text-center text-3 mb-0">
              <small>Clique aqui para escolher</small>
            </p>
          </div>
        </div>
      </div>
    </b-link>
    <h5 class="mt-0 mb-1 mt-3">
      <span class="btn-2 px-2 py-1 text-uppercase">{{ playerName }}</span>
    </h5>
    <p class="mb-0 text-1 mt-3">
      <b-img
        :src="require(`@/assets/img/filter_${role}.png`)"
        fluid
        alt=""
        class="icon-sm icon-green mr-2"
      ></b-img>
      <strong class="text-uppercase">{{roleName}}</strong>
    </p>
  </div>
</template>

<script>
export default {
  name: 'FantasyMapPlayer',
  props: {
    id: {
      type: Number,
      default: null
    },
    name: {
      type: String,
      default: null
    },
    role: {
      type: String,
      default: null
    },
    team: {
      type: String,
      default: null
    },
    player_image: {
      type: String,
      default: null
    },
    team_image: {
      type: String,
      default: null
    },
    price: {
      type: String,
      default: null
    },
    points: {
      type: String,
      default: null
    },
    shotcaller: {
      type: Boolean,
      default: false
    },
    selectedRole: {
      type: String,
      default: null
    },
    isMarketOpen: {
      type: Boolean,
      default: false
    }
  },
  mounted () {
    this.callFunction()
    window.addEventListener('resize', this.resizeMap)
  },
  computed: {
    isShotcaller () {
      return this.shotcaller ? 'ico_coroa_active2' : 'ico_coroa'
    },
    playerName () {
      return this.name || 'A definir'
    },
    roleName () {
      return this.role === 'jun' ? 'jng' : this.role
    }
  },
  methods: {
    callFunction: function () {
      setTimeout(
        function () {
          this.resizeMap()
        }.bind(this),
        1000
      )
    },
    resizeMap () {
      var positions = {
        top: [600, 90],
        jun: [250, 90],
        mid: [250, 300],
        sup: [-50, 400],
        adc: [-50, 600]
      }
      var mapW = document.querySelector('#mapa').clientWidth
      var mapH = document.querySelector('#mapa').clientHeight

      if (mapW >= 800) {
        var newL = positions[this.role][1]
        var newB = positions[this.role][0]
        document.querySelector('#map-' + this.role).style.left = newL + 'px'
        document.querySelector('#map-' + this.role).style.bottom = newB + 'px'
      } else if (mapW < 800 && mapW >= 600) {
        var newL2 = (mapW * positions[this.role][1]) / 800
        var newB2 = (mapH * positions[this.role][0]) / 800 - 25
        document.querySelector('#map-' + this.role).style.left = newL2 + 'px'
        document.querySelector('#map-' + this.role).style.bottom = newB2 + 'px'
      } else {
        var newL3 = (mapW * positions[this.role][1]) / 800
        var newB3 = (mapH * positions[this.role][0]) / 800 - 50
        if (this.role === 'sup' || this.role === 'adc') {
          newB3 = newB3 - 50
        }
        if (this.role === 'top') {
          newB3 = newB3 + 25
        }
        document.querySelector('#map-' + this.role).style.left = newL3 + 'px'
        document.querySelector('#map-' + this.role).style.bottom = newB3 + 'px'
      }
    }
  }
}
</script>

<style scoped>
  .card-player-3 {
  width: 125px;
  height: 125px;
  background-position: center center;
  background-size: cover;
  padding: 5px;
  position: relative;
}

.map-player-container {
  position: relative;
}

.card-player-outer {
  position: relative;
}

.card-player-outer .tabela-time {
  position: absolute;
  right: -15px;
  bottom: -15px;
}

.card-player-outer .lista-coroa {
  position: absolute;
  top: -15px;
  left: -15px;
  background-color: #13151b;
  z-index: 1;
}

#map-top {
  position: absolute;
  bottom: 600px;
  left: 90px;
}

#map-jun {
  position: absolute;
  bottom: 250px;
  left: 90px;
}

#map-mid {
  position: absolute;
  bottom: 250px;
  left: 300px;
}

#map-sup {
  position: absolute;
  bottom: -50px;
  left: 400px;
}

#map-adc {
  position: absolute;
  bottom: -50px;
  left: 600px;
}

@media (min-width: 300px) and (max-width: 599px) {
  .card-player-container {
    left: 0;
    right: 0;
    margin: auto;
  }
  .card-player-3,
  .card-player-container {
    width: 50px;
    height: 50px;
  }
  .map-player-container .card-player-outer {
    padding: 0px;
  }
}
@media (min-width: 600px) and (max-width: 800px) {
  .card-player-3 {
    width: 65px;
    height: 65px;
  }
}
</style>
