<template>
  <div>
    <b-container fluid="xl" class="px-md-5 px-xl-0 s1">
      <b-alert show v-model="validateEmailError" variant="danger" dismissible>
        Email não verificado. Favor verifique sua caixa de emails
        <b-link class="b-link-custom mt-3 px-3 py-2 mx-auto" size="sm" :to="{name: 'Resend Email'}">Reenviar Email</b-link>
      </b-alert>
      <b-row>
        <b-col
          class="bg-ondas py-5"
          :style="{
            'background-image':
              'url(' + require('../assets/img/ondas.png') + ')'
          }"
        >
          <b-row>
            <b-col class="d-none d-xl-block col-6 text-center py-3 align-self-center px-5">
              <b-img
                :src="require('../assets/img/login1.png')"
                fluid
                alt=""
              ></b-img>
            </b-col>
            <b-col class="col-12 col-md-8 col-lg-6 offset-md-2 offset-lg-3 offset-xl-0 col-5 pl-xl-5">
              <div class="card py-5 px-4">
                <p class="pb-5 text-center">
                  <b-img
                    :src="require('../assets/img/logo.png')"
                    fluid
                    alt=""
                    class=""
                  ></b-img>
                </p>
                <h5 class="text-white ff-chaney text-center">Iniciar Sessão</h5>
                <div class="py-5 px-3">
                  <b-form
                    class="ff-inter"
                    @submit="onSubmit"
                  >
                    <b-form-group label="E-mail">
                      <b-input-group
                        prepend=" "
                        class="mb-2 mr-sm-2 mb-sm-0"
                      >
                        <span
                          ><b-img
                            :src="require('../assets/icons/outline/sms.svg')"
                            fluid
                            alt=""
                            class="icon-white icon-sm"
                          ></b-img
                        ></span>
                        <b-form-input
                          v-model="user.email"
                          placeholder="Digite seu e-mail"
                          @input="$v.user.email.$touch()"
                        ></b-form-input>
                      </b-input-group>
                      <b-form-invalid-feedback
                        :state="isEmailValid"
                        v-for="(error, i) in errors.email" :key=i
                      >
                        {{ error }}
                      </b-form-invalid-feedback>
                    </b-form-group>

                    <b-form-group label="Senha" class="mt-4">
                      <b-input-group
                        prepend=" "
                        class="mb-2 mr-sm-2 mb-sm-0"
                      >
                        <span
                          ><b-img
                            :src="require('../assets/icons/outline/lock.svg')"
                            fluid
                            alt=""
                            class="icon-white icon-sm"
                          ></b-img
                        ></span>
                        <b-form-input
                          v-model="user.password"
                          placeholder="Digite sua senha"
                          type="password"
                          @input="$v.user.password.$touch()"
                        ></b-form-input>
                      </b-input-group>
                      <b-form-invalid-feedback
                        :state="isPasswordValid"
                        v-for="(error, i) in errors.password" :key=i
                      >
                        {{ error }}
                      </b-form-invalid-feedback>
                    </b-form-group>

                    <b-row>
                      <b-col class="col-12 col-lg-6 mt-4">
                        <b-form-group>
                          <b-form-checkbox-group>
                            <b-form-checkbox v-model="user.savePassword"
                              >Manter Login</b-form-checkbox
                            >
                          </b-form-checkbox-group>
                        </b-form-group>
                      </b-col>
                      <b-col class="col-12 col-lg-6 text-center text-lg-right mt-4">
                        <b-link to="/redefinir-senha/" class="text-white"
                          >Esqueceu a senha?</b-link
                        >
                      </b-col>
                    </b-row>

                    <b-button type="submit" class="btn-3 w-100 py-3 mt-4">
                      <b-img
                        :src="require('../assets/icons/broken/login.svg')"
                        fluid
                        alt=""
                        class="icon-dark icon-sm"
                      ></b-img>
                      <strong> ENTRAR </strong>
                    </b-button>
                  </b-form>
                </div>
                <p class="text-center text-3">Ainda não tem uma conta?</p>
                <p class="text-center">
                  <b-link to="/cadastro/" class="text-white"
                    >Cadastre-se agora</b-link
                  >
                </p>
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import setsNotify from '../mixins/sets-notify.mixin'
import formErrors from '../mixins/form-errors.mixin'
import { required, email } from 'vuelidate/lib/validators'
import { mapActions } from 'vuex'

export default {
  name: 'Login',
  mixins: [setsNotify, formErrors],
  metaInfo () {
    return {
      meta: [{
        lang: 'pt-BR',
        title: 'CartoLOL - Login',
        descrition: 'Quer receber os panoramas mais assertivos dos principais torneios de esports do mundo, mostrar que é pé quente nos palpites e de quebra montar o seu próprio time e mostrar que é o melhor manager do Brasil? Acesse nossos serviços e saiba mais.',
        keywords: 'bet, aposta, call, cblol, lol, cs, league-of-legends, betclub, esports, palpites, myhint, hint, bolão, bolao, baiano, baianolol, baianolol1, fantasy, cartolol, cartola-do-lol',
        site_name: 'CartoLOL',
        type: 'website',
        robots: 'index, follow'
      }]
    }
  },
  data () {
    return {
      user: { email: '', password: '', savePassword: false },
      errors: { email: [], password: [] },
      validateEmailError: false,
      loading: false
    }
  },
  validations: {
    user: {
      email: {
        required,
        email
      },
      password: {
        required
      }
    }
  },
  watch: {
    '$v.user.email.$error' (newVal, oldVal) {
      const validEmail = 'Insira um endereço de email válido.'
      const requiredField = 'Este campo é obrigatório.'
      if (!this.$v.user.email.email) {
        this.errors.email.push(validEmail)
      } else {
        this.errors.email = this.errors.email.filter(item => item !== validEmail)
      }
      if (!this.$v.user.email.required) {
        this.errors.email.push(requiredField)
      } else {
        this.errors.email = this.errors.email.filter(item => item !== requiredField)
      }
    },
    '$v.user.password.$error' (newVal, oldVal) {
      const requiredField = 'Este campo é obrigatório.'
      if (!this.$v.user.password.required) {
        this.errors.password.push(requiredField)
      } else {
        this.errors.password = this.errors.password.filter(item => item !== requiredField)
      }
    }
  },
  computed: {
    isEmailValid () {
      return this.errors.email.length === 0 ? null : false
    },
    isPasswordValid () {
      return this.errors.password.length === 0 ? null : false
    }
  },
  methods: {
    ...mapActions('auth', ['doLogin']),
    onSubmit (event) {
      event.preventDefault()
      const fields = [this.$v.user]
      this.verifyErrorsForm(fields).then((resp) => {
        this.login()
      }).catch((err) => {
        this.setNotifyDanger(err.message)
      })
    },
    login () {
      this.loading = true
      this.doLogin(this.user)
        .then(() => {
          this.$router.push(this.$route.query.redirect || '/')
          if (this.$gtag) {
            this.$gtag.event('Login', { event_category: 'Token' })
          }
        })
        .catch(error => {
          this.loading = false
          if (error.name === 400 && this.isJson(error.message)) {
            this.errors = this.parseServerSideErros(this.errors, JSON.parse(error.message))
            return
          }
          if (error.name === 406 && error.message === 'Email não verificado. Favor verifique sua caixa de emails') {
            this.validateEmailError = true
            return
          }
          this.setNotifyDanger(`${error.message}. Error: ${error.name}.`)
          if (this.$gtag) {
            this.$gtag.event('Falha Login', { event_category: 'Token' })
          }
        })
    }
  }
}
</script>

<style>
.bg-ondas {
  background-size: cover;
  background-position: center center;
}
.s1 .card {
  background-color: #13151b;
  border: 2px solid #20232c;
  border-radius: 16px;
}
</style>
