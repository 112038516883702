import { load } from 'recaptcha-v3'
export default {
  methods: {
    async getTokenRecaptcha (action) {
      const cookieConsent = localStorage.getItem('cookieConsent') ? JSON.parse(localStorage.getItem('cookieConsent')) : null
      if (cookieConsent && cookieConsent.functional) {
        const recaptcha = await load(process.env.VUE_APP_RECAPTCHA_KEY || '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI', {
          explicitRenderParameters: {
            badge: 'bottomright'
          }
        })
        const token = await recaptcha.execute(action)
        return token
      }
      this.$bvModal.show('recaptcha-settings')
      return null
    }
  }
}
