<template>
  <div>
    <div
      class="bg-capa"
      :style="{
        'background-image': 'url(' + require('../assets/img/bg_bolao.png') + ')'
      }"
    >
      <b-container fluid="xl" class="px-md-5 px-xl-0 text-center py-5">
        <b-row>
          <b-col class="col-12 py-5">
            <h1 class="ff-chaney mb-0">
              <b-img
                :src="require('../assets/icons/bold/ticket-star.svg')"
                fluid
                alt=""
                class="mr-3 icon-white"
                style="height: 50px"
              ></b-img>
              BOLÃO
            </h1>
            <h6 class="pt-3 text-3">
              Aposte nos vencedores da rodada e ganhe prêmios incriveis!
            </h6>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div class="custom-tab-container mt-3">
      <div class="nav-custom custom-tab-borda-externa d-none d-sm-block">
        <b-tabs content-class="mt-3">
          <b-tab title="First" active></b-tab>
        </b-tabs>
      </div>
      <b-container fluid="xl" class="px-md-5 px-xl-0">
        <b-row>
          <b-col>
            <div class="nav-custom custom-tab-borda-interna">
              <b-tabs content-class="mt-3">
                <PoolSelection v-if="pool.current_round_id" :user_pool="user_pool" :matchs="matchs" :winners="winners" :rounds="rounds" :round="round" :pool="pool" @select-team="onSelectTeam" @update-round="onUpdateRound"/>
                <PoolInfo v-if="pool.info && pool.award" :pool="pool" />
              </b-tabs>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<style lang="scss">
.s1 .card {
  background-color: #13151b;
  border: 2px solid #20232c;
  border-radius: 16px;
}
body {
  overflow-x: hidden;
}
</style>

<script>
import PoolSelection from '@/components/pool/PoolSelection.vue'
import PoolInfo from '@/components/pool/PoolInfo.vue'
import PoolService from '@/services/pools/pool.js'
import setsNotify from '@/mixins/sets-notify.mixin'
import { mapGetters } from 'vuex'

export default {
  name: 'Bolao',
  components: {
    PoolSelection,
    PoolInfo
  },
  mixins: [setsNotify],
  data () {
    return {
      poolService: new PoolService(),
      user_pool: [],
      matchs: [],
      winners: [],
      rounds: [],
      round: {},
      pool: {}
    }
  },
  mounted () {
    if (!window.__PRERENDER_INJECTED) {
      if (!this.isLoggedIn) {
        this.$router.push({ path: '/login/', query: { redirect: this.$router.currentRoute.fullPath } }, () => {
          this.setNotifyDanger('Você precisa estar logado para acessar esta página.')
        })
        return
      }
      this.getPoolRound()
    }
  },
  computed: {
    ...mapGetters('auth', ['isLoggedIn', 'getUser']),
    userId () {
      if (this.getUser) {
        return this.getUser.id
      }
      return null
    },
    isWinner () {
      return this.pool.paid && this.winners.find(winner => winner.user_id === this.userId)
    },
    isLosser () {
      return this.pool.paid && !this.winners.find(winner => winner.user_id === this.userId)
    }
  },
  methods: {
    async getPoolRound () {
      this.failed = false
      try {
        const { data } = this.showFriends = await this.poolService.list()
        this.pool = data.data.sort((a, b) => { return b.event__end.localeCompare(a.event__end) })[0]
        if (this.pool.current_round_id) {
          const { data: round } = await this.poolService.get(this.pool.current_round_id)
          this.round = round.data
          this.matchs = round.included.matchs
          this.winners = round.included.winners
          this.rounds = round.included.rounds
          this.user_pool = round.included.user_pool
          if (!this.rounds.find(r => r.id === this.round.id)) {
            this.rounds.unshift({ id: this.round.id, number: this.round.number })
          }
          let currentDate = null
          for (let i = 0; i < this.matchs.length; i++) {
            if (this.matchs[i].start === currentDate) {
              this.matchs[i].start = null
            } else {
              currentDate = this.matchs[i].start
            }
          }
        }
      } catch (error) {
        this.error_message = error.message
        this.failed = true
      }
    },
    async onUpdateRound (roundId) {
      this.failed = false
      try {
        const { data: round } = await this.poolService.get(roundId)
        this.round = round.data
        this.matchs = round.included.matchs
        this.winners = round.included.winners
        this.user_pool = round.included.user_pool
        let currentDate = null
        for (let i = 0; i < this.matchs.length; i++) {
          if (this.matchs[i].start === currentDate) {
            this.matchs[i].start = null
          } else {
            currentDate = this.matchs[i].start
          }
        }
      } catch (error) {
        this.error_message = error.message
        this.failed = true
      }
    },
    onSelectTeam (teamId, match) {
      const team = {
        match: match.id,
        team: teamId === match.team1 ? match.team1 : match.team2,
        team_name: teamId === match.team1 ? match.team1__name : match.team2__name,
        team_shortcut: teamId === match.team1 ? match.team1__shortcut : match.team2__shortcut,
        team_image: teamId === match.team1 ? match.team1__image : match.team2__image,
        winner: null
      }
      this.user_pool = this.user_pool.filter(userPool => userPool.match !== match.id)
      this.user_pool.push(team)
      this.user_pool = this.user_pool.sort((a, b) => { return a.match - b.match })
    }
  }

}

</script>
