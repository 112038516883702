<template>
  <div>
    <div
      class="bg-capa"
      :style="{
        'background-image':
          'url(' + require('../assets/img/bg_perfil.png') + ')'
      }"
    >
      <b-container fluid="xl" class="px-md-5 px-xl-0">
        <b-row>
          <b-col class="mt-5 text-center mb-3">
            <div class="container-avatar-lg mr-auto ml-auto">
              <b-avatar
                :src="userInfos.image"
                :text="firstLetter(userInfos.username)"
                size="200px"
              />
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <h5 class="text-white ff-chaney text-center">{{ userInfos.name }}</h5>
            <p class="text-center text-3">{{ userInfos.username }}</p>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="col-12 col-xl-8 offset-xl-2">
            <b-row>
              <input type="file" ref="file" @change="loadImage($event)" accept="image/*" class="d-none">
              <b-col class="py-3 col-12 col-lg-6">
                <b-link class="btn btn-1 py-3 px-3 m-2 d-block btn-secondary" @click="$refs.file.click()">
                  <b-img
                    :src="require('../assets/icons/bold/export-3.svg')"
                    fluid
                    alt=""
                    class="icon-white btn-img mr-1"
                  ></b-img>
                  ALTERAR FOTO DE PERFIL
                </b-link>
              </b-col>
              <b-col class="py-3 col-12 col-lg-6">
                <b-button :to="`/perfil/`" class="btn-1 py-3 px-3 m-2 d-block">
                  <b-img
                    :src="require('../assets/icons/bold/eye.svg')"
                    fluid
                    alt=""
                    class="icon-white btn-img mr-1"
                  ></b-img>
                  VISUALIZAR SEU PERFIL
                </b-button>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div class="custom-tab-container mt-3">
      <div class="nav-custom custom-tab-borda-externa d-none d-sm-block">
        <b-tabs content-class="mt-3">
          <b-tab title="First" active></b-tab>
        </b-tabs>
      </div>
      <b-container fluid="xl" class="px-md-5 px-xl-0 s1">
        <b-row>
          <b-col>
            <div class="nav-custom custom-tab-borda-interna">
              <b-tabs content-class="mt-3">
                <b-tab active>
                  <template #title>
                    <b-img
                      :src="require('../assets/icons/broken/frame-2.svg')"
                      fluid
                      alt=""
                      class="icon-sm"
                    ></b-img>
                    Perfil Público
                  </template>
                  <div class="py-5">
                    <b-row>
                      <b-col class="col-12 pb-5">
                        <b-img
                          :src="require('../assets/icons/broken/frame-2.svg')"
                          fluid
                          alt=""
                          class="icon-sm icon-green"
                        ></b-img>
                        MEU PERFIL
                      </b-col>
                      <b-col>
                        <div class="card py-5 px-3 px-lg-5">
                          <b-form class="ff-inter" @submit="onSubmit">
                            <b-row>
                              <b-form-group
                                label="Nome"
                                class="col-12"
                              >
                                <b-input-group
                                  prepend=" "
                                  class="mb-2 mr-sm-2 mb-sm-0"
                                >
                                  <span
                                    ><b-img
                                      :src="
                                        require('../assets/icons/outline/user.svg')
                                      "
                                      fluid
                                      alt=""
                                      class="icon-white icon-sm"
                                    ></b-img
                                  ></span>
                                  <b-form-input
                                    placeholder="Digite seu nome"
                                    v-model="userdata.name"
                                    @input="$v.userdata.name.$touch()"
                                  ></b-form-input>
                                </b-input-group>
                                <b-form-invalid-feedback
                                  align="left"
                                  :state="isNameValid"
                                  v-for="(error, i) in errors.name" :key=i
                                >
                                  {{ error }}
                              </b-form-invalid-feedback>
                              </b-form-group>
                            </b-row>
                            <b-row>
                              <b-form-group
                                label="Nickname"
                                class="col-12 col-lg-6"
                              >
                                <b-input-group
                                  prepend=" "
                                  class="mb-2 mr-sm-2 mb-sm-0"
                                >
                                  <span
                                    ><b-img
                                      :src="
                                        require('../assets/icons/outline/user.svg')
                                      "
                                      fluid
                                      alt=""
                                      class="icon-white icon-sm"
                                    ></b-img
                                  ></span>
                                  <b-form-input
                                    placeholder="Digite seu nickname"
                                    v-model="userdata.username"
                                    @input="$v.userdata.username.$touch()"
                                  ></b-form-input>
                                </b-input-group>
                                <b-form-invalid-feedback
                                  align="left"
                                  :state="isUsernameValid"
                                  v-for="(error, i) in errors.username" :key=i
                                >
                                  {{ error }}
                              </b-form-invalid-feedback>
                              </b-form-group>
                              <b-form-group
                                label="Usuário da Twitch"
                                class="col-12 col-lg-6"
                              >
                                <b-input-group
                                  prepend=" "
                                  class="mb-2 mr-sm-2 mb-sm-0"
                                >
                                  <span
                                    ><b-img
                                      :src="
                                        require('../assets/icons/outline/user.svg')
                                      "
                                      fluid
                                      alt=""
                                      class="icon-white icon-sm"
                                    ></b-img
                                  ></span>
                                  <b-form-input
                                    placeholder="Digite seu usuário da twitch"
                                    v-model="userdata.twitch"
                                  ></b-form-input>
                                </b-input-group>
                                <b-form-invalid-feedback
                                  align="left"
                                  :state="isTwitchValid"
                                  v-for="(error, i) in errors.twitch" :key=i
                                >
                                  {{ error }}
                              </b-form-invalid-feedback>
                              </b-form-group>
                            </b-row>
                            <b-row>
                              <b-form-group
                                label="Status (Opcional)"
                                class="col-12 mt-4"
                              >
                                <b-form-textarea
                                  placeholder="Digite seu status"
                                  rows="5"
                                  v-model="userdata.description"
                                ></b-form-textarea>
                                <b-form-invalid-feedback
                                  align="left"
                                  :state="isDescriptionValid"
                                  v-for="(error, i) in errors.description" :key=i
                                >
                                  {{ error }}
                              </b-form-invalid-feedback>
                              </b-form-group>
                            </b-row>

                            <b-row>
                              <b-col class="col-12 col-lg-6">
                                <b-button
                                  class="btn-1 w-100 py-3 mt-4"
                                  @click="resetData"
                                >
                                  <b-img
                                    :src="
                                      require('../assets/icons/broken/broom.svg')
                                    "
                                    fluid
                                    alt=""
                                    class="icon-white icon-sm"
                                  ></b-img>
                                  <strong> LIMPAR ALTERAÇÕES </strong>
                                </b-button>
                              </b-col>
                              <b-col class="col-12 col-lg-6">
                                <b-button
                                  type="submit"
                                  class="btn-3 w-100 py-3 mt-4"
                                >
                                  <b-img
                                    :src="
                                      require('../assets/icons/broken/edit.svg')
                                    "
                                    fluid
                                    alt=""
                                    class="icon-dark icon-sm"
                                  ></b-img>
                                  <strong> ATUALIZAR PERFIL </strong>
                                </b-button>
                              </b-col>
                            </b-row>
                          </b-form>
                        </div>
                      </b-col>
                    </b-row>
                    <hr class="my-5" />
                    <b-row>
                      <b-col
                        class="col-12 col-md-7 col-lg-8 col-xl-9 pb-4 align-self-center"
                      >
                        <b-img
                          :src="require('../assets/icons/broken/star.svg')"
                          fluid
                          alt=""
                          class="icon-sm icon-green"
                        ></b-img>
                        TIMES FAVORITOS
                      </b-col>
                      <b-col class="col-12 col-md-5 col-lg-4 col-xl-3 pb-4">
                        <b-button
                          class="btn-3 py-3 ml-md-auto w-100"
                          @click="$bvModal.show('modal-scoped3')"
                        >
                          <b-img
                            :src="require('../assets/icons/broken/edit.svg')"
                            fluid
                            alt=""
                            class="icon-dark btn-img mr-1"
                          ></b-img>
                          GERENCIAR
                        </b-button>
                      </b-col>
                      <b-col class="d-flex">
                        <div class="container-time-sm m-1" v-for="(team, index) in teamInfoLOL" :key="index">
                          <b-img
                            :src="team.image"
                            fluid
                            :alt="team.name"
                            class="btn-time-sm"
                          ></b-img>
                        </div>
                      </b-col>
                    </b-row>
                    <!-- <hr class="my-5" />
                    <b-row>
                      <b-col
                        class="col-12 col-md-7 col-lg-8 col-xl-9 pb-4 align-self-center"
                      >
                        <b-img
                          :src="require('../assets/icons/broken/star.svg')"
                          fluid
                          alt=""
                          class="icon-sm icon-green"
                        ></b-img>
                        TIME FAVORITO DE CONTER STRIKE
                      </b-col>
                      <b-col class="col-12 col-md-5 col-lg-4 col-xl-3 pb-4">
                        <b-button
                          class="btn-3 py-3 ml-md-auto w-100"
                          @click="$bvModal.show('modal-scoped4')"
                        >
                          <b-img
                            :src="require('../assets/icons/broken/edit.svg')"
                            fluid
                            alt=""
                            class="icon-dark btn-img mr-1"
                          ></b-img>
                          GERENCIAR
                        </b-button>
                      </b-col>
                      <b-col class="d-flex">
                        <div class="container-time-sm m-1" v-for="(team, index) in teamInfoCS" :key="index">
                          <b-img
                            :src="team.image"
                            fluid
                            :alt="team.name"
                            class="btn-time-sm"
                          ></b-img>
                        </div>
                      </b-col>
                    </b-row> -->
                  </div>
                </b-tab>
                <b-tab>
                  <template #title>
                    <b-img
                      :src="require('../assets/icons/broken/setting-2.svg')"
                      fluid
                      alt=""
                      class="icon-sm"
                    ></b-img>
                    Configurações da Conta
                  </template>
                  <div class="py-5">
                    <b-row>
                      <b-col class="col-12 pb-5">
                        <b-img
                          :src="require('../assets/icons/broken/frame-2.svg')"
                          fluid
                          alt=""
                          class="icon-sm icon-green"
                        ></b-img>
                        MEU PERFIL
                      </b-col>
                      <b-col>
                        <div class="card py-5 px-3 px-lg-5">
                          <b-form class="ff-inter">
                            <!-- <b-row>
                              <b-form-group
                                label="Email"
                                class="col-12"
                              >
                                <b-input-group
                                  prepend=" "
                                  class="mb-2 mr-sm-2 mb-sm-0"
                                >
                                  <span
                                    ><b-img
                                      :src="
                                        require('../assets/icons/outline/sms.svg')
                                      "
                                      fluid
                                      alt=""
                                      class="icon-white icon-sm"
                                    ></b-img
                                  ></span>
                                  <b-form-input
                                    placeholder="Digite seu e-mail"
                                    disabled
                                  ></b-form-input>
                                </b-input-group>
                              </b-form-group>
                            </b-row> -->

                            <b-row>
                              <b-col class="col-12">
                                <b-button
                                  v-b-modal.modal-scoped
                                  class="btn-1 w-100 py-3 mt-4"
                                >
                                  <b-img
                                    :src="
                                      require('../assets/icons/broken/lock.svg')
                                    "
                                    fluid
                                    alt=""
                                    class="icon-white icon-sm"
                                  ></b-img>
                                  <strong> ALTERAR SENHA </strong>
                                </b-button>
                              </b-col>
                              <!-- <b-col class="col-12 col-lg-6">
                                <b-button
                                  type="submit"
                                  class="btn-3 w-100 py-3 mt-4"
                                  disabled
                                  v-b-tooltip.hover
                                  title="No momento não é possível alterar seu e-mail"
                                >
                                  <b-img
                                    :src="
                                      require('../assets/icons/broken/edit.svg')
                                    "
                                    fluid
                                    alt=""
                                    class="icon-dark icon-sm"
                                  ></b-img>
                                  <strong> ATUALIZAR CONTA </strong>
                                </b-button>
                              </b-col> -->
                            </b-row>
                            <b-row>
                              <b-col class="col-12">
                                <b-button
                                variant="danger"
                                  v-b-modal.modal-scoped2
                                  class="btn-4 w-100 py-3 mt-4"
                                >
                                  <b-img
                                    :src="
                                      require('../assets/icons/broken/trash.svg')
                                    "
                                    fluid
                                    alt=""
                                    class="icon-white icon-sm"
                                  ></b-img>
                                  <strong> EXCLUIR CONTA </strong>
                                </b-button>
                              </b-col>
                            </b-row>
                          </b-form>
                        </div>
                      </b-col>
                    </b-row>
                  </div>
                </b-tab>
              </b-tabs>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <b-modal id="modal-scoped" size="lg">
      <template #modal-title>
        <h6 class="ff-chaney mb-0">
          <b-img
            :src="require('../assets/icons/broken/lock.svg')"
            fluid
            alt=""
            class="icon-green icon-sm"
          ></b-img>
          Mudar senha
        </h6>
      </template>
      <div class="d-block">
        <div class="">
          <b-form class="ff-inter">
            <b-form-group label="Senha Atual" class="mt-4">
              <b-input-group prepend=" " class="mb-2 mr-sm-2 mb-sm-0">
                <span
                  ><b-img
                    :src="require('../assets/icons/outline/lock.svg')"
                    fluid
                    alt=""
                    class="icon-white icon-sm"
                  ></b-img
                ></span>
                <b-form-input
                  placeholder="Digite sua senha atual"
                  type="password"
                  v-model="user2.oldPassword"
                  @input="$v.user2.oldPassword.$touch()"
                ></b-form-input>
              </b-input-group>
              <b-form-invalid-feedback
                :state="isOldPasswordValid"
                v-for="(error, i) in errors2.oldPassword" :key=i
              >
                {{ error }}
              </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group label="Nova Senha" class="mt-4">
              <b-input-group prepend=" " class="mb-2 mr-sm-2 mb-sm-0">
                <span
                  ><b-img
                    :src="require('../assets/icons/outline/lock.svg')"
                    fluid
                    alt=""
                    class="icon-white icon-sm"
                  ></b-img
                ></span>
                <b-form-input
                  placeholder="Digite sua nova senha"
                  type="password"
                  v-model="user2.password"
                  @input="$v.user2.password.$touch()"
                ></b-form-input>
              </b-input-group>
              <b-form-invalid-feedback
                :state="isPasswordValid"
                v-for="(error, i) in errors2.password" :key=i
              >
                {{ error }}
              </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group label="Confirme a Nova Senha" class="mt-4">
              <b-input-group prepend=" " class="mb-2 mr-sm-2 mb-sm-0">
                <span
                  ><b-img
                    :src="require('../assets/icons/outline/lock.svg')"
                    fluid
                    alt=""
                    class="icon-white icon-sm"
                  ></b-img
                ></span>
                <b-form-input
                  placeholder="Confirme sua nova senha"
                  type="password"
                  v-model="user2.passwordConfirmation"
                  @input="$v.user2.passwordConfirmation.$touch()"
                ></b-form-input>
              </b-input-group>
              <b-form-invalid-feedback
                :state="isPasswordConfirmationValid"
                v-for="(error, i) in errors2.passwordConfirmation" :key=i
              >
                {{ error }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-form>
        </div>
      </div>
      <template #modal-footer="{ hide }">
        <b-container>
          <b-row>
            <b-col class="col-12 col-lg-6 py-3 py-lg-0 pl-lg-0">
              <b-button class="btn-1 w-100 py-3" @click="hide()">
                <b-img
                  :src="require('../assets/icons/broken/close-square.svg')"
                  fluid
                  alt=""
                  class="icon-white icon-sm"
                ></b-img>
                <strong> CANCELAR </strong>
              </b-button>
            </b-col>
            <b-col class="col-12 col-lg-6 py-3 py-lg-0 pr-lg-0">
              <b-button class="btn-3 w-100 py-3" @click="onSubmit2">
                <b-img
                  :src="require('../assets/icons/broken/directbox-receive.svg')"
                  fluid
                  alt=""
                  class="icon-dark icon-sm"
                ></b-img>
                <strong> SALVAR </strong>
              </b-button>
            </b-col>
          </b-row>
        </b-container>
      </template>
    </b-modal>
    <b-modal id="modal-scoped2" size="lg">
      <template #modal-title>
        <h6 class="ff-chaney mb-0">
          <b-img
            :src="require('../assets/icons/broken/lock.svg')"
            fluid
            alt=""
            class="icon-green icon-sm"
          ></b-img>
          Excluir sua conta
        </h6>
      </template>
      <div class="d-block">
        <div class="">
          <p class="text-center text-3">
            Tem certeza que deseja excluir sua conta?
          </p>
          <div class="container-avatar-lg mr-auto ml-auto">
            <b-avatar
                :src="userInfos.image"
                :text="firstLetter(userInfos.username)"
                size="200px"
              />
          </div>
          <p class="text-center">{{ userInfos.name }}</p>
          <p class="text-center text-3">
            <small>{{ userInfos.username }}</small>
          </p>
        </div>
      </div>
      <template #modal-footer="{ hide }">
        <b-container>
          <b-row>
            <b-col class="col-12 col-lg-6 py-3 py-lg-0 pl-lg-0">
              <b-button class="btn-1 w-100 py-3" @click="hide()">
                <b-img
                  :src="require('../assets/icons/broken/close-square.svg')"
                  fluid
                  alt=""
                  class="icon-white icon-sm"
                ></b-img>
                <strong> CANCELAR </strong>
              </b-button>
            </b-col>
            <b-col class="col-12 col-lg-6 py-3 py-lg-0 pr-lg-0">
              <b-button class="btn-5 w-100 py-3" @click="deleteAccount">
                <b-img
                  :src="require('../assets/icons/broken/trash.svg')"
                  fluid
                  alt=""
                  class="icon-white icon-sm"
                ></b-img>
                <strong> EXCLUIR CONTA </strong>
              </b-button>
            </b-col>
          </b-row>
        </b-container>
      </template>
    </b-modal>
    <b-modal id="modal-scoped3" scrollable no-close-on-esc no-close-on-backdrop hide-header-close size="lg">
      <template #modal-title>
        <h6 class="ff-chaney mb-0">
          <b-img
            :src="require('../assets/icons/broken/star.svg')"
            fluid
            alt=""
            class="icon-green icon-sm"
          ></b-img>
          TIME FAVORITO DE LOL
        </h6>
      </template>
      <div class="d-block">
        <div class="">
          <b-form class="ff-inter">
            <b-form-group label="" class="mt-2">
              <b-input-group prepend=" " class="mb-2 mr-sm-2 mb-sm-0">
                <span
                  ><b-img
                    :src="require('../assets/icons/outline/search-normal.svg')"
                    fluid
                    alt=""
                    class="icon-white icon-sm"
                  ></b-img
                ></span>
                <b-form-input
                  placeholder="Pesquise por Nome ou Sigla"
                  type="text"
                  v-model="searchLOL"
                ></b-form-input>
              </b-input-group>
            </b-form-group>
            <b-row>
              <b-col class="col-6 col-md-4 col-lg-3 col-xl-2" v-for="(teamLOL, index) in filterTeamLOL" :key="index">
                <b-form-checkbox class="custom-checkbox  hide-box" :class="team.includes(teamLOL.id) ? 'active' : ''" @change="selectTeam(teamLOL.id)">
                  <div class="container-time">
                    <b-img
                    :src="teamLOL.image"
                    fluid
                    alt=""
                  ></b-img>
                  </div>
                  <p class="pt-3 text-center">
                    {{ teamLOL.name }}
                  </p>
                </b-form-checkbox>
              </b-col>
            </b-row>
          </b-form>
        </div>
      </div>
      <template #modal-footer>
        <b-container>
          <b-row>
            <b-col class="col-12 col-lg-6 py-3 py-lg-0 pl-lg-0">
              <b-button class="btn-1 w-100 py-3" @click="cancelTeam()">
                <b-img
                  :src="require('../assets/icons/broken/close-square.svg')"
                  fluid
                  alt=""
                  class="icon-white icon-sm"
                ></b-img>
                <strong> CANCELAR </strong>
              </b-button>
            </b-col>
            <b-col class="col-12 col-lg-6 py-3 py-lg-0 pr-lg-0">
              <b-button class="btn-3 w-100 py-3" @click="saveTeam()">
                <b-img
                  :src="require('../assets/icons/broken/tick-square.svg')"
                  fluid
                  alt=""
                  class="icon-dark icon-sm"
                ></b-img>
                <strong> CONFIRMAR ALTERAÇÃO </strong>
              </b-button>
            </b-col>
          </b-row>
        </b-container>
      </template>
    </b-modal>
     <b-modal id="modal-scoped4" scrollable no-close-on-esc no-close-on-backdrop hide-header-close size="lg">
      <template #modal-title>
        <h6 class="ff-chaney mb-0">
          <b-img
            :src="require('../assets/icons/broken/star.svg')"
            fluid
            alt=""
            class="icon-green icon-sm"
          ></b-img>
          TIME FAVORITO DE COUNTER STRIKE
        </h6>
      </template>
      <div class="d-block">
        <div class="">
          <b-form class="ff-inter">
            <b-form-group label="" class="mt-2">
              <b-input-group prepend=" " class="mb-2 mr-sm-2 mb-sm-0">
                <span
                  ><b-img
                    :src="require('../assets/icons/outline/search-normal.svg')"
                    fluid
                    alt=""
                    class="icon-white icon-sm"
                  ></b-img
                ></span>
                <b-form-input
                  placeholder="Pesquise por Nome ou Sigla"
                  v-model="searchCS"
                  type="text"
                ></b-form-input>
              </b-input-group>
            </b-form-group>
            <b-row>
              <b-col class="col-6 col-md-4 col-lg-3 col-xl-2" v-for="(teamCS, index) in filterTeamCS" :key="index">
                <b-form-checkbox class="custom-checkbox  hide-box" :class="team.includes(teamCS.id) ? 'active' : ''" @change="selectTeam(teamCS.id)">
                  <div class="container-time">
                    <b-img
                    :src="teamCS.image"
                    fluid
                    :alt="teamCS.name"
                  ></b-img>
                  </div>
                  <p class="pt-3 text-center">
                    {{ teamCS.name }}
                  </p>
                </b-form-checkbox>
              </b-col>
            </b-row>
          </b-form>
        </div>
      </div>
      <template #modal-footer>
        <b-container>
          <b-row>
            <b-col class="col-12 col-lg-6 py-3 py-lg-0 pl-lg-0">
              <b-button class="btn-1 w-100 py-3" @click="cancelTeam()">
                <b-img
                  :src="require('../assets/icons/broken/close-square.svg')"
                  fluid
                  alt=""
                  class="icon-white icon-sm"
                ></b-img>
                <strong> CANCELAR </strong>
              </b-button>
            </b-col>
            <b-col class="col-12 col-lg-6 py-3 py-lg-0 pr-lg-0">
              <b-button class="btn-3 w-100 py-3" @click="saveTeam()">
                <b-img
                  :src="require('../assets/icons/broken/tick-square.svg')"
                  fluid
                  alt=""
                  class="icon-dark icon-sm"
                ></b-img>
                <strong> CONFIRMAR ALTERAÇÃO </strong>
              </b-button>
            </b-col>
          </b-row>
        </b-container>
      </template>
    </b-modal>
    <b-modal
      id="cropper-modal"
      hide-footer
      hide-header
      body-class="p-0"
      centered
    >
      <wrapper>
        <cropper
          ref="cropper"
          class="circle-cropper"
          stencil-component="circle-stencil"
          :src="tmpImage.src"
          :stencil-props="{
            handlers: {},
            movable: false,
            scalable: false,
            aspectRatio: 1,
          }"
          :resize-image="{
            adjustStencil: false
          }"
          image-restriction="stencil"
          :canvas="{
            minHeight: 400,
            minWidth: 400,
            maxHeight: 400,
            maxWidth: 400,
          }"
        />
        <vertical-buttons>
          <square-button title="Flip Horizontal" @click="flip(true, false)">
            <b-icon-input-cursor/>
          </square-button>
          <square-button title="Flip Vertical" @click="flip(false, true)">
            <b-icon-input-cursor rotate="90"/>
          </square-button>
          <square-button title="Rotate Clockwise" @click="rotate(90)">
            <b-icon-arrow-clockwise/>
          </square-button>
          <square-button title="Rotate Counter-Clockwise" @click="rotate(-90)">
            <b-icon-arrow-counterclockwise/>
          </square-button>
          <square-button title="Upload" @click="uploadImage()">
            <b-icon-check/>
          </square-button>
          <square-button title="Cancel" @click="hideModal()">
            <b-icon-x/>
          </square-button>
        </vertical-buttons>
      </wrapper>
    </b-modal>
  </div>
</template>

<style lang="scss">
.s1 .card {
  background-color: #13151b;
  border: 2px solid #20232c;
  border-radius: 16px;
}
</style>

<script>
import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css'
import Wrapper from '@/components/cropper/Wrapper'
import VerticalButtons from '@/components/cropper/VerticalButtons'
import SquareButton from '@/components/cropper/SquareButton'
import UserInfo from '../services/users/user.js'
import setsNotify from '../mixins/sets-notify.mixin'
import formErrors from '../mixins/form-errors.mixin'
import fileUpload from '../mixins/upload-file.mixin'
import { required, sameAs } from 'vuelidate/lib/validators'
import { mapGetters, mapActions } from 'vuex'

function getMimeType (file, fallback = null) {
  const byteArray = (new Uint8Array(file)).subarray(0, 4)
  let header = ''
  for (let i = 0; i < byteArray.length; i++) {
    header += byteArray[i].toString(16)
  }
  switch (header) {
    case '89504e47':
      return 'image/png'
    case '47494638':
      return 'image/gif'
    case 'ffd8ffe0':
    case 'ffd8ffe1':
    case 'ffd8ffe2':
    case 'ffd8ffe3':
    case 'ffd8ffe8':
      return 'image/jpeg'
    default:
      return fallback
  }
}

export default {
  name: 'AlterarPerfil',
  mixins: [setsNotify, formErrors, fileUpload],
  components: {
    Cropper,
    Wrapper,
    VerticalButtons,
    SquareButton
  },
  metaInfo () {
    return {
      meta: [{
        lang: 'pt-BR',
        title: 'CartoLOL - Mudar perfil',
        descrition: 'Quer receber os panoramas mais assertivos dos principais torneios de esports do mundo, mostrar que é pé quente nos palpites e de quebra montar o seu próprio time e mostrar que é o melhor manager do Brasil? Acesse nossos serviços e saiba mais.',
        keywords: 'bet, aposta, call, cblol, lol, cs, league-of-legends, betclub, esports, palpites, myhint, hint, bolão, bolao, baiano, baianolol, baianolol1, fantasy, cartolol, cartola-do-lol',
        site_name: 'CartoLOL',
        type: 'website',
        robots: 'index, follow'
      }]
    }
  },
  data: () => ({
    failed: false,
    searchLOL: '',
    searchCS: '',
    busy: false,
    delPerfil: false,
    userdata: { name: '', username: '', description: '', twitch: '' },
    errors: { name: [], username: [], image: [], description: [], twitch: [] },
    loading: true,
    name: '',
    team: [],
    userInfos: [],
    userSocial: [],
    user: new UserInfo(),
    tmpImage: {
      src: null,
      type: null,
      name: null
    },
    localImage: null,
    user2: {
      id: null,
      oldPassword: '',
      password: '',
      passwordConfirmation: ''
    },
    errors2: { oldPassword: [], password: [], passwordConfirmation: [] }
  }),
  validations: {
    userdata: {
      name: {
        required
      },
      username: {
        required
      }
    },
    user2: {
      oldPassword: {
        required
      },
      password: {
        required
      },
      passwordConfirmation: {
        required,
        sameAsPassword: sameAs('password')
      }
    }
  },
  head: {
    title: 'CartoLOL - Alterar Perfil'
  },
  watch: {
    '$v.userdata.name.$error' (newVal, oldVal) {
      const requiredField = 'Este campo é obrigatório.'
      if (!this.$v.userdata.name.required) {
        this.errors.name.push(requiredField)
      } else {
        this.errors.name = this.errors.name.filter(item => item !== requiredField)
      }
    },
    '$v.userdata.username.$error' (newVal, oldVal) {
      const requiredField = 'Este campo é obrigatório.'
      if (!this.$v.userdata.username.required) {
        this.errors.username.push(requiredField)
      } else {
        this.errors.username = this.errors.username.filter(item => item !== requiredField)
      }
    },
    '$v.user2.oldPassword.$error' (newVal, oldVal) {
      const requiredField = 'Este campo é obrigatório.'
      this.errors2.oldPassword = this.errors2.oldPassword.filter(item => item !== requiredField)
      if (!this.$v.user2.oldPassword.required) {
        this.errors2.oldPassword.push(requiredField)
      }
    },
    '$v.user2.password.$error' (newVal, oldVal) {
      const requiredField = 'Este campo é obrigatório.'
      this.errors2.password = this.errors2.password.filter(item => item !== requiredField)
      if (!this.$v.user2.password.required) {
        this.errors2.password.push(requiredField)
      }
    },
    '$v.user2.passwordConfirmation.$error' (newVal, oldVal) {
      const requiredField = 'Este campo é obrigatório.'
      const sameAsPassword = 'As senhas devem ser iguais.'
      this.errors2.passwordConfirmation = this.errors2.passwordConfirmation.filter(item => item !== requiredField)
      if (!this.$v.user2.passwordConfirmation.required) {
        this.errors2.passwordConfirmation.push(requiredField)
      }
      this.errors2.passwordConfirmation = this.errors2.passwordConfirmation.filter(item => item !== sameAsPassword)
      if (!this.$v.user2.passwordConfirmation.sameAsPassword) {
        this.errors2.passwordConfirmation.push(sameAsPassword)
      }
    }
  },
  computed: {
    ...mapGetters('auth', ['getUser', 'isLoggedIn']),
    userID () {
      return this.getUser.id
    },
    UniqueGames () {
      const unique = []
      this.userSocial.allTeams.forEach(value => {
        if (unique.filter((game) => { return game.name === value.game_name }).length === 0) {
          const name = value.game_name
          const myTeam = this.teamInfo(value.game_name)
          const teams = this.teamList(value.game_name)
          const gameEnable = this.teamInfo(value.game_name).length > 0
          unique.push({ name, teams, myTeam, gameEnable })
        }
      })
      return unique
    },
    isOldPasswordValid () {
      return this.errors2.oldPassword.length === 0 ? null : false
    },
    isPasswordValid () {
      return this.errors2.password.length === 0 ? null : false
    },
    isPasswordConfirmationValid () {
      return this.errors2.passwordConfirmation.length === 0 ? null : false
    },
    isNameValid () {
      return this.errors.name.length === 0 ? null : false
    },
    isTwitchValid () {
      return this.errors.twitch.length === 0 ? null : false
    },
    isDescriptionValid () {
      return this.errors.description.length === 0 ? null : false
    },
    isUsernameValid () {
      return this.errors.username.length === 0 ? null : false
    },
    teamInfoLOL () {
      if (this.userSocial.favoriteTeams === undefined) {
        return []
      }
      const Team = this.userSocial.favoriteTeams.filter((team) => { return team.game === 'League of Legends' })
      return Team
    },
    teamInfoCS () {
      if (this.userSocial.favoriteTeams === undefined) {
        return []
      }
      const Team = this.userSocial.favoriteTeams.filter((team) => { return team.game === 'Counter Strike Global Offensive' })
      return Team
    },
    filterTeamLOL () {
      if (this.searchLOL === '') {
        return this.teamList('League of Legends')
      }
      return this.teamList('League of Legends').filter(item => {
        return item.name.toLowerCase().includes(this.searchLOL.toLowerCase()) || item.shortcut.toLowerCase().includes(this.searchLOL.toLowerCase())
      })
    },
    filterTeamCS () {
      if (this.searchCS === '') {
        return this.teamList('Counter Strike Global Offensive')
      }
      return this.teamList('Counter Strike Global Offensive').filter(item => {
        return item.name.toLowerCase().includes(this.searchCS.toLowerCase()) || item.shortcut.toLowerCase().includes(this.searchCS.toLowerCase())
      })
    }
  },
  methods: {
    ...mapActions('auth', ['doLogout']),
    onSubmit (event) {
      event.preventDefault()
      const fields = [this.$v.userdata]
      this.verifyErrorsForm(fields).then((resp) => {
        this.editProfile()
      }).catch((err) => {
        this.setNotifyDanger(err.message)
      })
    },
    async getUserInfo () {
      this.failed = false
      this.loading = true
      try {
        const { data } = await this.user.get(this.userID)
        this.userInfos = data.data
        this.userSocial = data.included
        this.name = this.userInfos.username === null ? 'User_' + this.userInfos.id : this.userInfos.username
        this.userdata = {
          name: this.userInfos.name,
          username: this.userInfos.username,
          description: this.userInfos.description,
          twitch: this.userInfos.twitch
        }
        this.team = this.userSocial.favoriteTeams.map(team => { return team.team_id })
        this.loading = false
      } catch (error) {
        this.failed = true
        this.error_message = error.message
      }
    },
    async editProfile () {
      this.busy = true
      try {
        const enviar = {
          name: this.userdata.name,
          username: this.userdata.username,
          newsLeller: this.userInfos.newsLeller,
          description: this.userdata.description,
          twitch: this.userdata.twitch,
          teams: this.team,
          user_id: this.userID
        }
        await this.user.update(enviar)
        this.name = this.userdata.username
        this.setNotifySuccess('Dados atualizados com  sucesso!')
        if (this.$gtag) {
          this.$gtag.event('Editar Perfil', { event_category: 'User' })
        }
      } catch (error) {
        if (error.name === 400 && this.isJson(error.message)) {
          this.errors = this.parseServerSideErros(this.errors, JSON.parse(error.message))
          return
        }
        this.setNotifyDanger(`${error.message}. Error: ${error.name}.`)
      } finally {
        this.busy = false
      }
    },
    firstLetter (username) {
      if (username === undefined || username === null) {
        return 'U'
      }
      return username.substring(0, 1).toUpperCase()
    },
    teamList (game) {
      if (this.userSocial.allTeams === undefined) {
        return []
      }
      const Teams = this.userSocial.allTeams.filter((team) => { return team.game_name === game })
      return Teams.sort((a, b) => { return a.name.localeCompare(b.name) })
    },
    teamInfo (game) {
      if (this.userSocial.favoriteTeams === undefined) {
        return []
      }
      const Team = this.userSocial.favoriteTeams.filter((team) => { return team.game === game })
      return Team
    },
    resetData () {
      this.userdata.name = this.userInfos.name
      this.userdata.username = this.userInfos.username
      this.userdata.description = this.userInfos.description
      this.userdata.twitch = this.userInfos.twitch
    },
    cancelTeam () {
      this.team = this.userSocial.favoriteTeams.map(team => { return team.team_id })
      this.$bvModal.hide('modal-scoped3')
      this.$bvModal.hide('modal-scoped4')
    },
    saveTeam () {
      this.resetData()
      this.editProfile()
      this.userSocial.favoriteTeams = this.userSocial.allTeams.filter((t) => { return this.team.includes(t.id) }).map((t) => ({ ...t, team_id: t.id, game: t.game_name }))
      this.$bvModal.hide('modal-scoped3')
      this.$bvModal.hide('modal-scoped4')
    },
    remove (index) {
      this.team.splice(index, 1, '')
    },
    haveFavoriteTeam (data) {
      return this.userSocial.allTeams.find(team => team.name === data)
    },
    teamImage (data) {
      const team = this.userSocial.allTeams.find(team => team.name === data)
      return team ? team.image : null
    },
    selectTeam (team) {
      if (this.team.includes(team)) {
        this.team.splice(this.team.indexOf(team), 1)
      } else {
        this.team.push(team)
      }
    },
    simpleGameName (name) {
      if (name === 'Counter Strike Global Offensive') {
        return 'CS: Global Offensive'
      }
      return name
    },
    async deleteAccount () {
      this.delPerfil = true
      try {
        await this.user.deleteAccount(this.userID)
        this.doLogout().then(() => {
          window.scrollTo(0, 0)
          this.$router.push('/')
        }).catch(error => {
          this.setNotifyDanger(`Não foi possível excluir conta. Error: ${error}`)
        })
      } catch (error) {
        this.delPerfil = false
      }
    },
    onSubmit2 (event) {
      event.preventDefault()
      const fields = [this.$v.user2]
      this.verifyErrorsForm(fields).then((resp) => {
        this.updatePassword()
      }).catch((err) => {
        this.setNotifyDanger(err.message)
      })
    },
    async updatePassword () {
      console.log('here')
      this.loading = true
      try {
        this.user2.id = this.getUser.id
        await this.user.partialUpdate(this.user2)
        this.$bvModal.hide('modal-scoped')
        this.setNotifySuccess('Senha alterada com sucesso.')
        this.user2 = { id: null, oldPassword: '', password: '', passwordConfirmation: '' }
        this.$v.$reset()
        if (this.$gtag) {
          this.$gtag.event('Alterar Senha', { event_category: 'User' })
        }
      } catch (error) {
        if (error.name === 400 && this.isJson(error.message)) {
          this.errors2 = this.parseServerSideErros(this.errors2, JSON.parse(error.message))
          return
        }
        this.setNotifyDanger(`${error.message}. Error: ${error.name}.`)
      } finally {
        this.loading = false
      }
    },
    // upload iamge
    loadImage (event) {
      const { files } = event.target
      if (files && files[0]) {
        if (this.tmpImage.src) {
          URL.revokeObjectURL(this.tmpImage.src)
        }
        const blob = URL.createObjectURL(files[0])
        const reader = new FileReader()
        reader.onload = (e) => {
          this.tmpImage = {
            src: blob,
            type: getMimeType(e.target.result, files[0].type),
            name: files[0].name
          }
          this.$bvModal.show('cropper-modal')
        }
        reader.readAsArrayBuffer(files[0])
      }
    },
    hideModal () {
      this.$bvModal.hide('cropper-modal')
      this.$refs.file.value = null
    },
    flip (x, y) {
      this.$refs.cropper.flip(x, y)
    },
    rotate (angle) {
      this.$refs.cropper.rotate(angle)
    },
    uploadImage () {
      const { canvas, coordinates } = this.$refs.cropper.getResult()
      this.tmpImage.name = `${coordinates.width}_${coordinates.height}_${this.tmpImage.name}`
      if (canvas) {
        const form = new FormData()
        canvas.toBlob(async blob => {
          const base64 = await this.blobToBase64(blob)
          form.append('image_name', this.tmpImage.name)
          form.append('image_data', base64)
          try {
            const { data } = await this.user.picture(form, this.userID)
            this.hideModal()
            this.localImage = data.data
            // update user localstorage
            const user = window.localStorage.getItem('user') ? JSON.parse(window.localStorage.getItem('user')) : null
            if (user) {
              user.image = data.data
              window.localStorage.setItem('user', JSON.stringify(user))
              this.$store.commit('authSuccess', user)
            }
            // update GET request cache
            const cache = await this.user.getItem(`/user/${this.userID}/`)
            if (cache) {
              cache.data.image = data.data
              await this.user.updateItem(`/user/${this.userID}/`, cache)
            }
          } catch (error) {
            if (error.name === 400 && this.isJson(error.message)) {
              this.errors = this.parseServerSideErros(this.errors, JSON.parse(error.message))
              this.errors.image.forEach(msg => {
                this.setNotifyDanger(`${msg}. Error: ${error.name}.`)
              })
              return
            }
            this.setNotifyDanger(`${error.message}. Error: ${error.name}.`)
          }
        }, this.tmpImage.type)
      }
    }
  },
  mounted () {
    if (!window.__PRERENDER_INJECTED) {
      if (!this.isLoggedIn) {
        this.$router.push({ path: '/login/', query: { redirect: this.$router.currentRoute.fullPath } }, () => {
          this.setNotifyDanger('Você precisa estar logado para acessar esta página.')
        })
        return
      }
      this.getUserInfo()
    }
  }
}
</script>
