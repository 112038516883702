<template>
  <b-col class="col-6 col-md-4 col-lg-3 mt-3">
    <div class="card-player-container p-3">
      <b-row>
        <div class="col-12 col-lg">
          <div>
            <b-media right-align vertical-align="center">
              <template #aside>
                <div class="tabela-time text-center p-1">
                  <b-img
                    :src="team.team_image"
                    fluid
                    alt=""
                    style="max-height: 50px"
                    class=""
                  ></b-img>
                </div>
              </template>
              <h5 class="mt-0 mb-1 text-uppercase"> {{ team.team_name }}</h5>
              <div class="result-label">
                <p class="mb-0" :class="labelClass">
                <small>JOGO {{ match }}</small><br />
                {{ labelText }}
              </p>
              </div>
            </b-media>
          </div>
        </div>
      </b-row>
    </div>
  </b-col>
</template>
<script>
export default {
  name: 'PoolUserTeam',
  props: {
    team: {
      type: Object,
      required: true
    },
    isPaid: {
      type: Boolean,
      default: false
    },
    matchs: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    match () {
      return this.matchs.findIndex(match => match.id === this.team.match) + 1
    },
    labelClass () {
      if (!this.isPaid) {
        return 'text-3'
      }
      if (this.team.winner === this.team.team) {
        return 'text-1'
      }
      return 'text-2'
    },
    labelText () {
      if (!this.isPaid) {
        return 'Vencedor'
      }
      if (this.team.winner === this.team.team) {
        return 'Venceu'
      }
      return 'Perdeu'
    }
  }
}
</script>
