<template>
  <!-- TAB Meu time -->
  <b-tab @click="selectionView = 0">
    <template #title>
      <b-img
        :src="require('@/assets/icons/broken/people.svg')"
        fluid
        alt=""
        class="icon-sm mr-3"
      ></b-img>
      Meu Time
    </template>
    <div class="py-5">
      <b-row>
        <b-col class="col-12 col-xl-8 offset-xl-2">
          <b-row>
            <b-col class="col-12 col-md-4 text-center text-md-left py-3">
              <b-link class="text-white" v-if="hasPreviousRound" @click="$emit('update-round', hasPreviousRound.id)">
                <b-img
                  :src="require('@/assets/icons/bold/arrow-square-left.svg')"
                  fluid
                  alt=""
                  class="icon-sm icon-white"
                ></b-img>
                RODADA {{ hasPreviousRound.number }}
              </b-link>
            </b-col>
            <b-col class="col-12 col-md-4 text-center text-md-center py-3">
              <h3 class="mb-0">
                <span class="destaque-green"> RODADA {{ round.number }} </span>
              </h3>
            </b-col>
            <b-col class="col-12 col-md-4 text-center text-md-right py-3">
              <b-link class="text-white" v-if="hasNextRound" @click="$emit('update-round', hasNextRound.id)">
                RODADA {{ hasNextRound.number }}
                <b-img
                  :src="require('@/assets/icons/bold/arrow-square-right.svg')"
                  fluid
                  alt=""
                  class="icon-sm icon-white"
                ></b-img>
              </b-link>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row class="mt-5">
        <b-col class="col-12">
          <b-img
            :src="require('@/assets/icons/broken/edit.svg')"
            fluid
            alt=""
            class="icon-sm icon-green mr-3"
          ></b-img>
          SELEÇÃO DE TIME
        </b-col>
        <b-col class="col-12">
          <div class="tab-tipo-mapa float-right">
            <b-tabs pills card align="right" class="pt-3" id="team-selection" v-model="selectionView">
              <FantasyTeamSelectionCard :selectedRole="selectedRole" :isMarketOpen="isMarketOpen" :wallet="wallet" :round="round" :team="team" :share="share" @select-role="selectRole" @select-shotcaller="selectShotcaller" @clear-team="clearTeam"/>
              <FantasyTeamSelectionList :selectedRole="selectedRole" :isMarketOpen="isMarketOpen" :wallet="wallet" :round="round" :team="team" :share="share" @select-role="selectRole" @select-shotcaller="selectShotcaller" @clear-team="clearTeam"/>
              <FantasyTeamSelectionMap :selectedRole="selectedRole" :isMarketOpen="isMarketOpen" :wallet="wallet" :round="round" :team="team" :share="share" @select-role="selectRole" @select-shotcaller="selectShotcaller" @clear-team="clearTeam"/>
            </b-tabs>
          </div>
        </b-col>
      </b-row>
      <FantasyMarket id="market-players" v-if="isMarketOpen && selectedRole" :round_players="round_players" :team="team" :weigths="weigths"  :selectedRole="selectedRole" @update-team="$emit('update-team', $event)"/>
      <FantasyMarketClosed v-if="round.point_paid" :round_players="round_players" :weigths="weigths"  :round="round" />
    </div>
    <FantasySlide :selectedRole="selectedRole" :isMarketOpen="isMarketOpen" :wallet="wallet" :round="round" :team="team" :share="share" @select-role="selectRole" @select-shotcaller="selectShotcaller" @clear-team="clearTeam" />
  </b-tab>
</template>

<script>
import FantasyMarket from './FantasyMarket.vue'
import FantasyMarketClosed from './FantasyMarketClosed.vue'
import FantasyTeamSelectionCard from './FantasyTeamSelectionCard.vue'
import FantasyTeamSelectionList from './FantasyTeamSelectionList.vue'
import FantasyTeamSelectionMap from './FantasyTeamSelectionMap.vue'
import FantasySlide from '@/components/fantasy/FantasySlide.vue'

export default {
  components: { FantasyTeamSelectionCard, FantasyMarket, FantasyMarketClosed, FantasyTeamSelectionList, FantasyTeamSelectionMap, FantasySlide },
  name: 'FantasyTeamSelection',
  model: {
    prop: 'team',
    event: 'update-team'
  },
  props: {
    team: {
      type: Object,
      default: () => ({ top_id: null, jun_id: null, mid_id: null, adc_id: null, sup_id: null, coach_id: null, shotcaller_id: null })
    },
    round: {
      type: Object,
      default: () => ({ id: 0, number: 0 })
    },
    rounds: {
      type: Array,
      default: () => []
    },
    isMarketOpen: {
      type: Boolean,
      default: false
    },
    wallet: {
      type: Object,
      default: () => ({ credits: null })
    },
    round_players: {
      type: Array,
      default: () => []
    },
    weigths: {
      type: Array,
      default: () => []
    },
    share: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      selectedRole: null,
      selectionView: 0
    }
  },
  computed: {
    hasNextRound () {
      return this.rounds.find(r => r.number === this.round.number + 1)
    },
    hasPreviousRound () {
      return this.rounds.find(r => r.number === this.round.number - 1)
    }
  },
  methods: {
    clearTeam () {
      this.$emit('update-team', { top_id: null, jun_id: null, mid_id: null, adc_id: null, sup_id: null, coach_id: null, shotcaller_id: null })
    },
    async selectRole (role) {
      if (this.isMarketOpen) {
        if (this.selectedRole === role) {
          this.selectedRole = null
        } else {
          this.selectedRole = role
          let market = document.getElementById('market-players')
          if (!market) {
            await new Promise(resolve => setTimeout(resolve, 100))
            market = document.getElementById('market-players')
          }
          if (market) {
            this.$smoothScroll({ scrollTo: market })
          }
        }
      }
    },
    selectShotcaller (shotcaller) {
      if (this.isMarketOpen && shotcaller) {
        const localTeam = JSON.parse(JSON.stringify(this.team))
        localTeam.shotcaller_id = shotcaller
        this.$emit('update-team', localTeam)
      }
    }
  }

}
</script>
