<template>
  <!-- TAB Meu time -->
  <b-tab active>
    <template #title>
      <b-img
        :src="require('@/assets/icons/broken/ticket-star.svg')"
        fluid
        alt=""
        class="icon-sm mr-3"
      ></b-img>
      Meu Bolão
    </template>
    <div class="py-5" style="position: relative">
      <b-row>
        <b-col class="col-12 col-xl-8 offset-xl-2">
          <b-row>
            <b-col class="col-12 col-md-4 text-center text-md-left py-3">
              <b-link
                class="text-white"
                v-if="hasPreviousRound"
                @click="$emit('update-round', hasPreviousRound.id)"
              >
                <b-img
                  :src="require('@/assets/icons/bold/arrow-square-left.svg')"
                  fluid
                  alt=""
                  class="icon-sm icon-white"
                ></b-img>
                RODADA {{ hasPreviousRound.number }}
              </b-link>
            </b-col>
            <b-col class="col-12 col-md-4 text-center text-md-center py-3">
              <h3 class="mb-0">
                <span class="destaque-green"> RODADA {{ round.number }} </span>
              </h3>
            </b-col>
            <b-col class="col-12 col-md-4 text-center text-md-right py-3">
              <b-link
                class="text-white"
                v-if="hasNextRound"
                @click="$emit('update-round', hasNextRound.id)"
              >
                RODADA {{ hasNextRound.number }}
                <b-img
                  :src="require('@/assets/icons/bold/arrow-square-right.svg')"
                  fluid
                  alt=""
                  class="icon-sm icon-white"
                ></b-img>
              </b-link>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row v-if="isLosser">
        <b-col class="col-12 my-3" style="position: relative">
          <div
            class="box-defeat p-5"
            :style="{
              background:
                'url(' + require('@/assets/img/bg_aviso.png') + '), #EE283F'
            }"
          >
            <b-row>
              <b-col class="col-10 col-xl-9">
                <h6 class="text-white">
                  <b-img
                    :src="require('@/assets/icons/broken/cup.svg')"
                    fluid
                    alt=""
                    class="icon-sm icon-white mr-2"
                  ></b-img>
                  PREMIAÇÃO
                </h6>
                <h5 class="mb-0 pt-3">
                  Poxa… Não foi dessa vez que você conseguiu… Mas não desista!
                </h5>
              </b-col>
              <div class="col-4 col-md-3 col-xl-3 ico-message">
                <b-img
                  :src="require('@/assets/img/ico_espada.png')"
                  fluid
                  alt=""
                  class=""
                ></b-img>
              </div>
            </b-row>
          </div>
        </b-col>
      </b-row>
      <b-row v-if="isWinner">
        <b-col class="col-12 my-3" style="position: relative">
          <div
            class="box-victory p-5"
            :style="{
              background:
                'url(' + require('@/assets/img/bg_sucesso.png') + '), #DCFC04'
            }"
          >
            <b-row>
              <b-col class="col-10 col-xl-9 text-4">
                <h6 class="">
                  <b-img
                    :src="require('@/assets/icons/broken/cup.svg')"
                    fluid
                    alt=""
                    class="icon-sm mr-2"
                  ></b-img>
                  PREMIAÇÃO
                </h6>
                <h5 class="mb-0 pt-3">
                  Parabéns, você acaba de ganhar {{ localAward }}, entraremos em
                  contato em breve!
                </h5>
              </b-col>
              <div class="col-4 col-md-3 col-xl-3 ico-message">
                <b-img
                  :src="require('@/assets/img/ico_trophy.png')"
                  fluid
                  alt=""
                  class=""
                ></b-img>
              </div>
            </b-row>
          </div>
        </b-col>
      </b-row>
      <b-row class="mt-5">
        <b-col class="col-12">
          <b-img
            :src="require('@/assets/icons/broken/edit.svg')"
            fluid
            alt=""
            class="icon-sm icon-green mr-3"
          ></b-img>
          SELEÇÃO DE VENCEDORES
          <hr />
        </b-col>
      </b-row>
      <b-row>
        <b-col v-if="round.paid">
          <p>
            Legenda:
          </p>
          <p>
            <b-img
              :src="require('@/assets/img/ico_vencedor_active.png')"
              fluid
              alt=""
              class="mr-3"
              style="max-height: 35px"
            ></b-img
            > Vencedor da partida que você apostou.
          </p>
          <p>
            <b-img
              :src="require('@/assets/img/ico_perdedor_active.png')"
              fluid
              alt=""
              class="mr-3"
              style="max-height: 35px"
            ></b-img
            > Você apostou no time que perdeu a partida.
          </p>
          <p>
            <b-img
              :src="require('@/assets/img/ico_vencedor2_active.png')"
              fluid
              alt=""
              class="mr-3"
              style="max-height: 35px"
            ></b-img
            > Vencedor da partida que você não apostou.
          </p>
        </b-col>
        <b-col class="col-12 pb-3">
          <PoolMatch
            v-for="(match, index) in matchs"
            :key="index"
            :match="match"
            :selectedTeam="selectedTeam(match)"
            :isMarketOpen="isMarketOpen"
            :isPaid="round.paid"
            @select-team="onSelectTeam($event, match)"
          />
        </b-col>
        <PoolUser
          :matchs="matchs"
          :user_pool="user_pool"
          :isMarketOpen="isMarketOpen"
          :isPaid="round.paid"
          :lockDate="round.lock_date"
          :poolId="round.id"
        />
      </b-row>
      <PoolLeaderboard :winners="winners" v-if="hasWinners" />
    </div>
  </b-tab>
</template>

<script>
import { mapGetters } from 'vuex'
import PoolMatch from './PoolMatch.vue'
import PoolUser from './PoolUser.vue'
import PoolLeaderboard from './PoolLeaderboard.vue'
export default {
  components: { PoolMatch, PoolUser, PoolLeaderboard },
  name: 'PoolSelection',
  props: {
    user_pool: {
      type: Array,
      default: () => []
    },
    matchs: {
      type: Array,
      default: () => []
    },
    winners: {
      type: Array,
      default: () => []
    },
    rounds: {
      type: Array,
      default: () => []
    },
    round: {
      type: Object,
      default: () => {}
    },
    pool: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    ...mapGetters('auth', ['getUser']),
    userId () {
      if (this.getUser) {
        return this.getUser.id
      }
      return null
    },
    localAward () {
      return this.round.award ? this.round.award : this.pool.award
    },
    isWinner () {
      return (
        this.round.paid &&
        this.winners.find(winner => winner.user_id === this.userId)
      )
    },
    isLosser () {
      return (
        this.round.paid &&
        !this.winners.find(winner => winner.user_id === this.userId)
      )
    },
    isMarketOpen () {
      return (
        new Date().getTime() > new Date(this.round.start_date).getTime() &&
        new Date().getTime() < new Date(this.round.lock_date).getTime()
      )
    },
    hasNextRound () {
      return this.rounds.find(r => r.number === this.round.number + 1)
    },
    hasPreviousRound () {
      return this.rounds.find(r => r.number === this.round.number - 1)
    },
    hasWinners () {
      return this.winners.length > 0
    }
  },
  methods: {
    selectedTeam (match) {
      const userPool = this.user_pool.find(
        userPool => userPool.match === match.id
      )
      return userPool ? userPool.team : null
    },
    onSelectTeam (team, match) {
      this.$emit('select-team', team, match)
    }
  }
}
</script>
