<template>
  <div class="btn-fixed-team d-none d-lg-block">
    <b-link class="open-team" @click="open()">
      <b-img
        :src="require('@/assets/img/btn_fixed.png')"
        fluid
        alt=""
        class=""
        style="max-width:75px"
      ></b-img>
    </b-link>
    <div class="btn-fixed-team-players">
      <div class="topo px-5 py-5">
        <h5 class="mb-0">
          <b-img
            :src="require('@/assets/icons/broken/game.svg')"
            fluid
            alt=""
            class="icon-sm icon-green mr-2"
          ></b-img>
          MEU TIME
          <b-link href="#" class="d-inline-block" @click="close()" style="float: right"><b-img
            :src="require('@/assets/icons/bold/close-square.svg')"
            fluid
            alt=""
            class="icon-sm icon-white mr-2"
          ></b-img></b-link>
        </h5>
      </div>
      <div class="body px-5">
        <b-row>
          <FantasyCardPlayer
            v-for="(role, index) in roles"
            :key="index"
            :id="team[`${role}_id`]"
            :name="team[`${role}_nickname`]"
            :role="role"
            :team="team[`${role}_team_shortcut`]"
            :player_image="team[`${role}_image`]"
            :team_image="team[`${role}_team_image`]"
            :price="team[`${role}_price`]"
            :points="team[`${role}_point`]"
            :shotcaller="
              team.shotcaller_id &&
                team[`${role}_id`] === team.shotcaller_id
            "
            :isMarketOpen="isMarketOpen"
            :selectedRole="selectedRole"
            @select-role="selectRole(role)"
            @select-shotcaller="
              $emit('select-shotcaller', team[`${role}_id`])
            "
          />
        </b-row>
        <b-row v-if="isMarketOpen">
          <div class="col-12 col-md-6 my-2 text-center">
            <b-button
              class="btn-1 w-100 py-2"
              @click="$emit('clear-team')"
            >
              <b-img
                :src="require('@/assets/icons/broken/broom.svg')"
                fluid
                alt=""
                class="icon-sm icon-white"
              ></b-img>
              LIMPAR TIME
            </b-button>
          </div>
          <div class="col-12 col-md-6 my-2 text-center mb-5">
            <b-overlay
              :show="busy"
              rounded
              opacity="0.6"
              spinner-small
              spinner-variant="primary"
            >
              <b-button
                class="btn-3 w-100 py-2"
                :disabled="!teamEnablely"
                :style="{ cursor: teamEnablely ? 'auto' : 'not-allowed' }"
                @click="closeTeam"
              >
                <b-img
                  :src="require('@/assets/icons/broken/people.svg')"
                  fluid
                  alt=""
                  class="icon-sm"
                ></b-img>
                CONFIRMAR TIME
              </b-button>
            </b-overlay>
            <p class="pt-3 mb-0" v-if="missingPlayers > 0">
              <b-img
                :src="require('@/assets/icons/bold/danger.svg')"
                fluid
                alt=""
                class="icon-sm icon-white"
              ></b-img>
              <span class="text-3"> Falta </span
              ><b
                >{{ missingPlayers }} jogador{{
                  missingPlayers > 1 ? 'es' : ''
                }}</b
              >!
            </p>
            <p class="pt-3 mb-0" v-if="missingCoach">
              <b-img
                :src="require('@/assets/icons/bold/danger.svg')"
                fluid
                alt=""
                class="icon-sm icon-white"
              ></b-img>
              <span class="text-3"> Falta </span><b>1 treinador</b>!
            </p>
            <p class="pt-3 mb-0" v-if="missingShotcaller">
              <b-img
                :src="require('@/assets/icons/bold/danger.svg')"
                fluid
                alt=""
                class="icon-sm icon-white"
              ></b-img>
              <span class="text-3"> Falta </span><b>1 capitão</b>!
            </p>
            <p class="pt-3 mb-0" v-if="missingCredits">
              <b-img
                :src="require('@/assets/icons/bold/danger.svg')"
                fluid
                alt=""
                class="icon-sm icon-white"
              ></b-img>
              <span class="text-3"> Saldo </span><b>insuficiente</b>!
            </p>

            <p class="pt-3 mb-0" v-if="hasRepeatedPlayer">
              <b-img
                :src="require('@/assets/icons/bold/danger.svg')"
                fluid
                alt=""
                class="icon-sm icon-white"
              ></b-img>
              <span class="text-3"> Jogadores </span><b>repeditos</b>!
            </p>
          </div>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import setsNotify from '@/mixins/sets-notify.mixin'
import formErrors from '@/mixins/form-errors.mixin'
import FantasyCardPlayer from '@/components/fantasy/FantasyCardPlayerSlide.vue'
import FantasyService from '@/services/fantasies/fantasy'
export default {
  name: 'FantasySlide',
  mixins: [setsNotify, formErrors],
  components: {
    FantasyCardPlayer
  },
  props: {
    team: {
      type: Object,
      default: () => ({ top_id: null, jun_id: null, mid_id: null, adc_id: null, sup_id: null, coach_id: null, shotcaller_id: null })
    },
    round: {
      type: Object,
      default: () => ({ id: 0, number: 0 })
    },
    isMarketOpen: {
      type: Boolean,
      default: false
    },
    wallet: {
      type: Object,
      default: () => ({ credits: null })
    },
    selectedRole: {
      type: String,
      default: null
    },
    share: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      roles: ['top', 'jun', 'mid', 'adc', 'sup', 'coach'],
      fantasyService: new FantasyService(),
      busy: false
    }
  },
  computed: {
    missingPlayers () {
      let missing = 5
      if (this.team.top_id) missing--
      if (this.team.jun_id) missing--
      if (this.team.mid_id) missing--
      if (this.team.sup_id) missing--
      if (this.team.adc_id) missing--
      return missing
    },
    missingCoach () {
      return !this.team.coach_id
    },
    missingShotcaller () {
      return !this.team.shotcaller_id
    },
    missingCredits () {
      if (this.teamValue === '--' || !this.wallet.credits) {
        return true
      }
      return parseFloat(this.wallet.credits) < parseFloat(this.teamValue)
    },
    hasRepeatedPlayer () {
      const ids = []
      const roles = ['top', 'jun', 'mid', 'adc', 'sup', 'coach']
      for (let i = 0; i < roles.length; i++) {
        const role = roles[i]
        if (this.team[role + '_id']) {
          if (ids.includes(this.team[role + '_id'])) {
            return true
          }
          ids.push(this.team[role + '_id'])
        }
      }
      return false
    },
    teamEnablely () {
      return !this.missingCredits && !this.missingPlayers && !this.missingCoach && !this.missingShotcaller && !this.hasRepeatedPlayer
    }
  },
  methods: {
    open () {
      document.querySelector('.btn-fixed-team-players').classList.add('visible')
    },
    close () {
      document.querySelector('.btn-fixed-team-players').classList.remove('visible')
      this.$emit('select-role', null)
      const playerDiv = document.getElementById('team-selection')
      console.log(playerDiv)
      if (playerDiv) {
        this.$smoothScroll({ scrollTo: playerDiv })
      }
    },
    selectRole (role) {
      document.querySelector('.btn-fixed-team-players').classList.remove('visible')
      this.$emit('select-role', role)
    },
    async closeTeam () {
      this.busy = true
      try {
        const roles = ['top', 'jun', 'mid', 'adc', 'sup', 'coach']
        const playersList = roles.map(role => { return [role, { player_id: this.team[`${role}_id`] }] })
        playersList.push(['id', this.round.id])
        playersList.push(['shotcaller', { player_id: this.team.shotcaller_id }])
        const data = Object.fromEntries(playersList)
        await this.fantasyService.update(data)
        this.setNotifySuccess('Seu time foi com fechado com sucesso. Você está eleito a participar desta rodada.')
        this.canShare = true
        if (this.$gtag) {
          this.$gtag.event('Fechar Time', { event_category: 'Fantasy' })
        }
      } catch (error) {
        if (error.name === 400 && this.isJson(error.message)) {
          const errors = this.parseServerSideErros({}, JSON.parse(error.message))
          Object.entries(errors).forEach(item => {
            item[1].forEach(msg => {
              this.setNotifyDanger(`${msg} Error: ${error.name}.`)
            })
          })
          return
        }
        this.setNotifyDanger(`${error.message}. Error: ${error.name}.`)
      } finally {
        this.busy = false
      }
    }
  }
}
</script>
