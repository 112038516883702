<template>
  <app-modal :title="title" :id="id" @show="getTeam" size="xl">
    <template #content>
      <b-row>
        <b-overlay :show="failed" no-wrap bg-color="#152233">
          <template #overlay>
            <div class="text-center">
              <b-icon
                icon="exclamation-triangle-fill"
                font-scale="3"
                variant="danger"
              ></b-icon>
              <p>Houve um problema ao carregar a pagina:</p>
              <p>{{ error_message }}</p>
              <b-button class="btn-default mx-1" size="sm" @click="getTeam">
                Tentar Novamente
              </b-button>
            </div>
          </template>
        </b-overlay>
        <b-col cols="12">
          <b-row ref="myTeam">
            <app-player-team-fantasy
              :name="player.nickname"
              :team="player.team_shortcut"
              :team_image="player.team_image"
              :id="player.player_id"
              :src="player.image"
              :is-shotcaller="player.shotcaller"
              :role="player.role_name"
              :loading="loading"
              v-for="(player, index) in team"
              :key="index"
            />
          </b-row>
        </b-col>
      </b-row>
    </template>
  </app-modal>
</template>

<script>
import AppModal from '@/components/AppModal'
import FantasyService from '@/services/fantasies/fantasy'
import AppPlayerTeamFantasy from '@/components/fantasy/AppPlayerTeamFantasy'
export default {
  name: 'AppUserTeamFantasyModal',
  components: { AppModal, AppPlayerTeamFantasy },
  props: {
    id: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    round: {
      type: Number
    },
    userId: {
      type: String,
      required: true
    }
  },
  data: () => ({
    fantasyService: new FantasyService(),
    team: [
      {
        nickname: 'JOGADOR',
        role_name: 'top',
        image: null,
        shotcaller: false,
        player_price: '0.00',
        team_shortcut: '',
        team_image: ''
      },
      {
        nickname: 'JOGADOR',
        role_name: 'jun',
        image: null,
        shotcaller: false,
        player_price: '0.00',
        team_shortcut: '',
        team_image: ''
      },
      {
        nickname: 'JOGADOR',
        role_name: 'mid',
        image: null,
        shotcaller: false,
        player_price: '0.00',
        team_shortcut: '',
        team_image: ''
      },
      {
        nickname: 'JOGADOR',
        role_name: 'adc',
        image: null,
        shotcaller: false,
        player_price: '0.00',
        team_shortcut: '',
        team_image: ''
      },
      {
        nickname: 'JOGADOR',
        role_name: 'sup',
        image: null,
        shotcaller: false,
        player_price: '0.00',
        team_shortcut: '',
        team_image: ''
      },
      {
        nickname: 'JOGADOR',
        role_name: 'coach',
        image: null,
        shotcaller: false,
        player_price: '0.00',
        team_shortcut: '',
        team_image: ''
      }
    ],
    loading: true,
    failed: false,
    error_message: '',
    roles: ['top', 'jun', 'mid', 'adc', 'sup', 'coach']
  }),
  methods: {
    async getTeam () {
      this.loading = true
      this.failed = false
      try {
        const { data } = await this.fantasyService.team(this.round, this.userId)
        this.team = this.parseTeam(data.data)
        this.roles.forEach(role => {
          const playerAtRole = this.team.find(
            player => player.role_name === role
          )
          if (!playerAtRole) {
            this.team.push({
              nickname: 'JOGADOR',
              role_name: role,
              image: null,
              shotcaller: false,
              player_price: '0.00',
              team_shortcut: '',
              team_image: ''
            })
          }
        })
        this.loading = false
      } catch (error) {
        this.failed = true
        this.error_message = error.message
      }
    },
    parseTeam (team) {
      const roles = ['top', 'jun', 'mid', 'adc', 'sup', 'coach']
      const result = []
      roles.forEach(role => {
        const player = {
          player_id: team[`${role}_id`],
          nickname: team[`${role}_nickname`],
          role_name: role,
          image: team[`${role}_image`],
          shotcaller: team.shotcaller_id
            ? team[`${role}_id`] === team.shotcaller_id
            : false,
          player_price: team[`${role}_player_price`],
          role_image: team[`${role}_role_image`],
          team_shortcut: team[`${role}_team_shortcut`],
          team_image: team[`${role}_team_image`]
        }
        result.push(player)
      })
      return result
    }
  }
}
</script>
