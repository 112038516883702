<template>
  <!-- TAB Fantasies anteriores -->
  <b-tab>
    <template #title>
      <b-img
        :src="require('@/assets/icons/broken/game.svg')"
        fluid
        alt=""
        class="icon-sm"
      ></b-img>
      Fantasies anteriores
    </template>
    <div class="py-5">
      <b-row>
        <b-col class="col-12 pb-5">
          <b-img
            :src="require('@/assets/icons/broken/game.svg')"
            fluid
            alt=""
            class="icon-sm icon-green"
          ></b-img>
          FANTASIES ANTERIORES
        </b-col>
        <b-col>
          <b-row>
            <FantasyCard :fantasy="fantasy" v-for="(fantasy, index) in fantasies" :key="index"/>
          </b-row>
        </b-col>
      </b-row>
    </div>
  </b-tab>
</template>

<script>
import FantasyCard from './FantasyCard.vue'
export default {
  components: { FantasyCard },
  name: 'FantasyPast',
  props: {
    fantasies: {
      type: Array,
      required: true
    }
  }
}
</script>
