<template>
  <!-- ROW APOSTA -->
  <b-row class="">
    <b-col class="col-12 py-3" v-if="match.start">
      <p class="mb-0">
          <b-button class="btn-6 px-3">
            <b-img
              :src="require('@/assets/icons/bold/calendar-2.svg')"
              fluid
              alt=""
              class="icon-sm icon-white"
            ></b-img>
            <small class="text-capitalize">
              {{ toPoolDate(match.start) }}</small
            >
          </b-button>
        </p>
        <p class="text-3 pt-3 pt-lg-5 d-none">Vencedor da Partida</p>
    </b-col>
    <b-col class="">
      <div class="card-player-container p-1 mb-1" style="">
        <b-row class="align-items-center">
          <div class="col-12 col-lg-5">
            <div class="text-center">
              <b-row class="align-items-center">
                <b-col class="col-4 offset-4 offset-lg-0">
                  <div class="text-center">
                    <b-link
                      @click="selectTeam(match.team1)"
                      :style="{ cursor: isMarketOpen ? 'pointer' : 'initial' }"
                    >
                      <b-img
                        :src="winnerImage(match.team1)"
                        fluid
                        alt=""
                        class=""
                      ></b-img>
                    </b-link>
                  </div>
                </b-col>
                <b-col class="col-12 col-lg-6 py-3 py-lg-0">
                  <p class="mb-0 text-uppercase">{{ match.team1__name }}</p>
                </b-col>
                <b-col class="col-12 col-lg-2">
                  <div class="tabela-time text-center p-1">
                    <b-img
                      :src="match.team1__image"
                      fluid
                      alt=""
                      style="max-height: 50px"
                      class=""
                    ></b-img>
                  </div>
                </b-col>
              </b-row>
            </div>
          </div>
          <div class="col-12 col-lg-2 text-center align-self-center">
            <h5 class="mb-0 btn-6 px-3 text-3 py-2 d-inline-block my-3 my-lg-0">
              VS
            </h5>
          </div>
          <div class="col-12 col-lg-5">
            <div class="text-center">
              <b-row class="align-items-center">
                <b-col class="col-12 col-lg-2">
                  <div class="tabela-time text-center p-1">
                    <b-img
                      :src="match.team2__image"
                      fluid
                      alt=""
                      style="max-height: 50px"
                      class=""
                    ></b-img>
                  </div>
                </b-col>
                <b-col class="col-12 col-lg-6 py-3 py-lg-0">
                  <p class="mb-0 text-uppercase">{{ match.team2__name }}</p>
                </b-col>
                <b-col class="col-4 offset-4 offset-lg-0">
                  <div class="text-center">
                    <b-link
                      @click="selectTeam(match.team2)"
                      :style="{ cursor: isMarketOpen ? 'pointer' : 'initial' }"
                    >
                      <b-img
                        :src="winnerImage(match.team2)"
                        fluid
                        alt=""
                        class=""
                      ></b-img>
                    </b-link>
                  </div>
                </b-col>
              </b-row>
            </div>
          </div>
        </b-row>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import dataFormat from '@/mixins/data-format.mixin'
export default {
  name: 'PoolMatch',
  mixins: [dataFormat],
  props: {
    match: {
      type: Object,
      required: true
    },
    selectedTeam: {
      type: Number || String,
      default: null
    },
    isMarketOpen: {
      type: Boolean,
      default: false
    },
    isPaid: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    selectTeam (team) {
      if (this.isMarketOpen) {
        this.$emit('select-team', team)
      }
    },
    winnerImage (team) {
      if (this.isPaid) {
        if (this.selectedTeam) {
          if (this.match.winner === team) {
            return require('@/assets/img/ico_vencedor_active.png')
          }
          if (this.selectedTeam === team) {
            return require('@/assets/img/ico_perdedor_active.png')
          }
          return require('@/assets/img/ico_vencedor.png')
        }
        if (this.match.winner === team) {
          return require('@/assets/img/ico_vencedor2_active.png')
        }
        return require('@/assets/img/ico_vencedor.png')
      }
      if (this.selectedTeam === team) {
        return require('@/assets/img/ico_vencedor_active.png')
      }
      return require('@/assets/img/ico_vencedor.png')
    }
  }
}
</script>
