<template>
  <b-col class="col-12 pb-3">
    <div class="sidebar-item">
      <div class="">
        <b-row class="">
          <b-col class="col-12">
            <div class="card-player-container">
              <div
                class="
                  px-3
                  quadro3
                  py-4
                  border-top-0 border-left-0 border-right-0 d-none
                "
              >
                <h4>
                <b-img
                  :src="require('@/assets/icons/broken/edit.svg')"
                  fluid
                  alt=""
                  class="icon-sm icon-green mr-2"
                  style="margin-top: -7px; width: 25px"
                ></b-img>
                MEU BOLÃO
                </h4>
                <h4>
                <small>
                  {{ countLabel }}
                <vue-countdown-timer
                  :end-text="''"
                  :start-time="new Date()"
                  :end-time="new Date(lockDate)"
                  :day-txt="'dias e'"
                  :seconds-txt="''"
                  show-zero
                >
                </vue-countdown-timer>
                </small>
                </h4>
              </div>
              <b-row class="p-3">
                <PoolUserTeam v-for="(team,index) in user_pool" :key="index" :team="team" :matchs="matchs" :isPaid="isPaid" class="d-none"/>
                <b-col class="col-12 col-lg-4 offset-lg-4" v-if="isMarketOpen">
                  <div class="pb-3">
                    <b-row>
                      <div class="col-12 col-lg">
                        <b-overlay
                          :show="busy"
                          rounded
                          opacity="0.6"
                          spinner-small
                          spinner-variant="primary"
                        >
                          <b-button class="btn-7 w-100 py-3" :class="isMisingMatchs ? 'btn-7' : 'btn-3'" :style="{cursor: isMisingMatchs ? 'not-allowed' : 'pointer' }" :disabled="isMisingMatchs" @click="closePool">
                            <b-img
                              :src="
                                require('@/assets/icons/broken/ticket-star.svg')
                              "
                              fluid
                              alt=""
                              class="icon-sm mr-1"
                              :class="isMisingMatchs ? 'icon-white' : 'icon-black'"
                            ></b-img>
                            <b>FECHAR BOLÃO</b>
                          </b-button>
                        </b-overlay>
                        <p class="text-center text-3 mb-0" v-if="isMisingMatchs">
                          <b-img
                            :src="require('@/assets/icons/bold/danger.svg')"
                            fluid
                            alt=""
                            class="icon-sm icon-white mr-1"
                          ></b-img>
                          Falta{{ missingMatchs > 1 ? 'm' : ''}}
                          <strong class="text-white">{{ missingMatchs }} partida{{ missingMatchs > 1 ? 's' : ''}}</strong>
                        </p>
                      </div>
                    </b-row>
                  </div>
                </b-col>
              </b-row>
            </div>
          </b-col>
        </b-row>
      </div>
    </div>
  </b-col>
</template>

<script>
import PoolUserTeam from './PoolUserTeam.vue'
import PoolService from '@/services/pools/pool.js'
import setsNotify from '@/mixins/sets-notify.mixin'

export default {
  components: { PoolUserTeam },
  mixins: [setsNotify],
  name: 'PoolUser',
  props: {
    matchs: {
      type: Array,
      default: () => []
    },
    user_pool: {
      type: Array,
      default: () => []
    },
    lockDate: {
      type: String,
      default: ''
    },
    isMarketOpen: {
      type: Boolean,
      default: false
    },
    isPaid: {
      type: Boolean,
      default: false
    },
    poolId: {
      type: Number || String,
      default: null
    }
  },
  data () {
    return {
      poolService: new PoolService(),
      busy: false
    }
  },
  computed: {
    countLabel () {
      return (new Date() > new Date(this.lockDate)) ? 'Finalizado' : 'Finaliza em'
    },
    missingMatchs () {
      return this.matchs.length - this.user_pool.length
    },
    isMisingMatchs () {
      return this.missingMatchs !== 0
    }
  },
  methods: {
    async closePool () {
      this.busy = true
      try {
        await this.poolService.update({ id: this.poolId, matchs: this.user_pool })
        this.setNotifySuccess('Bolão fechado com sucesso!')
      } catch (error) {
        this.setNotifyDanger(error.message)
      } finally {
        this.busy = false
      }
    }
  }
}
</script>
