<template>
  <!-- TAB Leaderboard -->
  <b-tab @click="getLeaderboard">
    <template #title>
      <b-img
        :src="require('@/assets/icons/broken/ranking-1.svg')"
        fluid
        alt=""
        class="icon-sm"
      ></b-img>
      Leaderboard
    </template>
    <div class="py-5">
      <b-row class="mt-5">
        <b-col class="col-12 pb-4">
          <b-img
            :src="require('@/assets/icons/broken/ranking-1.svg')"
            fluid
            alt=""
            class="icon-sm icon-green mr-3"
          ></b-img>
          LEADERBOARD
        </b-col>
      </b-row>
      <b-row class="justify-content-center">
        <b-col class="col-12 col-md-8 col-xl-6">
          <b-form class="ff-inter justify-content-center" inline>
            <label class="mr-sm-2" for="inline-form-custom-select-pref">
              <b-img
                :src="require('@/assets/icons/broken/ranking-1.svg')"
                fluid
                alt=""
                class="icon-sm icon-white mr-2"
              ></b-img>
              Tipo:
            </label>
            <b-form-select
              class="mb-2 mr-sm-2 mb-sm-0"
              :options="types"
              v-model="selectedType"
              @change="onChange"
            ></b-form-select>
            <label class="mr-sm-2 ml-md-5" for="inline-form-custom-select-pref">
              <b-img
                :src="require('@/assets/icons/broken/repeate-music.svg')"
                fluid
                alt=""
                class="icon-sm icon-white mr-2"
              ></b-img>
              Rodada:
            </label>
            <b-form-select
              class="mb-2 mr-sm-2 mb-sm-0 custom-select"
              :options="rounds"
              v-model="selectedRound"
              @change="onChange"
              style="color: white; border: solid 1px white;"
            ></b-form-select>
          </b-form>
        </b-col>
      </b-row>
      <b-row class="mt-5 justify-content-center">
        <b-col class="col-12 col-md-10 col-xl-8">
          <b-row class="justify-content-center">
            <b-col
              class="
                col-12 col-lg-4
                order-2 order-lg-1
                text-center
                align-self-end
                mb-5
              "
            >
              <div v-if="second">
                <h4 class="text-center">2º LUGAR</h4>
                <div class="container-avatar-md mr-auto ml-auto my-3">
                  <b-avatar
                    :src="second.image"
                    :text="firstLetter(second.username)"
                    size="150px"
                  />
                </div>
                <p class="mb-0">{{ second.username }}</p>
                <!-- <p class="text-3">
                  <small> SrPandaNerd </small>
                </p> -->
                <h4 class="text-center">{{ second.score }} pts</h4>
                <p>
                  <b-button
                    class="btn-1 py-2 mt-4 mr-2"
                    v-b-tooltip.hover title="Ver Perfil" :to="{ path: '/perfil/', query: { user: second.user_id } }" v-if="second.user_id !== userID"
                  >
                    <b-img
                      :src="require('@/assets/icons/bold/eye.svg')"
                      fluid
                      alt=""
                      class="icon-white icon-sm"
                    ></b-img>
                  </b-button>
                  <b-button
                    class="btn-1 py-2 mt-4 mr-2"
                    v-b-tooltip.hover title="Solicitar Amizade" @click="addPublicFriend(second.username)" v-if="!isMyFriend(second.user_id) && second.user_id !== userID"
                  >
                    <b-img
                      :src="require('@/assets/icons/bold/profile-add.svg')"
                      fluid
                      alt=""
                      class="icon-white icon-sm"
                    ></b-img>
                  </b-button>
                  <b-button
                    class="btn-1 py-2 mt-4"
                    v-b-modal="`UserTeam-${second.user_id}`"
                    v-if="showTeam"
                  >
                    <b-img
                      :src="require('@/assets/icons/bold/people.svg')"
                      fluid
                      alt=""
                      class="icon-white icon-sm"
                    ></b-img>
                  </b-button>
                </p>
                <AppUserTeamFantasyModal
                  :id="`UserTeam-${second.user_id}`"
                  :title="`TIME DE ${second.username} (${round.text})`"
                  :round="round.value"
                  :userId="`${second.user_id}`"
                />
              </div>
            </b-col>
            <b-col class="col-12 col-lg-4 order-1 order-lg-2 text-center mb-5">
              <div v-if="first">
                <h4 class="text-center">1º LUGAR</h4>
                <div class="container-avatar-lg mr-auto ml-auto my-3">
                  <b-avatar
                    :src="first.image"
                    :text="firstLetter(first.username)"
                    size="200px"
                  />
                </div>
                <p class="mb-0">{{ first.username }}</p>
                <!-- <p class="text-3">
                  <small> SrPandaNerd </small>
                </p> -->
                <h4 class="text-center">{{ first.score }} pts</h4>
                <p>
                  <b-button
                    class="btn-1 py-2 mt-4 mr-2"
                    v-b-tooltip.hover title="Ver Perfil" :to="{ path: '/perfil/', query: { user: first.user_id } }" v-if="first.user_id !== userID"
                  >
                    <b-img
                      :src="require('@/assets/icons/bold/eye.svg')"
                      fluid
                      alt=""
                      class="icon-white icon-sm"
                    ></b-img>
                  </b-button>
                  <b-button
                    class="btn-1 py-2 mt-4 mr-2"
                    v-b-tooltip.hover title="Solicitar Amizade" @click="addPublicFriend(first.username)" v-if="!isMyFriend(first.user_id) && first.user_id !== userID"
                  >
                    <b-img
                      :src="require('@/assets/icons/bold/profile-add.svg')"
                      fluid
                      alt=""
                      class="icon-white icon-sm"
                    ></b-img>
                  </b-button>
                  <b-button
                    class="btn-1 py-2 mt-4"
                    v-b-modal="`UserTeam-${first.user_id}`"
                    v-if="showTeam"
                  >
                    <b-img
                      :src="require('@/assets/icons/bold/people.svg')"
                      fluid
                      alt=""
                      class="icon-white icon-sm"
                    ></b-img>
                  </b-button>
                </p>
                <AppUserTeamFantasyModal
                  :id="`UserTeam-${first.user_id}`"
                  :title="`TIME DE ${first.username} (${round.text})`"
                  :round="round.value"
                  :userId="`${first.user_id}`"
                />
              </div>
            </b-col>
            <b-col
              class="
                col-12 col-lg-4
                order-3 order-lg-3
                text-center
                align-self-end
                mb-5
              "
            >
              <div v-if="third">
                <h4 class="text-center">3º LUGAR</h4>
                <div class="container-avatar-md mr-auto ml-auto my-3">
                  <b-avatar
                    :src="third.image"
                    :text="firstLetter(third.username)"
                    size="150px"
                  />
                </div>
                <p class="mb-0">{{ third.username }}</p>
                <!-- <p class="text-3">
                  <small> SrPandaNerd </small>
                </p> -->
                <h4 class="text-center">{{ third.score }} pts</h4>
                <p>
                  <b-button
                    class="btn-1 py-2 mt-4 mr-2"
                    v-b-tooltip.hover title="Ver Perfil" :to="{ path: '/perfil/', query: { user: third.user_id } }" v-if="third.user_id !== userID"
                  >
                    <b-img
                      :src="require('@/assets/icons/bold/eye.svg')"
                      fluid
                      alt=""
                      class="icon-white icon-sm"
                    ></b-img>
                  </b-button>
                  <b-button
                    class="btn-1 py-2 mt-4 mr-2"
                    v-b-tooltip.hover title="Solicitar Amizade" @click="addPublicFriend(third.username)" v-if="!isMyFriend(third.user_id) && third.user_id !== userID"
                  >
                    <b-img
                      :src="require('@/assets/icons/bold/profile-add.svg')"
                      fluid
                      alt=""
                      class="icon-white icon-sm"
                    ></b-img>
                  </b-button>
                  <b-button
                    class="btn-1 py-2 mt-4"
                    v-b-modal="`UserTeam-${third.user_id}`"
                    v-if="showTeam"
                  >
                    <b-img
                      :src="require('@/assets/icons/bold/people.svg')"
                      fluid
                      alt=""
                      class="icon-white icon-sm"
                    ></b-img>
                  </b-button>
                </p>
                <AppUserTeamFantasyModal
                  :id="`UserTeam-${third.user_id}`"
                  :title="`TIME DE ${third.username} (${round.text})`"
                  :round="round.value"
                  :userId="`${third.user_id}`"
                />
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <table class="table custom-table">
            <tbody>
              <tr v-for="(user, index) in userWithAward" :key="index">
                <td class="">{{ index + 4 }}º</td>
                <td>
                  <div>
                    <b-media class="">
                      <template #aside>
                        <div class="container-avatar">
                          <b-avatar
                            :src="user.image"
                            :text="firstLetter(user.username)"
                            size="40px"
                          />
                        </div>
                      </template>
                      <h5 class="mt-0 mb-1">{{ user.username }}</h5>
                      <!-- <p class="mb-0 text-3">
                        <small> SrPandaNerd </small>
                      </p> -->
                    </b-media>
                  </div>
                </td>
                <td>
                  <h5 class="mb-0 text-right">
                    {{ user.score }} pts
                  </h5>
                </td>
                <td class="text-right">
                  <p>
                    <b-button
                      class="btn-1 py-2 mt-4 mr-2"
                      v-b-tooltip.hover title="Ver Perfil" :to="{ path: '/perfil/', query: { user: user.user_id } }" v-if="user.user_id !== userID"
                    >
                      <b-img
                        :src="require('@/assets/icons/bold/eye.svg')"
                        fluid
                        alt=""
                        class="icon-white icon-sm"
                      ></b-img>
                    </b-button>
                    <b-button
                      class="btn-1 py-2 mt-4 mr-2"
                      v-b-tooltip.hover title="Solicitar Amizade" @click="addPublicFriend(user.username)" v-if="!isMyFriend(user.user_id) && user.user_id !== userID"
                    >
                      <b-img
                        :src="require('@/assets/icons/bold/profile-add.svg')"
                        fluid
                        alt=""
                        class="icon-white icon-sm"
                      ></b-img>
                    </b-button>
                    <b-button
                      class="btn-1 py-2 mt-4"
                      v-b-modal="`UserTeam-${user.user_id}`"
                      v-if="showTeam"
                    >
                      <b-img
                        :src="require('@/assets/icons/bold/people.svg')"
                        fluid
                        alt=""
                        class="icon-white icon-sm"
                      ></b-img>
                    </b-button>
                  </p>
                </td>
                <AppUserTeamFantasyModal
                  :id="`UserTeam-${user.user_id}`"
                  :title="`TIME DE ${user.username} (${round.text})`"
                  :round="round.value"
                  :userId="`${user.user_id}`"
                />
              </tr>
            </tbody>
          </table>
        </b-col>
      </b-row>
      <b-row
        class="mt-3"
        v-if="
          (userWithAward.length > 0 || awardNumber == 3) &&
            userWithoutAward.length > 0
        "
      >
        <b-col class="col-12 pb-4">
          <b-img
            :src="require('@/assets/icons/broken/ranking-1.svg')"
            fluid
            alt=""
            class="icon-sm icon-green mr-3"
          ></b-img>
          SEM PREMIAÇÃO
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <table class="table custom-table">
            <tbody>
              <tr v-for="(user, index) in userWithoutAward" :key="index">
                <td class="">
                  {{ index + Math.max(awardNumber, 3) + 1 }}º
                </td>
                <td>
                  <div>
                    <b-media class="">
                      <template #aside>
                        <div class="container-avatar">
                          <b-avatar
                            :src="user.image"
                            :text="firstLetter(user.username)"
                            size="40px"
                          />
                        </div>
                      </template>
                      <h5 class="mt-0 mb-1">{{ user.username }}</h5>
                      <!-- <p class="mb-0 text-3">
                        <small> SrPandaNerd </small>
                      </p> -->
                    </b-media>
                  </div>
                </td>
                <td>
                  <h5 class="mb-0 text-right">
                    {{ user.score }} pts
                  </h5>
                </td>
                <td class="text-right">
                  <p>
                    <b-button
                      class="btn-1 py-2 mt-4 mr-2"
                      v-b-tooltip.hover title="Ver Perfil" :to="{ path: '/perfil/', query: { user: user.user_id } }" v-if="user.user_id !== userID"
                    >
                      <b-img
                        :src="require('@/assets/icons/bold/eye.svg')"
                        fluid
                        alt=""
                        class="icon-white icon-sm"
                      ></b-img>
                    </b-button>
                    <b-button
                      class="btn-1 py-2 mt-4 mr-2"
                      v-b-tooltip.hover title="Solicitar Amizade" @click="addPublicFriend(user.username)" v-if="!isMyFriend(user.user_id) && user.user_id !== userID"
                    >
                      <b-img
                        :src="require('@/assets/icons/bold/profile-add.svg')"
                        fluid
                        alt=""
                        class="icon-white icon-sm"
                      ></b-img>
                    </b-button>
                    <b-button
                      class="btn-1 py-2 mt-4"
                      v-b-modal="`UserTeam-${user.user_id}`"
                      v-if="showTeam"
                    >
                      <b-img
                        :src="require('@/assets/icons/bold/people.svg')"
                        fluid
                        alt=""
                        class="icon-white icon-sm"
                      ></b-img>
                    </b-button>
                  </p>
                </td>
                <AppUserTeamFantasyModal
                  :id="`UserTeam-${user.user_id}`"
                  :title="`TIME DE ${user.username} (${round.text})`"
                  :round="round.value"
                  :userId="`${user.user_id}`"
                />
              </tr>
              <tr v-if="!userInLeaderboard">
                <td colspan="4" class="text-center"><h2>...</h2></td>
              </tr>
              <tr class="current-player" v-if="!userInLeaderboard">
                <td class="">{{user.rank}}º</td>
                <td>
                  <div>
                    <div class="media">
                      <div class="media-aside align-self-start">
                        <div class="container-avatar">
                          <b-avatar
                            :src="user.image"
                            :text="firstLetter(user.username)"
                            size="40px"
                          />
                        </div>
                      </div>
                      <div class="media-body">
                        <h5 class="mt-0 mb-1">
                          {{ user.username}} <small class="text-3">(Você)</small>
                        </h5>
                      </div>
                    </div>
                  </div>
                </td>
                <td>
                  <h5 class="mb-0 text-right">{{user.score}} pts</h5>
                </td>
                <td class="text-right"></td>
              </tr>
            </tbody>
          </table>
          <!-- <div class="mt-3">
            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              align="center"
              class="custom-pagination"
            ></b-pagination>
          </div> -->
        </b-col>
      </b-row>
    </div>
  </b-tab>
</template>

<script>
import FantasyService from '@/services/fantasies/fantasy.js'
import UserService from '@/services/users/user.js'
import setsNotify from '@/mixins/sets-notify.mixin'
import AppUserTeamFantasyModal from '@/components/modal/AppUserTeamFantasyModal.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'FantasyLeaderboard',
  mixins: [setsNotify],
  components: {
    AppUserTeamFantasyModal
  },
  props: {
    fantasy: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      fantasyService: new FantasyService(),
      userService: new UserService(),
      users: [],
      friends: [],
      user: { user_id: null, username: '' },
      awardNumber: 0,
      round: { value: null, text: '' },
      types: [
        { text: 'Geral', value: 'general' },
        { text: 'Amigos', value: 'friends' }
      ],
      selectedType: 'general',
      rounds: [],
      selectedRound: 'all',
      rows: 100,
      currentPage: 3
    }
  },
  computed: {
    ...mapGetters('auth', ['getUser', 'isLoggedIn']),
    userID () {
      if (this.isLoggedIn) {
        return this.getUser.id
      }
      return null
    },
    userWithAward () {
      return this.users.slice(3, this.awardNumber)
    },
    userWithoutAward () {
      return this.users.slice(Math.max(this.awardNumber, 3), 20)
    },
    userInLeaderboard () {
      if (this.user.user_id === null) {
        return false
      }
      return this.users.slice(0, 20).find(user => user.user_id === this.user.user_id)
    },
    first () {
      if (this.users.length > 0) {
        return this.users[0]
      }
      return null
    },
    second () {
      if (this.users.length > 1) {
        return this.users[1]
      }
      return null
    },
    third () {
      if (this.users.length > 2) {
        return this.users[2]
      }
      return null
    },
    showTeam () {
      return this.selectedRound !== 'all' && this.round.value
    }
  },
  methods: {
    async getLeaderboard () {
      const { data } = await this.fantasyService.leaderboard(this.fantasy.id)
      this.users = data.data
      this.user = data.included.user
      this.friends = data.included.friends
      this.awardNumber = data.included.fantasy.awardNumber
      this.rounds = data.included.rounds.filter(round => round.number !== 1).map(round => ({
        value: round.id,
        text: `Rodada ${round.number}`
      }))
      if (this.rounds.length > 0) {
        this.round = this.rounds[0]
      }
      this.rounds.unshift({ value: 'all', text: 'Todas' })
    },
    firstLetter (username) {
      if (username === null || username === undefined || username === '') {
        return 'U'
      }
      return username.charAt(0).toUpperCase()
    },
    isMyFriend (id) {
      return this.friends.includes(id)
    },
    async addPublicFriend (friend) {
      try {
        const { data } = await this.userService.addFriend(friend)
        const ret = data.data
        this.friends.push(ret.user_id)
        this.setNotifySuccess('Solicitação enviada com sucesso!')
      } catch (error) {
        this.setNotifyDanger(error.message)
      }
    },
    async onChange () {
      try {
        const { data } =
          this.selectedType === 'friends'
            ? this.selectedRound === 'all'
              ? await this.fantasyService.friendLeaderboard(this.fantasy.id)
              : await this.fantasyService.roundFriendLeaderboard(
                this.selectedRound
              )
            : this.selectedRound === 'all'
              ? await this.fantasyService.leaderboard(this.fantasy.id)
              : await this.fantasyService.roundLeaderboard(this.selectedRound)
        this.users = data.data
        this.user = data.included.user
        this.awardNumber =
          this.selectedType === 'friends' || this.selectedRound !== 'all'
            ? 0
            : data.included.fantasy.awardNumber
        if (this.selectedRound === 'all') {
          this.round = { id: this.rounds[1].value, number: this.rounds[1].text }
        } else {
          this.round = this.rounds.find(
            round => String(round.value) === String(this.selectedRound)
          )
        }
      } catch (error) {}
    }
  }
}
</script>
