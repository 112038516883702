<template>
  <!-- TAB Info -->
  <b-tab>
    <template #title>
      <b-img
        :src="require('@/assets/icons/broken/info-circle.svg')"
        fluid
        alt=""
        class="icon-sm"
      ></b-img>
      Informações
    </template>
    <div class="py-5">
      <b-row class="mt-5">
        <b-col class="col-12">
          <b-img
            :src="require('@/assets/icons/broken/info-circle.svg')"
            fluid
            alt=""
            class="icon-sm icon-green mr-3"
          ></b-img>
          INFORMAÇÕES
        </b-col>
        <b-col class="col-12 text-3 pt-3">
          <p v-for="(text, index) in info" :key="index">
              <span v-html="text"></span>
          </p>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="col-12 my-5" style="position: relative">
          <div
            class="box-victory p-5"
            :style="{
              background:
                'url(' + require('@/assets/img/bg_sucesso.png') + '), #DCFC04',
            }"
          >
            <b-row>
              <b-col class="col-10 col-xl-9 text-4">
                <h6 class="">
                  <b-img
                    :src="require('@/assets/icons/broken/cup.svg')"
                    fluid
                    alt=""
                    class="icon-sm mr-2"
                  ></b-img>
                  PREMIAÇÃO
                </h6>
                <h5 class="mb-0 pt-3">
                  Você pode ganhar até {{ pool.award }}!
                </h5>
              </b-col>
              <div class="col-4 col-md-3 col-xl-3 ico-message">
                <b-img
                  :src="require('@/assets/img/ico_trophy.png')"
                  fluid
                  alt=""
                  class=""
                ></b-img>
              </div>
            </b-row>
          </div>
        </b-col>
        <b-col class="col-12 col-lg-6 offset-lg-3 align-self-center pt-5 pt-md-0">
            <p class="mb-0 text-center">
              <a href="https://streamelements.com/baiano/store" target="_blank" class="text-white">
              <b-img
                :src="require('@/assets/img/baicoin.png')"
                fluid
                alt=""
              ></b-img>
              </a>
            </p>
            <h2 class="text-center pt-3">
              <a href="https://streamelements.com/baiano/store" target="_blank" class="text-white">USE AS BAICOINS NA BAILOJA</a>
            </h2>
          </b-col>
      </b-row>
    </div>
  </b-tab>
</template>

<script>
export default {
  name: 'PoolInfo',
  props: {
    pool: {
      type: Object,
      required: true
    }
  },
  computed: {
    info () {
      return this.pool.info.replace('\r', '').split('\n')
    }
  }
}
</script>
