<template>
  <b-modal
    :id="id"
    :ref="id"
    :size="size"
    :visible="visible"
    modal-cancel
    :hide-footer="hideFooter"
    :scrollable="scrollable"
    no-stacking
    :title="title"
    :centered="centered"
    :no-close-on-backdrop="noCloseOnBackdrop"
    :no-close-on-esc="noCloseOnEsc"
    content-class="custom-modal-class"
    @show="onShow"
    @hidden="$emit('change', false)"
  >
    <template slot="modal-header">
      <slot name="title">
        <h6 class="ff-chaney mb-0">
          <b-img
            :src="require('../assets/icons/broken/information.svg')"
            fluid
            alt=""
            class="icon-green icon-sm"
          ></b-img>
          {{ title }}
        </h6>
      </slot>
      <button
        type="button"
        aria-label="Close"
        class="close"
        @click="hideModal"
        v-if="!hideHeaderClose"
      >
        ×
      </button>
    </template>
    <div>
      <slot name="content"></slot>
    </div>
    <div class="text-center" v-if="hideFooter">
      <slot name="footer"></slot>
    </div>
    <template #modal-footer>
      <div class="text-center">
        <slot name="footer"></slot>
      </div>
    </template>
  </b-modal>
</template>

<script>
export default {
  name: 'AppModal',
  model: { prop: 'visible', event: 'change' },
  props: {
    title: {},
    text: {},
    id: {},
    size: { default: 'md' },
    visible: { default: false },
    scrollable: { default: false },
    hideFooter: { default: true },
    centered: { default: false },
    noCloseOnBackdrop: { default: false },
    noCloseOnEsc: { default: false },
    hideHeaderClose: { default: false }
  },
  methods: {
    hideModal () {
      if (this.id) {
        this.$refs[this.id].hide()
      }
      this.$emit('change', false)
    },
    onShow (BvModalEvent) {
      this.$emit('show', BvModalEvent)
    }
  }
}
</script>

<style>
.custom-modal-class {
  background-color: #152233;
  border: 1px solid #dcfc04;
}

.custom-header-class {
  border-bottom: none;
  display: block;
}

.custom-close-button {
  position: absolute;
  top: 0;
  right: 0;
}

.custom-modal-title {
  font-weight: 100;
  color: #dcfc04;
  text-align: center;
}
</style>
