<template>
  <!-- TAB Estatisticas -->
  <b-tab @click="getRoundStats">
    <template #title>
      <b-img
        :src="require('@/assets/icons/broken/chart-2.svg')"
        fluid
        alt=""
        class="icon-sm"
      ></b-img>
      Estatísticas
    </template>
    <div class="py-5">
      <b-row class="mt-5">
        <b-col class="col-12">
          <b-img
            :src="require('@/assets/icons/broken/chart-1.svg')"
            fluid
            alt=""
            class="icon-sm icon-green mr-3"
          ></b-img>
          ESTATÍSTICAS DO CAMPEONATO
        </b-col>
        <b-col class="col-12">
          <b-row>
            <b-col class="col-12 col-md-4 col-xl-3">
              <div class="quadro2 py-3 px-4 mt-4">
                <h2 class="">{{number}}</h2>
                <p class="text-3 mb-1">
                  <b-img
                    :src="require('@/assets/icons/broken/repeate-music.svg')"
                    fluid
                    alt=""
                    class="icon-sm icon-grey"
                  ></b-img>
                  RODADA ATUAL
                </p>
              </div>
            </b-col>
            <b-col class="col-12 col-md-4 col-xl-3">
              <div class="quadro2 py-3 px-4 mt-4">
                <h2 class="">
                  <vue-countdown-timer
                      :end-text="''"
                      :start-time="new Date()"
                      :end-time="new Date(lock_date)"
                      :day-txt="''"
                      :seconds-txt="''"
                      show-zero
                    ></vue-countdown-timer>
                </h2>
                <p class="text-3 mb-1">
                  <b-img
                    :src="require('@/assets/icons/broken/timer.svg')"
                    fluid
                    alt=""
                    class="icon-sm icon-grey"
                  ></b-img>
                  {{ marketLabel }}
                </p>
              </div>
            </b-col>
            <!-- <b-col class="col-12 col-md-4 col-xl-3">
              <div class="quadro2 py-3 px-4 mt-4">
                <h2 class="">820</h2>
                <p class="text-3 mb-1">
                  <b-img
                    :src="require('@/assets/icons/broken/game.svg')"
                    fluid
                    alt=""
                    class="icon-sm icon-grey"
                  ></b-img>
                  JOGADORES
                </p>
              </div>
            </b-col> -->
          </b-row>
          <hr class="my-5" />
        </b-col>
        <b-col class="col-12 pb-4">
          <b-img
            :src="require('@/assets/icons/broken/people.svg')"
            fluid
            alt=""
            class="icon-sm icon-green mr-3"
          ></b-img>
          ESTATÍSTICAS DO TIME
        </b-col>
        <b-col>
          <table class="table custom-table">
            <thead>
              <tr>
                <th scope="col">RODADA</th>
                <th scope="col">TOTAL DE PONTOS</th>
                <th scope="col">PREÇO</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(round,index) in stats.round" :key="index">
                <td>
                  <b-img
                    :src="require('@/assets/icons/broken/repeate-music.svg')"
                    fluid
                    alt=""
                    class="icon-sm icon-green mr-3"
                  ></b-img>
                  Rodada {{ round.number }}
                </td>
                <td><b>{{ round.total_points }}</b> pts</td>
                <td>
                  <h4 class="mb-0"><small>L$</small> {{ round.total_price }}</h4>
                </td>
                <td class="text-right">
                  <b-button class="btn-1 mr-3 w-100" :to="`/fantasy/?rodada=${round.round_id}`">
                    <b-img
                      :src="require('@/assets/icons/bold/eye.svg')"
                      fluid
                      alt=""
                      class="icon-sm icon-white mr-1"
                    ></b-img>
                    <b>VISUALIZAR</b>
                  </b-button>
                </td>
              </tr>
            </tbody>
          </table>
          <hr class="my-5" />
        </b-col>
        <b-col class="col-12 pb-4">
          <b-img
            :src="require('@/assets/icons/broken/buy-crypto.svg')"
            fluid
            alt=""
            class="icon-sm icon-green mr-3"
          ></b-img>
          PONTOS GANHOS x CUSTO
        </b-col>
        <AppLineChart class="col col-12 col-md-10 offset-md-1" :options="roundDataset.options" :chart-data="roundDataset"/>
        <b-col>
          <hr class="my-5" />
        </b-col>
        <b-col class="col-12 pb-4 mt-5">
          <b-img
            :src="require('@/assets/icons/broken/chart-1.svg')"
            fluid
            alt=""
            class="icon-sm icon-green mr-3"
          ></b-img>
          PONTOS GANHOS x RODADA
        </b-col>
        <AppLineChart class="col col-12 col-md-10 offset-md-1" :options="roleDataset.options" :chart-data="roleDataset"/>
        <b-col>
          <hr class="my-5" />
        </b-col>
      </b-row>
    </div>
  </b-tab>
</template>
<style>
.chartjs-render-monitor{
  height: 400px!important;
}
</style>
<script>
import FantasyService from '@/services/fantasies/fantasy.js'
import AppLineChart from '@/components/AppLineChart.vue'
export default {
  name: 'FantasyStatistics',
  components: {
    AppLineChart
  },
  props: {
    fantasy: {
      type: Object,
      default: () => ({})
    }
  },
  data: () => ({
    fantasyService: new FantasyService(),
    stats: { round: [], team: [] },
    number: '',
    lock_date: ''
  }),
  computed: {
    marketLabel () {
      return (new Date().getTime() < new Date(this.lock_date).getTime()) ? 'FECHAMENTO MERCADO' : 'MERCADO FECHADO'
    },
    roundSorted () {
      if (this.stats.round.length > 0) {
        const round = this.stats.round
        return round.sort((a, b) => { return a.number - b.number })
      }
      return []
    },
    roundDataset () {
      return {
        labels: this.roundSorted.map(r => `Rodada ${r.number}`),
        datasets: [
          {
            label: 'VALOR DO TIME',
            fill: false,
            pointStyle: this.image('wallet2.png'),
            borderColor: '#8694b2',
            data: this.roundSorted.map(r => parseFloat(r.total_price)),
            lineTension: 0
          }, {
            label: 'PONTOS RECEBIDOS',
            fill: false,
            pointStyle: this.image('trophy2.png'),
            borderColor: '#FFFFFF',
            data: this.roundSorted.map(r => parseFloat(r.total_points)),
            lineTension: 0
          }
        ],
        options: {
          responsive: true,
          maintainAspectRatio: false,
          title: {
            display: true,
            text: 'Pontos ganhos e custo do time por rodada'
          },
          elements: {
            point: {
              radius: 20
            }
          },
          scales: {
            yAxes: [{
              ticks: {
                stepSize: 50
              }
            }]
          }
        }
      }
    },
    roleDataset () {
      return {
        labels: this.roundSorted.map(r => `Rodada ${r.number}`),
        datasets: [
          {
            label: 'TOP',
            fill: false,
            pointStyle: this.image('ico_top.png'),
            borderColor: '#dcfc04',
            data: this.stats.team.map(player => parseFloat(player.top_point)),
            lineTension: 0
          },
          {
            label: 'MID',
            fill: false,
            pointStyle: this.image('ico_mid.png'),
            borderColor: '#8694b2',
            data: this.stats.team.map(player => parseFloat(player.mid_point)),
            lineTension: 0
          },
          {
            label: 'ADC',
            fill: false,
            pointStyle: this.image('ico_adc.png'),
            borderColor: '#ffffff',
            data: this.stats.team.map(player => parseFloat(player.adc_point)),
            lineTension: 0
          },
          {
            label: 'JUNGLER',
            fill: false,
            pointStyle: this.image('ico_jun.png'),
            borderColor: '#F99117',
            data: this.stats.team.map(player => parseFloat(player.jun_point)),
            lineTension: 0
          },
          {
            label: 'SUP',
            fill: false,
            pointStyle: this.image('ico_sup.png'),
            borderColor: '#336CFF',
            data: this.stats.team.map(player => parseFloat(player.sup_point)),
            lineTension: 0
          },
          {
            label: 'COACH',
            fill: false,
            pointStyle: this.image('ico_coach.png'),
            borderColor: '#8C8A97',
            data: this.stats.team.map(player => parseFloat(player.coach_point)),
            lineTension: 0
          }
        ],
        options: {
          responsive: true,
          maintainAspectRatio: false,
          title: {
            display: true,
            text: 'Pontos ganhos por role, por rodada'
          },
          elements: {
            point: {
              radius: 20
            }
          },
          tooltips: {
            enabled: true,
            mode: 'single',
            callbacks: {
              label: (tooltipItems, data) => {
                const role = ['top', 'mid', 'adc', 'jun', 'sup', 'coach']
                const round = this.roundSorted.map(r => r.number)
                const player = this.stats.team.find(player => player.number === round[tooltipItems.index])
                return player ? `${player[`${role[tooltipItems.datasetIndex]}_name`]}: ${tooltipItems.value}` : `${role[tooltipItems.datasetIndex]}: ${tooltipItems.value}`
              }
            }
          }
        }
      }
    }
  },
  methods: {
    async getRoundStats () {
      const { data } = await this.fantasyService.get(this.fantasy.current_round_id)
      this.stats = data.included.user_stats
      this.number = data.data.number
      this.lock_date = data.data.lock_date
    },
    image (name) {
      const img = new Image(25, 25)
      img.src = require(`@/assets/img/${name}`)
      return img
    }
  }
}
</script>
