<template>
  <!-- CARD FANTASY FINALIZADO-->
  <b-col class="col-12 col-md-6 col-xl-4 my-3">
    <div class="fantasy-finished-outer">
      <div class="fantasy-finished-barra"></div>
      <div class="fantasy-finished-container">
        <div class="fantasy-finished-inner p-3 text-center">
          <b-img
            :src="fantasy.image"
            fluid
            alt=""
            class="py-3"
          ></b-img>
          <p class="mb-0">
            <b-button class="btn-6 px-3">
              <b-img
                :src="require('@/assets/icons/bold/calendar-2.svg')"
                fluid
                alt=""
                class="icon-sm icon-white"
              ></b-img>
              {{ toDate(fantasy.event__start) }} - {{ toDate(fantasy.event__end) }}
            </b-button>
          </p>
          <h4 class="pt-3">{{ fantasy.name }}</h4>
        </div>
        <hr class="mt-0 mb-3" />
        <div class="p-3">
          <b-button class="btn-1 px-3 py-3 w-100" :to="`/fantasy/${fantasy.id}?info`"> MAIS DETALHES </b-button>
        </div>
        <div class="pt-2">
          <b-button
            class="btn-2 px-3 w-100 py-3"
            style="border-radius: 0px 0px 8px 8px"
            :to="`/fantasy/${fantasy.id}`"
          >
            <h5 class="mb-0">
              <b-img
                :src="require('@/assets/icons/broken/eye.svg')"
                fluid
                alt=""
                class="icon-black mr-2"
              ></b-img>
              <b>VISUALIZAR</b>
            </h5>
          </b-button>
        </div>
      </div>
    </div>
  </b-col>
</template>

<script>
import dataFormat from '@/mixins/data-format.mixin'
export default {
  name: 'FantasyCard',
  mixins: [dataFormat],
  props: {
    fantasy: {
      type: Object,
      required: true
    }
  }
}
</script>
