<template>
  <div>
    <div>
      <b-container fluid="xl" class="px-md-5 px-xl-0">
        <b-row>
          <b-col class="col-12">
            <h4 class="text-white ff-chaney mt-5">POLÍTICA DE PRIVACIDADE</h4>
          </b-col>
          <b-col class="col-12 pt-3">
            <div class="text-3 content pb-5">
              <p class="text-white">Última Atualização: 21 de setembro de 2020</p>
              <p>
                Bem-vindo(a)! Obrigado por utilizar o <strong>CartoLOL</strong>!
              </p>
              <p>
                Quando você utiliza o <strong>CartoLOL</strong>, você nos confia
                seus dados e informações. Nos comprometemos a manter essa
                confiança.
              </p>
              <p>
                Nesse sentido, a presente Política de Privacidade (“Política”)
                explica de maneira clara e acessível como as suas informações e
                dados serão coletados, usados, compartilhados e armazenados por
                meio dos nossos sistemas.
              </p>
              <p>
                <strong>
                  A aceitação da nossa Política será feita quando você acessar
                  ou usar o site, aplicativo ou serviços do
                  <strong>CartoLOL</strong>. Isso indicará que você está ciente
                  e em total acordo com a forma como utilizaremos as suas
                  informações e seus dados.
                </strong>
              </p>
              <p>
                A presente Política está dividida da seguinte forma para
                facilitar a sua compreensão:
              </p>
              <ol>
                <li>Quais informações o <strong>CartoLOL</strong> coleta</li>
                <li>
                  Como o <strong>CartoLOL</strong> usa as informações coletadas
                </li>
                <li>
                  Como, quando e com quem o
                  <strong>CartoLOL</strong> compartilha suas informações
                </li>
                <li>
                  Quais direitos você tem sobre as informações que coletamos
                </li>
                <li>
                  Como o <strong>CartoLOL</strong> protege suas informações
                </li>
                <li>Atualizações dessa política de privacidade</li>
                <li>Lei aplicável</li>
              </ol>
              <p>
                Este documento deve ser lido em conjunto com o nosso
                <b-link to="/termos" class="text-white">Termo de Uso</b-link>, que contém uma visão
                geral da nossa plataforma. Caso tenha dúvidas ou precise tratar
                de qualquer assunto relacionado a esta Política, entre em
                contato conosco através do e-mail contato@CartoLOL.com.br.
              </p>
              <h4>1. INFORMAÇÕES QUE COLETAMOS</h4>
              <p>Nós coletamos os seguintes tipos de informações:</p>
              <h6>1.1. Informações que você nos fornece.</h6>
              <p>Isso inclui:</p>
              <p>
                <u>Dados de cadastro</u>. Quando você se cadastra no
                <strong>CartoLOL</strong>, você nos fornece informações como
                senha, nome, e-mail, data de nascimento, foto.
              </p>

              <h6>1.2. Informações geradas quando você usa nossos serviços.</h6>
              <p>Nós coletamos as seguintes informações geradas:</p>
              <p>
                <u>Registros de acesso</u>. O <strong>CartoLOL</strong> coleta
                automaticamente registros de acesso a aplicação, que incluem o
                endereço IP, com data e hora, utilizado para acessar o
                <strong>CartoLOL</strong>. Esses dados são de coleta
                obrigatória, de acordo com a Lei 12.965/2014, mas somente serão
                fornecidos para terceiros com a sua autorização expressa ou por
                meio de demanda judicial.
              </p>
              <p>
                <u>Comunicações com o <strong>CartoLOL</strong></u
                >. Quando você se comunica com o <strong>CartoLOL</strong>,
                coletamos informações sobre sua comunicação, incluindo metadados
                como data, IP e hora das comunicações e todo o seu conteúdo,
                assim como qualquer informação que você escolha fornecer.
              </p>
              <p>
                <u>Cookies e tecnologias semelhantes</u>. Nós utilizamos
                cookies, que são arquivos de texto gerados e armazenados no seu
                navegador ou aparelho por sites, aplicativos e anúncios online.
                Os cookies poderão ser utilizados para as seguintes finalidades:
                Autenticar usuários.
              </p>
              <p>
                <u>Outras informações geradas</u>. Ao utilizar certos recursos,
                o <strong>CartoLOL</strong> pode coletar informações geradas por
                você como: Sessão: uma sessão é um intercâmbio de informações
                interativas semi-permanente, também conhecida como um diálogo,
                uma conversação ou uma reunião entre dois ou mais dispositivos
                comunicantes, ou entre um computador e um usuário.
              </p>
              <h6>1.3. Informações de outras fontes.</h6>
              <p>Isso pode incluir:</p>
              <p>
                <u>Informações provenientes de outros usuários.</u>. Outros
                usuários do <strong>CartoLOL</strong> podem produzir informações
                sobre você, como referências, avaliações ou comentários.
              </p>
              <h4>2. COMO USAMOS SUAS INFORMAÇÕES</h4>
              <p>
                Não custa lembrar, prezamos muito pela sua privacidade. Por
                isso, todos os dados e informações sobre você são tratadas como
                confidenciais, e somente as usaremos para os fins aqui descritos
                e autorizados por você, principalmente para que você possa
                utilizar o
                <strong>CartoLOL</strong> de forma plena, visando sempre
                melhorar a sua experiência como usuário.
              </p>
              <h6>2.1. Usos autorizados.</h6>
              <p>Desta forma, poderemos utilizar seus dados para:</p>
              <ul style="list-style-type: disc !important; margin-left: 50px">
                <li>
                  Permitir que você acesse e utilize todas as funcionalidades do
                  <strong>CartoLOL</strong>;
                </li>
                <li>
                  Enviar a você mensagens a respeito de suporte ou serviço, como
                  alertas, notificações e atualizações;
                </li>
                <li>
                  Nos comunicar com você sobre produtos, serviços, promoções,
                  notícias, atualizações, eventos e outros assuntos que você
                  possa ter interesse;
                </li>
                <li>
                  Verificar ou autenticar as informações fornecidas por você,
                  inclusive comparando a dados coletados de outras fontes;
                </li>
                <li>
                  Para qualquer fim que você autorizar no momento da coleta de
                  dados;
                </li>
                <li>Cumprir obrigações legais.</li>
              </ul>
              <p>
                Eventualmente, poderemos utilizar dados para finalidades não
                previstas nesta política de privacidade, mas estas estarão
                dentro das suas legítimas expectativas.
                <b
                  >O eventual uso dos seus dados para finalidades que não
                  cumpram com essa prerrogativa será feito mediante sua
                  autorização prévia.</b
                >
              </p>
              <h6>2.2. Exclusão dos dados.</h6>
              <p>
                Todos os dados coletados serão excluídos de nossos servidores
                quando você assim requisitar, por procedimento gratuito e
                facilitado, ou quando estes não forem mais necessários ou
                relevantes para lhe oferecermos os nossos serviços, salvo se
                houver qualquer outra razão para a sua manutenção, como eventual
                obrigação legal de retenção de dados ou necessidade de
                preservação destes para resguardo de direitos do
                <strong>CartoLOL</strong>.
              </p>
              <p>
                Nos casos em que você solicite a exclusão dos seus dados, ela
                será feita prontamente, na medida do que for possível.
              </p>
              <h6>2.3. Monitoramento.</h6>
              <p>
                O <strong>CartoLOL</strong> se reserva no direito de monitorar
                toda a plataforma, principalmente para assegurar que as regras
                descritas em <strong>nosso</strong> Termos de Uso estão sendo
                observadas, ou ainda se não há violação ou abuso das leis
                aplicáveis.
              </p>
              <h6>2.4. Exclusão de usuário.</h6>
              <p>
                O <strong>CartoLOL</strong> se reserva no direito de excluir
                determinado usuário, independentemente do tipo que for, caso a
                presente Política ou os Termos de Uso não sejam respeitados.
                Como prezamos pelo bom relacionamento com os usuários,
                reconhecemos que têm o direito de buscar entender os motivos e
                até contestá-los, o que pode ser feito pelo seguinte e-mail:
                contato@CartoLOL.com.br.
              </p>
              <h4>3. COMPARTILHAMENTO DAS INFORMAÇÕES</h4>
              <p>
                Todos os dados, informações e conteúdos sobre você podem ser
                considerados ativos no caso de negociações em que o
                <strong>CartoLOL</strong> fizer parte. Portanto, nos reservamos
                no direito de, por exemplo, incluir seus dados dentre os ativos
                da empresa caso esta venha a ser vendida, adquirida ou fundida
                com outra.
                <b
                  >Por meio desta Política você concorda e está ciente desta
                  possibilidade.</b
                >
              </p>
              <p>
                O <strong>CartoLOL</strong> se reserva no direito de fornecer
                seus dados e informações sobre você, incluindo interações suas,
                caso seja requisitado judicialmente para tanto, ato necessário
                para que a empresa esteja em conformidade com as leis nacionais,
                ou caso você autorize expressamente.
              </p>
              <h4>4. DIREITOS DOS TITULARES</h4>
              <p>
                Você sempre poderá optar em não divulgar seus dados para nós,
                mas tenha em mente que alguns desses dados podem ser necessários
                para utilizar as funcionalidades de nossas aplicações.
                Independente disso, você sempre possuirá direitos relativos à
                privacidade e à proteção dos seus dados pessoais.
              </p>
              <p>
                Dessa forma, abaixo resumimos todos os direitos que você tem sob
                as leis setoriais brasileiras relativas à proteção de dados e a
                Lei Geral de Proteção de Dados ("LGPD"), quais sejam:
              </p>
              <ol>
                <li>
                  <strong>Direito de acesso.</strong> Este direito permite que
                  você possa requisitar e receber uma cópia dos dados pessoais
                  que possuímos sobre você.
                </li>
                <li>
                  <strong>Direito de retificação.</strong> Este direito permite
                  que você, a qualquer momento, possa solicitar a correção e/ou
                  retificação dos seus dados pessoais, caso identifique que
                  alguns deles estão incorretos. Contudo, para ser efetivada
                  essa correção, teremos que checar a validade dos dados que
                  você nos fornece. Você pode retificar diretamente alguns dos
                  seus dados pessoais através do e-mail contato@CartoLOL.com.br.
                </li>
                <li>
                  <strong>Direito de exclusão.</strong> Este direito permite que
                  você possa nos solicitar a exclusão dos dados pessoais que
                  possuímos sobre você. Todos os dados coletados serão excluídos
                  de nossos servidores quando você assim requisitar ou quando
                  estes não forem mais necessários ou relevantes para lhe
                  oferecermos nossos serviços, salvo se houver qualquer outra
                  razão para a sua manutenção, como eventual obrigação legal de
                  retenção de dados ou necessidade de preservação destes para
                  resguardo de direitos da <strong>CartoLOL</strong>. Para
                  alterar suas informações pessoais ou excluí-las do nosso banco
                  de dados, basta enviar um e-mail para contato@CartoLOL.com.br.
                </li>
                <li>
                  <strong>Direito de oposição ao processamento.</strong> Você
                  também tem o direito de contestar onde e em que contexto
                  estamos tratando seus dados pessoais para diferentes
                  finalidades. Em determinadas situações, podemos demonstrar que
                  temos motivos legítimos para tratar seus dados, os quais se
                  sobrepõem aos seus direitos, caso, por exemplo, sejam
                  essenciais para o fornecimento de nossas aplicações.
                </li>
                <li>
                  <strong
                    >Direito de solicitar anonimização, bloqueio ou
                    eliminação.</strong
                  >
                  Este direito permite que você nos peça para suspender o
                  processamento de seus dados pessoais nos seguintes cenários:
                  (a) se você quiser que nós estabeleçamos a precisão dos dados;
                  (b) quando você precisar que sejam mantidos os dados mesmo se
                  não precisarmos mais deles, conforme necessário, para
                  estabelecer, exercer ou defender reivindicações legais; ou (c)
                  se você se opôs ao uso de seus dados, mas nesta hipótese
                  precisamos verificar se temos motivos legítimos para usá-los.
                </li>
                <li>
                  <strong>4.6. Direito à portabilidade.</strong> Forneceremos a
                  você, ou a terceiros que você escolheu, seus dados pessoais em
                  formato estruturado e interoperável.
                </li>
                <li>
                  <strong>Direito de retirar o seu consentimento.</strong> Você
                  tem o direito de retirar o seu consentimento em relação aos
                  termos desta Política de Privacidade. No entanto, isso não
                  afetará a legalidade de qualquer processamento realizado
                  anteriormente. Se você retirar o seu consentimento, talvez não
                  possamos fornecer determinados serviços.
                </li>
                <li>
                  <strong>Direito a revisão de decisões automatizadas.</strong>
                  Você também tem o direito de solicitar a revisão de decisões
                  tomadas unicamente com base em tratamento automatizado de seus
                  dados pessoais que afetem seus interesses, incluídas as
                  decisões destinadas a definição de perfis pessoais,
                  profissionais, de consumo e de crédito e/ou os aspectos de sua
                  personalidade.
                </li>
              </ol>
              <p>
                Talvez seja necessário solicitar informações específicas suas
                para nos ajudar a confirmar sua identidade e garantir seu
                direito de acessar seus dados pessoais (ou de exercer seus
                outros direitos). Esta é uma medida de segurança para garantir
                que os dados pessoais não sejam divulgados a qualquer pessoa que
                não tenha direito de recebê-los. Podemos também contatá-lo para
                obter mais informações em relação à sua solicitação, a fim de
                acelerar nossa resposta. Tentamos responder a todas as
                solicitações legítimas dentro de 5 dias úteis. Ocasionalmente,
                pode levar mais de 5 dias se sua solicitação for particularmente
                complexa ou se você tiver feito várias solicitações. Neste caso,
                iremos comunicá-lo e mantê-lo atualizado sobre o andamento da
                sua solicitação.
              </p>
              <p>
                Caso Você tenha alguma dúvida sobre essas questões e sobre como
                você pode exercer esses direitos, fique à vontade para entrar em
                contato conosco no e-mail contato@CartoLOL.com.br.
              </p>
              <h4>5. SEGURANÇA DAS INFORMAÇÕES</h4>
              <p>
                Todos os seus dados são confidenciais e somente as pessoas com
                as devidas autorizações terão acesso a eles. Qualquer uso destes
                estará de acordo com a presente Política. O
                <strong>CartoLOL</strong> empreenderá todos os esforços
                razoáveis de mercado para garantir a segurança dos nossos
                sistemas e dos seus dados. Nossos servidores estão localizados
                em diferentes locais para garantir estabilidade e segurança, e
                somente podem ser acessados por meio de canais de comunicação
                previamente autorizados.
              </p>
              <p>
                Todas as suas informações serão, sempre que possível,
                criptografadas, caso não inviabilizem o seu uso pela plataforma.
                A qualquer momento você poderá requisitar cópia dos seus dados
                armazenados em nossos sistemas. Manteremos os dados e
                informações somente até quando estas forem necessárias ou
                relevantes para as finalidades descritas nesta Política, ou em
                caso de períodos pré-determinados por lei, ou até quando estas
                forem necessárias para a manutenção de interesses legítimos do
                <strong>CartoLOL</strong>.
              </p>
              <p>
                O <strong>CartoLOL</strong> considera a sua privacidade algo
                extremamente importante e fará tudo que estiver ao alcance para
                protegê-la. Todavia, não temos como garantir que todos os dados
                e informações sobre você em nossa plataforma estarão livres de
                acessos não autorizados, principalmente caso haja
                compartilhamento indevido das credenciais necessárias para
                acessar o nosso aplicativo. Portanto, você é o único responsável
                por manter sua senha de acesso em local seguro e é vedado o
                compartilhamento desta com terceiros. Você se compromete a
                notificar o <strong>CartoLOL</strong> imediatamente, através de
                meio seguro, a respeito de qualquer uso não autorizado de sua
                conta, bem como o acesso não autorizado por terceiros a esta.
              </p>
              <h4>6. ATUALIZAÇÕES DA POLÍTICA DE PRIVACIDADE</h4>
              <p>
                O <strong>CartoLOL</strong> se reserva no direito de alterar
                essa Política quantas vezes forem necessárias, visando fornecer
                a você mais segurança, conveniência, e melhorar cada vez mais a
                sua experiência. É por isso que é muito importante acessar nossa
                Política periodicamente. Para facilitar, indicamos no início do
                documento a data da última atualização. Caso sejam feitas
                alterações relevantes que ensejem novas autorizações suas,
                publicaremos uma nova política de privacidade, sujeita novamente
                ao seu consentimento.
              </p>
              <h4>7. LEI APLICÁVEL</h4>
              <p>
                Este documento é regido e deve ser interpretado de acordo com as
                leis da República Federativa do Brasil. Fica eleito o Foro da
                Comarca de São Paulo, São Paulo, como o competente para dirimir
                quaisquer questões porventura oriundas do presente documento,
                com expressa renúncia a qualquer outro, por mais privilegiado
                que seja.
              </p>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<style lang="scss">
.content h1,
.content h2,
.content h3,
.content h4,
.content h5,
.content h6 {
  color: white;
  margin-top: 30px;
}
</style>

<script>
export default {}
</script>
