<template>
  <b-col
    class="col-12 col-md-12 col-lg-12 col-xl offset-xl-1 align-self-center"
  >
    <div class="resumo-time p-3">
      <div class="row">
        <div class="col-12 col-md-6 my-2">
          <div class="quadro p-2">
            <div v-if="teamPoints === '0.00'" class="quadro-inner py-2">
              <p class="text-3 text-center mb-1">
                <b-img
                  :src="require('@/assets/icons/bold/wallet.svg')"
                  fluid
                  alt=""
                  class="icon-sm icon-grey"
                ></b-img>
                Carteira
              </p>
              <h2 class="text-center mb-0"><small> L$ </small> {{ walletText }}</h2>
            </div>
            <div v-else class="quadro-inner py-2">
              <p class="text-3 text-center mb-1">
                <b-img
                  :src="require('@/assets/icons/bold/award.svg')"
                  fluid
                  alt=""
                  class="icon-sm icon-grey"
                ></b-img>
                Total de pontos
              </p>
              <h2 class="text-center mb-0">{{ teamPoints }} <small> pts </small></h2>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 my-2">
          <div class="quadro p-2">
            <div class="quadro-inner py-2">
              <p class="text-3 text-center mb-1">
                <b-img
                  :src="require('@/assets/icons/bold/dollar-square.svg')"
                  fluid
                  alt=""
                  class="icon-sm icon-grey"
                ></b-img>
                Valor do time
              </p>
              <h2 class="text-center mb-0"><small> L$ </small> {{ teamValue }}</h2>
            </div>
          </div>
        </div>
      </div>
      <b-row v-if="isMarketOpen">
        <div class="col-12 col-md-6 my-2 text-center">
          <b-button class="btn-1 w-100 py-2" @click="$emit('clear-team')">
            <b-img
              :src="require('@/assets/icons/broken/broom.svg')"
              fluid
              alt=""
              class="icon-sm icon-white"
            ></b-img>
            LIMPAR TIME
          </b-button>
        </div>
        <div class="col-12 col-md-6 my-2 text-center">
          <b-overlay
            :show="busy"
            rounded
            opacity="0.6"
            spinner-small
            spinner-variant="primary"
          >
            <b-button class="btn-3 w-100 py-2" :disabled="!teamEnablely" :style="{'cursor': teamEnablely ? 'auto' : 'not-allowed'}" @click="closeTeam">
              <b-img
                :src="require('@/assets/icons/broken/people.svg')"
                fluid
                alt=""
                class="icon-sm mr-1"
              ></b-img>
              <strong>CONFIRMAR TIME</strong>
            </b-button>
          </b-overlay>
          <p class="pt-3 mb-0" v-if="missingPlayers > 0">
            <b-img
              :src="require('@/assets/icons/bold/danger.svg')"
              fluid
              alt=""
              class="icon-sm icon-white"
            ></b-img>
            <span class="text-3"> Falta </span><b>{{ missingPlayers }} jogador{{ missingPlayers > 1 ? 'es' : ''}}</b>!
          </p>
          <p class="pt-3 mb-0" v-if="missingCoach">
            <b-img
              :src="require('@/assets/icons/bold/danger.svg')"
              fluid
              alt=""
              class="icon-sm icon-white"
            ></b-img>
            <span class="text-3"> Falta </span><b>1 treinador</b>!
          </p>
          <p class="pt-3 mb-0" v-if="missingShotcaller">
            <b-img
              :src="require('@/assets/icons/bold/danger.svg')"
              fluid
              alt=""
              class="icon-sm icon-white"
            ></b-img>
            <span class="text-3"> Falta </span><b>1 capitão</b>!
          </p>
          <p class="pt-3 mb-0" v-if="missingCredits">
            <b-img
              :src="require('@/assets/icons/bold/danger.svg')"
              fluid
              alt=""
              class="icon-sm icon-white"
            ></b-img>
            <span class="text-3"> Saldo </span><b>insuficiente</b>!
          <p class="pt-3 mb-0" v-if="hasRepeatedPlayer">
            <b-img
              :src="require('@/assets/icons/bold/danger.svg')"
              fluid
              alt=""
              class="icon-sm icon-white"
            ></b-img>
            <span class="text-3"> Jogadores </span><b>repeditos</b>!
          </p>
        </div>
      </b-row>
      <app-social-share
        :canShare="share || canShare"
        :service="fantasyService"
        :share-id="round"
        error-message="Você precisa fechar o seu time primeiro antes de compatilhá-lo."
        v-model="shareURL"
      />
    </div>
  </b-col>
</template>

<script>
import setsNotify from '@/mixins/sets-notify.mixin'
import formErrors from '@/mixins/form-errors.mixin'
import FantasyService from '@/services/fantasies/fantasy'
import AppSocialShare from '@/components/AppSocialShare.vue'

export default {
  name: 'FantasySummary',
  mixins: [setsNotify, formErrors],
  components: {
    AppSocialShare
  },
  props: {
    team: {
      type: Object,
      default: () => ({ top_id: null, jun_id: null, mid_id: null, adc_id: null, sup_id: null, coach_id: null, shotcaller_id: null })
    },
    round: {
      type: Number || String,
      required: true
    },
    isMarketOpen: {
      type: Boolean,
      default: false
    },
    wallet: {
      type: Object,
      default: () => ({ credits: null })
    },
    share: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      fantasyService: new FantasyService(),
      busy: false,
      loading: false,
      shareURL: null,
      canShare: false
    }
  },
  computed: {
    missingPlayers () {
      let missing = 5
      if (this.team.top_id) missing--
      if (this.team.jun_id) missing--
      if (this.team.mid_id) missing--
      if (this.team.sup_id) missing--
      if (this.team.adc_id) missing--
      return missing
    },
    missingCoach () {
      return !this.team.coach_id
    },
    missingShotcaller () {
      return !this.team.shotcaller_id
    },
    missingCredits () {
      if (this.teamValue === '--' || !this.wallet.credits) {
        return true
      }
      return parseFloat(this.wallet.credits) < parseFloat(this.teamValue)
    },
    hasRepeatedPlayer () {
      const ids = []
      const roles = ['top', 'jun', 'mid', 'adc', 'sup', 'coach']
      for (let i = 0; i < roles.length; i++) {
        const role = roles[i]
        if (this.team[role + '_id']) {
          if (ids.includes(this.team[role + '_id'])) {
            return true
          }
          ids.push(this.team[role + '_id'])
        }
      }
      return false
    },
    teamEnablely () {
      return !this.missingCredits && !this.missingPlayers && !this.missingCoach && !this.missingShotcaller && !this.hasRepeatedPlayer
    },
    walletText () {
      return this.wallet.credits ? this.wallet.credits : '---.--'
    },
    teamValue () {
      const roles = ['top', 'jun', 'mid', 'adc', 'sup', 'coach']
      const value = roles.reduce((prev, curr) => { return prev + (this.team[`${curr}_price`] ? parseFloat(this.team[`${curr}_price`]) : 0) }, 0)
      if (isNaN(value)) {
        return '--'
      }
      return value.toFixed(2)
    },
    teamPoints () {
      const roles = ['top', 'jun', 'mid', 'adc', 'sup', 'coach']
      const value = roles.reduce((prev, curr) => { return prev + (this.team[`${curr}_point`] ? parseFloat(this.team[`${curr}_point`]) : 0) }, 0)
      if (isNaN(value)) {
        return '--'
      }
      return value.toFixed(2)
    }
  },
  methods: {
    async closeTeam () {
      this.busy = true
      try {
        const roles = ['top', 'jun', 'mid', 'adc', 'sup', 'coach']
        const playersList = roles.map(role => { return [role, { player_id: this.team[`${role}_id`] }] })
        playersList.push(['id', this.round])
        playersList.push(['shotcaller', { player_id: this.team.shotcaller_id }])
        const data = Object.fromEntries(playersList)
        await this.fantasyService.update(data)
        this.setNotifySuccess('Seu time foi com fechado com sucesso. Você está eleito a participar desta rodada.')
        this.canShare = true
        if (this.$gtag) {
          this.$gtag.event('Fechar Time', { event_category: 'Fantasy' })
        }
      } catch (error) {
        if (error.name === 400 && this.isJson(error.message)) {
          const errors = this.parseServerSideErros({}, JSON.parse(error.message))
          Object.entries(errors).forEach(item => {
            item[1].forEach(msg => {
              this.setNotifyDanger(`${msg} Error: ${error.name}.`)
            })
          })
          return
        }
        this.setNotifyDanger(`${error.message}. Error: ${error.name}.`)
      } finally {
        this.busy = false
      }
    }
  }
}
</script>
